
import React from "react";
import Loader from 'react-loader-spinner';

import './fullpageloader.css';


const FullPageLoader = () => {
    return (
        <div className="loader">
            <Loader type="Rings" color="#ccc" height={200} width={1000} />
        </div>
    );
};

export default FullPageLoader;
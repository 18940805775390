import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../actions/alert";
import MuiTableCell from "@material-ui/core/TableCell";
import { Grid, Divider, TableRow } from "@material-ui/core";
import API from "../../urls/index";
import { withStyles } from "@material-ui/core/styles";
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import {

  NotificationBar,
  BasicTable,
  CustomizedTab,

  ProjectButton,
} from "../../components";
import axiosInstance from "../../utils/axiosConfig";

import styles from "./task.module.css";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0px",
    height: "100%",
  },
})(MuiTableCell);

const Tasks = (props) => {
  const location = useLocation();


  const { projectID } = props;
  const { projectTitle } = props;
  const [value, setValue] = useState(null);
  const [ loader, showLoader, hideLoader ] = useFullPageLoader()
  const [showTable, setShowTable] = useState(false);
  const [weight, setWeight] = useState(null);
  const [ dynamicID, setDynamicID ] = useState(null);
  const [taskList, setTaskList] = useState(null);
  const [taskChange, settaskChange] = useState({
    title: "",
    weight: "",
    start_date: "",
    end_date: "",
  });
  const CreateTable = () => {
    setShowTable(!showTable);
  };

  const handleTaskChange = (event) => {
    settaskChange({ ...taskChange, [event.target.name]: event.target.value });
  };





  const handleSubmitTask = () => {
    if (showTable) {
      if (
        taskChange.title !== "" &&
        taskChange.weight !== "" &&
        taskChange.start_date !== "" &&
        taskChange.end_date !== ""
      ) {
        if (taskChange.start_date < taskChange.end_date) {
          if(taskChange.weight > 10){
            
            
            props.setAlert("Please Enter Value of Priority between 1 to 10", "error");
            settaskChange({
              title: taskChange.title,
              weight: "",
              start_date: taskChange.start_date,
              end_date: taskChange.end_date,
            });
          }
          else{
         
            axiosInstance
            .post(API.AddTask + `${projectID}/task/add/`, taskChange)
            .then((res) => {
              if (res.data.status === "success") {
                getTasks();
               
                props.setAlert("Successfully added new task", "success");
                setShowTable(false);
                
              }
            })
            .catch((err) => {
              props.setAlert(
                "Priority Cannot Exceed more than 10",
                "error"
              );
              settaskChange({
                title: taskChange.title,
                weight: "",
                start_date: taskChange.start_date,
                end_date: taskChange.end_date,
              });
            });
          }
        } else {
          props.setAlert("Start Date cannot Be greater than End date", "error");
        }
      }
    }
    if (showTable) {
      if (
        taskChange.title === "" ||
        taskChange.weight === "" ||
        taskChange.start_date === "" ||
        taskChange.end_date === ""
      ) {
        setShowTable(false);
        props.setAlert("Please enter all Fields before Submitting", "error")
      }
    }
  };

 
 

  
  useEffect(() => {
    let unmounted = false;
    getTasks();

    return () => {
      unmounted = true;
    };
  }, []);

  const getTasks = () => {
    showLoader()
    axiosInstance
      .get(API.ProjectTaskList + `${projectID}/task/`)
      .then((res) =>{

      setTaskList(res.data.results);
      hideLoader()
      
    })
      .catch((err) => console.log(err));
  };

  const getTaskId = (id) => {
    setValue(id);
  };


  useEffect(() => {
    if(taskList){
      if(taskList.length >= 1){
        const [ taskID ] = taskList;
        setDynamicID(taskID.id)
      }
      else{
        setDynamicID(0)
      }
    }

  }, [taskList])



  const useOutsideClickRef = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleSubmitTask();
          settaskChange({
            title: "",
            weight: "",
            start_date: "",
            end_date: "",
          });
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, showTable, taskChange]);
  };

  const wrapperRef = useRef(null);
  useOutsideClickRef(wrapperRef);

  

  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
            done="70"
            projectTitle={projectTitle}
            projectID={projectID}
            taskRoom={props.taskRoom}
          />
        }
        topButton="button"
      />

      <main>
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "100%" }}>
            <Grid container>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  md={12}
                  style={{ margin: "15px" }}
                >
                  <div className={styles.buttonAndFilter}>
                    <ProjectButton onClick={CreateTable} text="+ Add Task" />
                    {/* <div className={styles.filterComponent}>
                        <Filter />
                      </div> */}
                  </div>
                </Grid>
              </Grid>
              <Grid item lg={12} sm={12} xl={12} md={12} xs={12}>
                <Divider />
                <BasicTable
                  task="task"
                  id="ID"
                  name="Task Name"
                  weight="Weight"
                  stDate="Start"
                  endDate="End"
                  duration="Duration"
                  sid="S. ID"
                  getTasks={getTasks}
                  pid="P. ID"
                  status="Status"
                
                  getTaskId={getTaskId}
                  
                  
                  projectTitle={projectTitle}
                  showTable={showTable}
                  projectID={projectID}
                  taskList={taskList}
                  loader = {loader}
                  setAlert={props.setAlert}
                  dynamicTable={
                    showTable && (
                      <>
                        <TableRow style={{ width: "100%" }} ref={wrapperRef}>
                          <TableCell
                            style={{
                              width: "4%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                            align="center"
                          >
                            {dynamicID ? (dynamicID + 1) : 1 }
                          </TableCell>
                          <TableCell
                            style={{
                              width: "55%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="text"
                              autoFocus
                              placeholder="TaskName"
                              value={taskChange.title}
                              className={styles.tableInput}
                              name="title"
                              onChange={handleTaskChange}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "5%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="number"
                              placeholder="1 to 10"
                              name="weight"
                              min="1"
                              max="10"
                              value={taskChange.weight}
                              className={styles.tableInput}
                              onChange={handleTaskChange}
                              className={styles.tableInput}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "10%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="date"
                              placeholder="Start Date"
                              name="start_date"
                              value={taskChange.start_date}
                              onChange={handleTaskChange}
                              className={styles.tableInput}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "10%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="date"
                              placeholder="End date"
                              name="end_date"
                              value={taskChange.end_date}
                              onChange={handleTaskChange}
                              className={styles.tableInput}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "6%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Duration"
                              className={styles.tableInput}
                            />
                          </TableCell>
                          {/* <TableCell
                            style={{
                              width: "6%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="number"
                              placeholder="Successor"
                              className={styles.tableInput}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "6%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="number"
                              placeholder="Predecessor"
                              className={styles.tableInput}
                            />
                          </TableCell> */}
                          <TableCell
                            style={{
                              width: "6%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <select
                              placeholder="Status"
                              disabled
                              className={styles.tableInput}
                            >
                              <option value="open">open</option>
                              <option value="inprogress">inprogress</option>
                              <option value="parked">parked</option>
                              <option value="closed">closed</option>
                            </select>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "4%",
                              border: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            <input
                              type="text"
                               disabled
                              className={styles.tableInput}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  }
                />
                {showTable && (
                  <div
                    className={styles.addTaskButton}
                    onClick={handleSubmitTask}
                  >
                    Add Task ...
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default connect(null, { setAlert })(Tasks);

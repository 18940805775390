import React, { Fragment, useEffect, useState } from "react";
import { Alert, NotificationBar, SideBar } from "../components";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const DefaultLayout = (props) => {
  const history = useHistory();
  return <Fragment>{props.children} <Alert /></Fragment>;
};

export default connect(null, null)(DefaultLayout);

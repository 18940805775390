import React, { useContext, useState } from "react";


import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import { Button } from "@material-ui/core";

import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";

import { MessageContext } from "../../components/ContactMessages";

import { Modal } from "../../components";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "10px",
  },
})(MuiTableCell);

const ContactMessageData = () => {
  const [loading, setLoading] = useState(false)
  const [openDeleteReminder, setOpenDeleteReminder] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState(null)
  const messages = useContext(MessageContext);
  const [deleteId, setDeleteId] = useState();


  const DeleteData = (id) => {
    setDeleteId(id)
    setOpenDeleteReminder(!openDeleteReminder)
  }

  const ConfirmDeleteData = (deleteId) => {
    setLoading(true)
    axiosInstance.delete(API.DeleteMessage + `${deleteId}/`)
      .then(res => {
        if (res) {
          setLoading(false)
          setFilteredMessages(messages.filter((message) => message.id !== deleteId))

          setOpenDeleteReminder(false)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    (loading ? <div><i className="fa fa-refresh fa-spin"></i>Loading Wait...</div> : (filteredMessages ? filteredMessages.map(message => (
      <React.Fragment key={message.id}>
        <Hidden smDown>
          <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}>
            <TableCell>{message.id}</TableCell>
            <TableCell component="th" scope="row" align="left">
              {message.full_name}
            </TableCell>
            <TableCell align="left">
              <a href={`mailto:${message.email_address}`}>
                {message.email_address}
              </a>
            </TableCell>
            <TableCell align="left">{message.phone_number}</TableCell>
            <TableCell align="left" style={{ width: "45%" }}>
              {message.message}
            </TableCell>
            <TableCell align="left">
              <Button
                color="secondary"
                onClick={() => DeleteData(message.id)}
              >
                Delete
                </Button>
            </TableCell>
          </TableRow>
        </Hidden>

        <Hidden mdUp>
          <TableRow component={Paper}>
            <TableCell
              align="left"
              style={{
                width: "40%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>S.No.</p>
              <p>Name</p>
              <p>Email</p>
              <p>phone Number</p>
              <p>Message</p>
              <p>Action</p>
            </TableCell>
            <TableCell
              align="left"
              style={{
                width: "40%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>{message.id}</p>
              <p>{message.full_name}</p>
              <p>{message.email_address}</p>
              <p>{message.phone_number}</p>
              <p>{message.message}</p>
            </TableCell>
            <TableCell align="center">
              <Button color="secondary">Delete</Button>
            </TableCell>
          </TableRow>
        </Hidden>
        <Modal
          openPop={openDeleteReminder}
          onClick={() => setOpenDeleteReminder(!openDeleteReminder)}
          backdropClicked={() => setOpenDeleteReminder(!openDeleteReminder)}
          title="Confirm Delete"
          deleteBar="Delete Bar"
          ConfirmDeleteData={() => ConfirmDeleteData(deleteId)}
        />
      </React.Fragment>)) : messages.map((message) => (
        <React.Fragment key={message.id}>
          <Hidden smDown>
            <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}>
              <TableCell>{message.id}</TableCell>
              <TableCell component="th" scope="row" align="left">
                {message.full_name}
              </TableCell>
              <TableCell align="left">
                <a href={`mailto:${message.email_address}`}>
                  {message.email_address}
                </a>
              </TableCell>
              <TableCell align="left">{message.phone_number}</TableCell>
              <TableCell align="left" style={{ width: "45%" }}>
                {message.message}
              </TableCell>
              <TableCell align="left">
                <Button
                  color="secondary"
                  onClick={() => DeleteData(message.id)}
                >
                  Delete
            </Button>
              </TableCell>
            </TableRow>
          </Hidden>

          <Hidden mdUp>
            <TableRow component={Paper}>
              <TableCell
                align="left"
                style={{
                  width: "40%",
                  lineHeight: "0.5rem",
                  padding: "20px",
                }}
              >
                <p>S.No.</p>
                <p>Name</p>
                <p>Email</p>
                <p>phone Number</p>
                <p>Message</p>
                <p>Action</p>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: "40%",
                  lineHeight: "0.5rem",
                  padding: "20px",
                }}
              >
                <p>{message.id}</p>
                <p>{message.full_name}</p>
                <p>{message.email_address}</p>
                <p>{message.phone_number}</p>
                <p>{message.message}</p>
              </TableCell>
              <TableCell align="left">
                <Button color="secondary">Delete</Button>
              </TableCell>
            </TableRow>
          </Hidden>
          <Modal
            openPop={openDeleteReminder}
            onClick={() => setOpenDeleteReminder(!openDeleteReminder)}
            backdropClicked={() => setOpenDeleteReminder(!openDeleteReminder)}
            title="Confirm Delete"
            deleteBar="Delete Bar"
            ConfirmDeleteData={() => ConfirmDeleteData(deleteId)}
          />
        </React.Fragment>))
    )));
};

export default ContactMessageData;

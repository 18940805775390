import React, { useState, useEffect, createContext } from "react";
import { Grid } from "@material-ui/core";
import { Member, NotificationBar } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls";
import ConferenceContext from './conferenceContext';

const ListOfConferences = (props) => {
  const [conferenceList, setConferenceList] = useState(null);

  useEffect(() => {
    getConferenceList();
  }, []);

  const getConferenceList = async () => {
    try {
      const res = await axiosInstance.get(API.GetConferenceList);
      setConferenceList(res.data);
    } catch (error) {
      throw error;
    }
  };

  if (!conferenceList) {
    return <div>Loading...</div>;
  }

  return (
    <ConferenceContext.Provider value={conferenceList}>
      <React.Fragment>
        <NotificationBar title="Conferences" topButton="Top Button" />
        <Grid container>
          <Member
            listOfConference="List Of conferences"
            td1="S.No"
            td2="Title"
            td3="Project"
            td4="Task"
            td5="Members"
            td6="Action"
          />
        </Grid>
      </React.Fragment>
    </ConferenceContext.Provider>
  );
};

export default ListOfConferences;

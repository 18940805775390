import React, { useState } from "react";
import API from '../../urls/index';
import axiosInstance from '../../utils/axiosConfig';
import { Button, DocumentModal } from "../../components";
import ProjectDocumentSelectBox from "../ProjectDocumentSelectBox";

const ResubmitDocument = (props) => {

    const [ documentResubmit, setDocumentResubmit ] = useState({status : ''})
    const [ postImage, setPostImage ] = useState(null)
    const handleDocumentFileChange = (event) => {
        setPostImage({ document: event.target.files });
    }

    const handleDocumentResubmission = (e) => {
        setDocumentResubmit({[e.target.name] : e.target.value})
    }

    const handleDocumentResubmitSubmit = (e) => {
        e.preventDefault()

        let form_data = new FormData();

        form_data.append("file", postImage.document[0]);
        form_data.append("status", documentResubmit.status);

        axiosInstance.post(API.SingleTaskDocument + `${props.ID}/file/add/`, form_data)
                     .then(res => console.log(res))
                     .catch(err => console.log(err))
    }
  return (
    <>
      <ProjectDocumentSelectBox htmlFor="Status"
        opt1="Not Deliverable"
        value1="Not Deliverable"
        opt2="Resubmission"
        value="Resubmission"
        opt3="Not Deliverable Internal"
        value3="Not Deliverable Internal"
        opt4="Initial Submission"
        value4="Initial Submission"
        name="status"
        onChange={handleDocumentResubmission}
      />
      <DocumentModal name="file" type="file" label="Document" onChange={handleDocumentFileChange} />
      <Button button={props.button} onClick={handleDocumentResubmitSubmit} />
    </>
  );
};

export default ResubmitDocument;

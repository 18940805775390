import React from "react";
import { connect } from "react-redux";
import { Profile } from "../../containers";
import { setAlert } from '../../actions/alert';


const UserProfile = ({ info, setAlert }) => {

  return (

      <Profile
        userName={info.username}
        email={info.email}
        link="User Profile"
        setAlert = {setAlert}
      />

  );
};

const mapStateToProps = (state) => {
  return { info: state.auth.data };
};

export default connect(mapStateToProps, { setAlert })(UserProfile);

import React, { useContext, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAlert} from '../../actions/alert';
import { Modal } from '../../components';
import TaskActionItemsContext from '../../components/TaskActionItems/TaskActionItemsContext';
import { TableRow, TableCell, Hidden, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import SimpleMenu from '../Menu';
import './task.css';


const TaskActionItemsList = (props) => {
   
    const [taskActionItems, getTaskActionItems, proID, loader] = useContext(TaskActionItemsContext)

    const [ openDelete, setOpenDelete ] = useState(false)
    const [openActionItemModal, setOpenActionItemModal] = useState(false);
    const [ editState, setEditState ] = useState({id:'', title: '', assigned_to: '', due_date: '', status : false})
    const [ ID, setID ] = useState()
    const [ loading, setLoading ]= useState(false);
    const [status, setStatus] = useState({ status: "" });
    const [prevStatus, setPrevStatus] = useState(null);
    const [ response, setResponse ] = useState(false);
    const [ actionID, setActionID ] = useState(null);

    const deleteActionItem = (id) => {
        setOpenDelete(!openDelete)
        setID(id)
    }

    const capitalizeFirstChar = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const deleteTaskActionItem = (ID) => {
      setLoading(true)
        axiosInstance.delete(API.DeleteTaskActionItem + `${ID}/update/`)
                     .then(res => {if(res){
                         props.setAlert("Task Action Item deleted Successfully", "success")
                         setOpenDelete(!openDelete)
                         setResponse(true)
                         setLoading(false)
                         
                     }})
                     .catch(err => { if(err ){
                         props.setAlert("You do not have permission to perform this action", 'error')
                         setOpenDelete(!openDelete)
                         setLoading(false)
                     }})  
       
    }


    const getFilteredActionItems = (taskActionItems) => {
        if(response){
            return taskActionItems.filter(task => task.id !== ID)
        } 
        return taskActionItems
    }

    const updateTaskActionItem = (id, title, assigned_to, due_date, status) => {
      setOpenActionItemModal(!openActionItemModal)
      setEditState({id: id, title: title, assigned_to : assigned_to, due_date: due_date, status: status})
    }

    const handleCompleted = (id, status) => {
      setID(id)
      if(!status) {
        setLoading(true)
        axiosInstance.patch(API.DeleteActionItem + `${id}/update/`, {status : true})
                      .then(res => {if(res.status === 200){
                        getTaskActionItems()
                        props.setAlert("Marked This Action Item Completed", "success")
                        setLoading(false)
                      }})
                      .catch(err => { if(err){
                        props.setAlert("Something Went Wrong", "error")
                        setLoading(false)
                      }})
      }
      else if(status){
        setLoading(true)
        axiosInstance.patch(API.DeleteActionItem + `${id}/update/`, {status : false})
                      .then(res => {if(res.status === 200){
                        getTaskActionItems()
                        props.setAlert("Marked This Action Item InCompleted", "success")
                        setLoading(false)
                      }})
                      .catch(err => { if(err){
                        props.setAlert("Something Went Wrong", "error")
                        setLoading(false)
                      }})
      }
    }

    const handleStatusChange = (e) => {
      setStatus({ [e.target.name]: e.target.value });
    };
  
    const handleStatusSubmit = () => {
      
      if (prevStatus.status === status.status) {
        setActionID(null)
      } else {
     
        axiosInstance
          .patch(API.DeleteMyActionItem + `${actionID}/update/`, status)
          .then((res) => {
            if (res) {
              props.setAlert("Action Items Editted Successfully", "success");
              getTaskActionItems()
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err) {
              props.setAlert(
                "You Do not have permission to perform this action",
                "error"
              );
              setLoading(false);
            }
          });
      }
    };
    const setPreviousStatus = (status, id) => {
      setStatus({status : status});
      setPrevStatus({ status: status });
      setActionID(id);
    };

    const useOutsideClickRef = (ref) => {
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            handleStatusSubmit();
            setActionID(null);
          }
        };
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, status]);
    };
  
  
  
    const wrapperRef = useRef(null);
    useOutsideClickRef(wrapperRef);

    return (
        !taskActionItems ? <div>{loader}</div> :
        getFilteredActionItems(taskActionItems).map((task, index) => (<React.Fragment key={task.id}>
          
                        
            <TableRow
              style={{ borderBottom: "1px solid rgba(224,224,224,1)"}}
              className="largeScreen"
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell component="th" scope="row" align="left">
               {task.title}
              </TableCell>
              <TableCell align="left">
                {task.owner.first_name} {task.owner.last_name}
              </TableCell>
              <TableCell align="left">
                {task.assigned_to.first_name} {task.assigned_to.last_name}
              </TableCell>
              <TableCell align="left">
                {task.due_date}
              </TableCell>
              <TableCell
              align="left"
             
            >
              {actionID === task.id ? (
                <select
                  ref={wrapperRef}
                  name="status"
                  onChange={handleStatusChange}
                >
                  <option value="incomplete" selected = {prevStatus.status === "incomplete" ? true : false}>incomplete</option>
                  <option value="complete" selected = {prevStatus.status === "complete" ? true : false}>complete</option>
                  <option value="parked" selected = {prevStatus.status === "parked" ? true : false}>parked</option>
                </select>
              ) : (
                <div onClick={() => setPreviousStatus(task.status, task.id)}>{capitalizeFirstChar(task.status)}</div>
              )}
            </TableCell>
              <TableCell align="left">
                <SimpleMenu edit="Edit" delete="Delete" updateProject={() => updateTaskActionItem(task.id, task.title, task.assigned_to, task.due_date, task.status)} deleteProject={() => deleteActionItem(task.id)} />
                
              </TableCell>
              {/* <TableCell align="center">
                {loading && ID === task.id ? <i className="fa fa-refresh fa-spin"></i> : <CheckCircleOutlineOutlinedIcon className={task.status ? "green" : "gray"} onClick={() => handleCompleted(task.id, task.status)} />}
                {console.log(ID)}
              </TableCell> */}
              
            </TableRow>
           
            <TableRow component={Paper} className="smallScreen" >
                <TableCell
                  align="left"
                  style={{
                    width: "40%",
                    lineHeight: "0.5rem",
                    padding: "20px",
                  }}
                 
                >
                  <p>S.No.</p>
                  <p>Action</p>
                  <p>Name</p>
                  <p>Due Date</p>
               
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    width: "40%",
                    lineHeight: "0.5rem",
                    padding: "20px",
                  }}
                 
                >
                  <p>{index+1}</p>
                  <p>{task.title}</p>
                  <p>{task.assigned_to.first_name} {task.assigned_to.last_name}</p>
                  <p> {task.due_date}</p>
                
                </TableCell>
                <TableCell  >
                  <SimpleMenu edit="Edit" delete="Delete" updateProject={() => updateTaskActionItem(task.id, task.title, task.assigned_to.id, task.due_date, task.status)} deleteProject={() => deleteActionItem(task.id)} />
                </TableCell>
               
              </TableRow>
           
            <Modal
            openPop={openActionItemModal}
            onClick={() => setOpenActionItemModal(!openActionItemModal)}
            backdropClicked={() => setOpenActionItemModal(!openActionItemModal)}
            title="Update Action Item"
            button="Update Action Item"
            editState={editState}
            actionItemupdate={true}
            />
            <Modal
                  openPop={openDelete}
                  onClick = {() => setOpenDelete(!openDelete)}
                  backdropClicked = {() => setOpenDelete(!openDelete)}
                  deleteBar = "Delete Bar"
                  title="Confirm Delete"
                  loading={loading}
                  ConfirmDeleteData = {() => deleteTaskActionItem(ID)}
                  closeDialog = { () => setOpenDelete(!openDelete)}
                /> 
          </React.Fragment>
            
        ))
    )
}

export default connect(null, { setAlert })(TaskActionItemsList);
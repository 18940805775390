import React, { useState } from 'react';
import { DocumentModal, Button } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';

import ProjectUsers from '../ProjectUsers';

const AddProjectActionItems = (props) => {
    const { projectID } = props;



    const [loading, setLoading] = useState(false)
    const [actionItems, setActionItems] = useState({ title: '', due_date: '', status: '' })
    const [assigned, setAssigned] = useState({ assigned: '' })

    const handleProjectActionItemsChange = (e) => {
        setActionItems({ ...actionItems, [e.target.name]: e.target.value })
    }

    const handleMembersFieldChange = (value) => {
        setAssigned({ assigned: value })


    }


    const handleProjectActionItemsSubmit = (e) => {
        if (actionItems.title === '' && actionItems.due_date === '') {
            props.setAlert("Add Required Fields before submitting", "error")
        }
        else if (assigned.assigned === '') {
            props.setAlert("Add Member You want to assign this task to", "error")
        }
        else {
            e.preventDefault();
            setLoading(true)
            const [assignTo] = assigned.assigned;

            const data = {
                title: actionItems.title,
                due_date: actionItems.due_date,
                status: "incomplete",
                assigned_to: assignTo.user.id

            }


            axiosInstance.post(API.AddProjectActionItems + `${projectID}/actionitem/add`, data)
                .then(res => {
                    if (res.status === 200) {

                        props.getProjectActionItems()


                        props.setAlert("Action Item added successfully", "success")
                        setActionItems({ title: '', due_date: '', status: false })
                        setAssigned({ assigned: '' })
                        props.close()
                        setLoading(false)

                    }
                })
                .catch(err => props.setAlert("Something went wrong", "error"))
        }




    }


    return (
        <>
            <ProjectUsers label="Assigned To" value={setAssigned.assigned} proID={projectID} handleMembersFieldChange={handleMembersFieldChange} />
            <DocumentModal value={setActionItems.title} label="Action" type="text" name="title" onChange={handleProjectActionItemsChange} />

            <DocumentModal value={setActionItems.due_date} name="due_date" label="Due Date" type="date" onChange={handleProjectActionItemsChange} />
            <Button loading={loading} button={props.button} onClick={handleProjectActionItemsSubmit} />
        </>
    )
}

export default AddProjectActionItems;
import React from "react";
import { Grid } from "@material-ui/core";
import {
  NotificationBar,
  Member,
  CustomizedTab,
  Modal,
  ProjectButton,
} from "../../components";

import styles from "./members.module.css";

const Members = (props) => {

  const { taskTitle } = props;
  const { projectID } = props;
  const { proID } = props
  const [openMember, SetOpenMember] = React.useState(false);

  // const [ projectMembersList, setProjectMembersList ] = useState(null);

  // useEffect(() => {
  //    getProjectMembersList()
  // },[])

  // const getProjectMembersList = async () => {
  //     await axiosInstance.get(API.ListProjectMembers + `${projectID}/members/`)
  //                        .then(res => {if(res.status === 200){
  //                           setProjectMembersList(res.data.results)
  //                        }
  //                        else{
  //                            props.setAlert("Error Loading Data", "Error")
  //                        }
  //                     })
  //                        .catch(err => console.log(err))
  // } 


  // console.log(projectMembersList)

  return (

    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
            done="70"
            projectID={projectID}
            taskRoom={props.taskRoom}
            taskTitle={taskTitle}
            taskID={props.taskID}
            proID={proID}
            projectTitle={props.projectTitle}
          />
        }
        topButton="button"
      />

      <main>
        <div className={styles.myitemOuterWrapper}>
          <Grid container>
            <Grid container>
              <Grid item lg={12} style={{ margin: "15px 0px" }}>
                <ProjectButton
                  onClick={() => SetOpenMember(!openMember)}
                  text="+ Members"
                />
              </Grid>
            </Grid>
            <div
              style={{
                borderBottom: "1px solid rgba(224,224,224,1)",
                width: "100%",
              }}
            ></div>
            <Member
              getProjectMembersList={props.getProjectMembersList}
              proMember="Project Member"
              taskRoom={props.taskRoom}
              td1="S.No"
              td2="Name"
              td3="Project Role"
              td4="Email"
              td5="Contact"
              td6="Action"
              taskID={props.taskID}
              // projectMembers={projectMembersList}
              projectID={projectID}

              proID={proID}
            />
          </Grid>
        </div>
      </main>
      <Modal
        openPop={openMember}
        onClick={() => SetOpenMember(!openMember)}
        backdropClicked={() => SetOpenMember(!openMember)}
        title="Add Members"
        projectID={projectID}
        members="members"
        button="Add Member"
        taskRoom={props.taskRoom}
        taskID={props.taskID}
        proID={proID}
        // projectMembers={projectMembersList}
        getTaskMembersList={props.getTaskMembersList}
        getProjectMembersList={props.getProjectMembersList}
        memberRole="MemberRoles"
      />
    </React.Fragment>
  );
};

export default Members;

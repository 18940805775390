import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import './notification.css';

const NotificationPopOver = () => {
    return (
        <div className="notification">
            <div className="notificationOuterWrapper">
            <ul>
                <div className="notificationInnerWrapper">
                 <div className="Avatar">
                   <Avatar style={{height:'60px', width:'60px'}} /> 
                 </div>  
                <li>
                    home kjhsdkhglksfjglkdfjlgkjdf,mnbkjhv  kjshdgkjhsdg kjhsdlkfsdlnflsdnflkds sdlkjhglskdh, ani aru sankjhkf jhgsfjh
                    <p>Date : 2019-09-78</p>
                </li>
                
                </div>
                <div className="notificationInnerWrapper">
                 <div className="Avatar">
                   <Avatar style={{height:'60px', width:'60px'}} /> 
                 </div>  
                <li>
                    home kjhsdkhglksfjglkdfjlgkjdf,mnbkjhv  kjshdgkjhsdg kjhsdlkfsdlnflsdnflkds sdlkjhglskdh, ani aru sankjhkf jhgsfjh
                    <p>Date : 2019-09-78</p>
                </li>
                
                </div>
                <div className="notificationInnerWrapper">
                 <div className="Avatar">
                   <Avatar style={{height:'60px', width:'60px'}} /> 
                 </div>  
                <li>
                    home kjhsdkhglksfjglkdfjlgkjdf,mnbkjhv  kjshdgkjhsdg kjhsdlkfsdlnflsdnflkds sdlkjhglskdh, ani aru sankjhkf jhgsfjh
                    <p>Date : 2019-09-78</p>
                </li>
                
                </div>
                
            </ul>
        </div>
        </div>
    )
}

export default NotificationPopOver;
import React, {
  useState,

  useRef,
  useEffect,

} from "react";
import { v4 as uuid } from 'uuid';
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { getUserProfileInfo } from '../../actions/userProfile';
import firebase from 'firebase';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import ChatContext from "../../containers/Chat/chatContext";
// import { useCollectionData } from "react-firebase-hooks/firestore";


import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";


import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import './chatForm.css';
import db from '../../firebase';

const useStyles = makeStyles((theme) => ({
  formInputChat: {
    border: "1px solid black",
    marginTop: '20px',
    padding: '5px 10px',
    borderRadius: '3px'
  },

  actions: {
    display: "flex",
  },

  expand: {
    marginLeft: "auto",
  },
  chatbox_form: {
    marginTop: "15px",
    border: "none !important",
    background: "#e5e7ea !important",
  },
  chatInput: {

    color: "#4c4c4c",
    fontSize: "15px",
    minHeight: "48px",
    width: "100%",
  },
  chatFormBtns: {

    float: "right",
    marginTop: "-10px",
  },
}));

const ChatForm = ({ auth, setAlert, rooms, value, user_data }) => {
  const msgID = uuid();
  const fileInputRef = useRef();
  const imageFileInputRef = useRef();
  const submitBtnRef = useRef();
  const chatBoxFieldRef = useRef();

  const [textMessage, setTextMessage] = useState("");
  const [fileInput, setFileInput] = useState("");
  const allInputs = { imgUrl: "" };
  const [fileAsUrl, setFileAsUrl] = useState("");
  const classes = useStyles();


  useEffect(() => {

    chatBoxFieldRef.current.focus();



  }, [])

  // const query = messagesRef.orderBy("createdAt", "asc"); //.limitToLast(25);
  // const [messages] = useCollectionData(query, { idField: "id" });
  const [company_id] = auth.companies;

  const handleSubmit = async () => {
    setTextMessage("")
    if (isMessageEmpty(textMessage)) {
      return false;
    }
    await db.collection("rooms").doc(rooms).collection("messages").add({
      id: msgID,
      user: `${user_data.first_name} ${user_data.last_name}`,
      message: textMessage,
      timestamp: new Date(), //chatContextObj.firestore.FieldValue.serverTimestamp() || null,
      image: user_data.photo,
      email: user_data.username,
      user_id: user_data.id,

    });

  };

  const isMessageEmpty = (textMessage) => {
    return adjustTextMessage(textMessage).length === 0;
  };

  const adjustTextMessage = (textMessage) => {
    return textMessage.trim();
  };

  const handleMessageChange = (e) => {
    setTextMessage(e.target.value);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit();
      setTextMessage("");
    }
  };



  const handleAttachements = (e) => {
    let currentTarget = e.currentTarget.id;
    const file = e.target.files[0];
    let fileExt = file.name.substring(file.name.lastIndexOf("."));

    let validExts =
      currentTarget === "icon-button-photo"
        ? new Array(".png", ".jpg", ".jpeg", ".gif")
        : new Array(".xlsx", ".xls", ".csv", ".txt", ".pdf", ".docx", ".doc");
    if (validExts.indexOf(fileExt) < 0) {
      alert(
        "Invalid file selected, valid files are of " +
        validExts.toString() +
        " types."
      );
      return false;
    }
    setFileInput(file);
  };

  useEffect(() => {
    if (fileInput === "") {
      return false;
    }

    const uploadTask = firebase.storage()
      .ref(`/attachments/${fileInput.name}`)
      .put(fileInput);

    setAlert("Uploading files ... ", "info");

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {

        firebase.storage()
          .ref("attachments")
          .child(fileInput.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setFileAsUrl(fireBaseUrl);
            setFileInput("");
          });
      }
    );
  }, [fileInput]);

  useEffect(() => {
    async function sendAttachment() {
      if (fileAsUrl === "") {
        return false;
      }
      await db.collection("rooms").doc(rooms).collection("messages").add({
        id: msgID,
        user: `${user_data.first_name} ${user_data.last_name}`,
        message: fileAsUrl,
        attachmentName: fileInput.name != "" ? fileInput.name : "",
        timestamp: new Date(),
        image: user_data.photo,
        email: user_data.username,
        user_id: user_data.id,

      });
    }
    sendAttachment();
  }, [fileAsUrl]);





  const openFileDialog = (e) => {
    if (e.currentTarget.id === "photo-btn") imageFileInputRef.current.click();
    else fileInputRef.current.click();
  };


  return (
    <div className={classes.formInputChat}>
      <form id="chat-form" onSubmit={handleSubmit}>
        {/* <Card className={clsx(classes.chatbox_form)}> */}



        <CardContent>
          <textarea


            value={textMessage}
            rows={2}

            className={clsx(classes.chatInput)}
            placeholder="Send you message here"
            onKeyUp={handleKeypress}
            onChange={handleMessageChange}
            ref={chatBoxFieldRef}

          />
          <div

            className={`${clsx(classes.chatFormBtns)} ${clsx(classes.actions)}`}
          >
            <React.Fragment>
              <input
                id="icon-button-files"
                onChange={handleAttachements}
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}

              />
              <label htmlFor="icon-button-files">
                <IconButton
                  aria-label="add files"
                  id="file-btn"
                  onClick={openFileDialog}

                >
                  <AttachFileIcon fontSize="small" />
                </IconButton>
              </label>
            </React.Fragment>

            <React.Fragment>
              <input
                id="icon-button-photo"
                onChange={handleAttachements}
                type="file"
                style={{ display: "none" }}
                ref={imageFileInputRef}
                accept="image/*"
              />
              <label htmlFor="icon-button-photo">
                <IconButton
                  aria-label="insert photo"
                  id="photo-btn"
                  onClick={openFileDialog}

                >
                  <InsertPhotoIcon fontSize="small" />
                </IconButton>
              </label>
            </React.Fragment>
            <IconButton

              //  className={isConversation ? clsx(classes.expandConversation) : clsx(classes.expand)}
              aria-label="send message"
              // onClick={handleSubmit}
              ref={submitBtnRef}
              style={{ marginTop: '-5px' }}

              onClick={handleSubmit}


            >
              <SendIcon fontSize="small" />
            </IconButton>
          </div>

        </CardContent>

        {/* </Card> */}

      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
    user_data: state.userProfile.data,
    error: state.auth.error,
  };
};

export default connect(mapStateToProps, { getUserProfileInfo, setAlert })(ChatForm);

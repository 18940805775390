import React, { useContext, useState } from "react";


import MuiTableCell from "@material-ui/core/TableCell";
import {  withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import VisibilityIcon from '@material-ui/icons/Visibility';
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import { formatDate } from '../../utils/dateTimeUtility';

import ProjectDocumentContext from "../../containers/ProjectDocument/ProjectDocumentContext";
import { SimpleMenu, Modal, ContactMessageData } from "../../components";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "2px",
  },
})(MuiTableCell);

const DocumentTable = (props) => {



  const [ editState, setEditState ] = useState({id : '', title : ''})
  const [openDelete, setOpenDelete] = useState(false);
  const [ openDocumentEdit, setOpenDocumentEdit ] = useState(false)
  const [ projectDocumentList, getProjectDocumentList, loader] = useContext(ProjectDocumentContext);
  const [ loading, setLoading]= useState(false);
  
  const [ response, setResponse ] = useState(false)
  const [ID, setId] = useState();

  const deleteProjectDocument = (id) => {
    setOpenDelete(!openDelete);
    setId(id);

  };

  const deleteProjectDocumentConfirm = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.ProjectDocumentDelete + `${ID}/`)
      .then((res) => {
        if (res) {
          setResponse(true);
          setOpenDelete(!openDelete);
          props.setAlert("Document deleted successfully", "success")
          setLoading(false)
        }
      })
      .catch((err) => {
        props.setAlert(err.response.data.detail, "error")
        setOpenDelete(!openDelete);
        setLoading(false)
      });
  };

  const editProjectDocument = (id, name) => {
    setOpenDocumentEdit(!openDocumentEdit)
    setEditState({id : id, name : name})
  }

  
  const getFilteredDocument = (projectDocumentList) => {
    if(response){
      return projectDocumentList.filter(project => project.id !==ID);
    }
    else{
      return projectDocumentList;
    }
  }

 

  return (
    !projectDocumentList ? <div>{loader}</div> : getFilteredDocument(projectDocumentList).map((document, index) => (
        <React.Fragment key={document.id}>
          
            <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }} className="largeScreen">
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell component="th" scope="row" align="center" >
                {document.name} <a href={`http://nascent.link${document.document_file.map(file => file.file)}`} target="_blank"><VisibilityIcon fontSize="small" style={{color:"gray"}} /></a>
              </TableCell>
              
              <TableCell align="center">
                {document.document_file.map(file => file.document_status.map(status => status.first_name))} {' '}
                {document.document_file.map(file => file.document_status.map(status => status.last_name))}
              </TableCell>
              <TableCell align="center">
                {document.document_file.map(file => file.document_status.map(status => formatDate(status.action_date)))}
               
              </TableCell>

              <TableCell align="center">
                <SimpleMenu
                  edit="Edit"
                  delete="Delete"
                  updateProject = { () => editProjectDocument(document.id, document.name)}
                  deleteProject={() => deleteProjectDocument(document.id)}
                />
              </TableCell>
            </TableRow>
          
            <TableRow component={Paper} className="smallScreen">
              <TableCell
                align="left"
                style={{
                  width: "40%",
                  lineHeight: "0.5rem",
                  padding: "20px",
                }}
              >
                <p>S.No.</p>
                <p>Document</p>
                <p>Uploaded Date</p>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: "40%",
                  lineHeight: "0.5rem",
                  padding: "10px",
                }}
              >
                <p>{document.id}</p>
                <p>{document.name} <a href={`http://nascent.link${document.document_file.map(file => file.file)}`} target="_blank"><VisibilityIcon fontSize="small" style={{color:"gray"}} /></a></p>
                <p> {document.document_file.map(file => file.document_status.map(status => formatDate(status.action_date)))} </p>
              </TableCell>
              <TableCell align="center" style={{ width: "20%" }}>
                <SimpleMenu
                  edit="Edit"
                  delete="Delete"
                  deleteProject={() => deleteProjectDocument(document.id)}
                />
              </TableCell>
            </TableRow>
         
          <Modal 
          openPop={openDocumentEdit}
          onClick={() => setOpenDocumentEdit(!openDocumentEdit)}
          backdropClicked={() => setOpenDocumentEdit(!openDocumentEdit)}
          title="Update Document"
          button ="Update"
          editState={editState}
          updateDocument={true}
          />
          <Modal
            openPop={openDelete}
            onClick={() => setOpenDelete(!openDelete)}
            backdropClicked={() => setOpenDelete(!openDelete)}
            deleteBar="Delete Bar"
            title="Confirm Delete"
            loading={loading}
            ConfirmDeleteData={() => deleteProjectDocumentConfirm(ID)}
            closeDialog={() => setOpenDelete(!openDelete)}
          />
        </React.Fragment>
      )));
};

export default DocumentTable;

import React from 'react';

import styles from './documentmodal.module.css';




const DocumentModal = (props) => {

  return(
      
   
    
        <div className={styles.uploadElement}>
  
             <label htmlFor={props.htmlFor}>{props.label}</label>  
           {/* <Button icon={<UploadOutlined />} style={{border:"1px solid rgba(224,224,224,1)", width:"45%", outline:"none", color:"gray", letterSpacing:'0.15rem'}} >Click to Upload <ExpandMore fontSize="small" /></Button> */}
           <input type={props.type} name={props.name} onChange={props.onChange} value={props.value} />
        
        </div>
   
)
}

export default DocumentModal;
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";

import { getNotifications } from "../../actions/notifications";
import { getUserProfileInfo } from "../../actions/userProfile";
import VideocamIcon from "@material-ui/icons/Videocam";
import AccountIcon from "../../img/AccountIcon.svg";
import NotificationIcon from "../../img/NotificationIcon.svg";
import { Button, Hidden } from "@material-ui/core";
import { Modal } from "../../components";
import { logout } from "../../actions/auth";
import { useStyles } from "./styles.js";
import axiosInstance from "../../utils/axiosConfig";
import { formatDate } from "../../utils/dateTimeUtility";
import "./notificationBar.css";

import API from "../../urls/index";

const NotificationBar = (props) => {
  const { company_head } = props;
  // const [ n , setN ] = useState(8)
  // const [ currentNotification, setCurrentNotification ] = useState(1);
  // const [ currentNotifications, setCurrentNotifications ] = useState(null);
  // const [ notificationPerPage, setNotificationPerPage ] = useState(4);
  const [companyId] = company_head.companies;

  const history = useHistory();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConference, setOpenConference] = React.useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [roomName, setRoomName] = useState(false);

  const isMenuOpen = Boolean(anchorEl) || Boolean(notificationAnchorEl);

  const generateRoomName = (e) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    setRoomName(result);
  };

  const openConferenceModal = () => {
    generateRoomName();
    setOpenConference(!openConference);
  };

  useEffect(() => {
    props.getNotifications(
      localStorage.getItem("companyId")
        ? localStorage.getItem("companyId")
        : companyId.id
    );
    props.getUserProfileInfo();
  }, []);

  // useEffect(() => {

  //     if(props.notifications.notification){
  //       const indexOfLastPage = currentNotification * notificationPerPage;
  //       const indexOfFirstPage = indexOfLastPage - notificationPerPage;

  //       const nots = props.notifications.notification.results.notifications.slice(0,4)
  //       setCurrentNotifications(nots);

  //     }

  // }, [props.notifications.notification])

  // useEffect(() => {
  //   setNotificationPerPage(n+4)
  //   setN(n+4)
  // },[currentNotifications])

  // useEffect(() => {
  //   if(currentNotifications){

  //     if(currentNotifications.length < props.notifications.notification.results.notifications.length){
  //       setNotificationPerPage(n+4)
  //       setN(n+4)

  //     }
  //   }
  // }, [ currentNotifications ])

  useEffect(() => {
    if (roomName) {
      console.log(roomName);
    }
  }, [roomName]);

  let url = props.userprofileinfo.data
    ? props.userprofileinfo.data.photo
    : `http://nascent.link${props.company_head.context.user.avatar}`;

  const handleNotificationPath = (
    id,
    purpose,
    project,
    task,
    company,
    read
  ) => {
    axiosInstance
      .patch(API.UpdateNotification + `${id}/`, { is_read: true })
      .then((res) => {
        if (res) {
          handleMenuClose();
        }
      })
      .catch((err) => console.log(err));
    if (purpose === "TDoc") {
      history.push(
        `/task/${project.id}/document/${task.title.replace(/\\|\//g, "")}/${
          task.id
        }`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "PDoc") {
      history.push(
        `/document/${project.title.replace(/\\|\//g, "")}/${project.id}`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "PMember") {
      history.push(
        `/members/${project.title.replace(/\\|\//g, "")}/${project.id}`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "CMem") {
      history.push("/");
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "Task") {
      history.push(
        `/task/${project.id}/members/${task.title.replace(/\\|\//g, "")}/${
          task.id
        }`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "PMember") {
      history.push(
        `/members/${project.title.replace(/\\|\//g, "")}/${project.id}`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "Task Aciton Item") {
      history.push(
        `/task/${project.id}/actionItems/${task.title.replace(/\\|\//g, "")}/${
          task.id
        }`
      );
      props.getNotifications(
        localStorage.getItem("companyId")
          ? localStorage.getItem("companyId")
          : companyId.id
      );
    } else if (purpose === "Project Aciton Item") {
      if (!project && !task) {
        history.push(`/myitems/${company.id}`);
        props.getNotifications(
          localStorage.getItem("companyId")
            ? localStorage.getItem("companyId")
            : companyId.id
        );
      }
      if (project && !task) {
        history.push(
          `/actionItems/${project.title.replace(/\\|\//g, "")}/${project.id}`
        );
        props.getNotifications(
          localStorage.getItem("companyId")
            ? localStorage.getItem("companyId")
            : companyId.id
        );
      }
      if (project && task) {
        history.push(
          `/task/${project.id}/actionItems/${task.title.replace(
            /\\|\//g,
            ""
          )}/${task.id}`
        );
        props.getNotifications(
          localStorage.getItem("companyId")
            ? localStorage.getItem("companyId")
            : companyId.id
        );
      }
    }
  };

  // const getMoreNotifications = () => {
  //   const nots = props.notifications.notification.results.notifications.slice(0, 8);
  //   setCurrentNotifications(nots);
  //   setN(n+4)
  // }

  // useEffect(() => {
  //   const nots = props.notifications.notification.results.notifications.slice(0, n);
  //   setCurrentNotifications(nots)
  // }, [])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setNotificationAnchorEl(null);
  };

  const handleLogoutSubmit = () => {
    props.logout();
    history.push("/login");
  };
  const menuId = "primary-search-account-menu";
  const renderUserMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "35px" }}
    >
      {props.company_head.company_head ? (
        <MenuItem
          onClick={handleMenuClose}
          to="/company-profile-update"
          component={Link}
        >
          Company Update
        </MenuItem>
      ) : (
        <MenuItem
          onClick={handleMenuClose}
          to="/user-profile-update"
          component={Link}
        >
          Profile Update
        </MenuItem>
      )}

      {/* <MenuItem onClick={handleMenuClose}>Payment</MenuItem> */}
      {props.company_head.company_head ? (
        <MenuItem onClick={handleMenuClose} to="/admins" component={Link}>
          Company Admins
        </MenuItem>
      ) : null}
      {props.auth.companies.length > 1 ? (
        <MenuItem
          onClick={handleMenuClose}
          to="/multiple-company"
          component={Link}
        >
          Switch Company
        </MenuItem>
      ) : null}

      <MenuItem
        onClick={handleMenuClose}
        to="/update-password"
        component={Link}
      >
        Change Password
      </MenuItem>
      <MenuItem onClick={handleLogoutSubmit} to="/login" component={Link}>
        Logout
      </MenuItem>
    </Menu>
  );

  const renderNotifications = props.notifications.notification ? (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="notification-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "35px" }}
    >
      <MenuItem
        style={{
          borderBottom: "1px solid rgba(224,224,224,1)",
          paddingLeft: "30px",
        }}
      >
        Notifications
      </MenuItem>
      {!props.notifications.notification ? (
        <MenuItem>No Notifications</MenuItem>
      ) : (
        props.notifications.notification.results.notifications.map(
          (notification) => (
            <MenuItem
              style={
                notification.is_read
                  ? {
                      backgroundColor: "white",
                      marginTop: "10px",
                      borderBottom: "1px solid rgba(224,224,224,1)",
                    }
                  : {
                      backgroundColor: "rgba(224,224,224,1)",
                      marginTop: "10px",
                      borderBottom: "1px solid rgba(224,224,224,1)",
                    }
              }
              onClick={() =>
                handleNotificationPath(
                  notification.id,
                  notification.purpose,
                  notification.project,
                  notification.task,
                  notification.company,
                  notification.is_read
                )
              }
              key={notification.id}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "500px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {/* <div>
                  <Avatar style={{ height: "50px", width: "50px" }} />
                </div> */}
                  <div
                    style={{
                      flexDirection: "column",
                      paddingLeft: "20px",
                    }}
                  >
                    <div>{notification.title}</div>

                    <div style={{ fontSize: "12px" }}>
                      {formatDate(notification.created_date)}
                    </div>
                  </div>
                </div>
              </div>
            </MenuItem>
          )
        )
      )}

      {/* <MenuItem onClick={() => getMoreNotifications()}>{currentNotifications && currentNotifications.length < props.notifications.notification.results.notifications.length  && "See more notifications" }</MenuItem> */}
    </Menu>
  ) : (
    <Menu>
      <MenuItem>Loading...</MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Hidden smUp>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginRight: "18px",
                  marginBottom: "-70px",
                  marginTop: "-50px",
                }}
              >
                <Button
                  className={classes.topButton}
                  variant="contained"
                  onClick={() => openConferenceModal()}
                  size="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#1890ff",
                    border: "1px solid rgba(224,224,224,1)",
                  }}
                >
                  Schedule Conference
                </Button>

                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  style={{
                    outline: "none",
                    color: "#FFFFFF",
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <img
                    className={classes.iconButton}
                    src={AccountIcon}
                    alt="Account Icon"
                  />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="show 39 new notifications"
                  color="inherit"
                  onClick={handleNotificationMenuOpen}
                  style={{ outline: "none", color: "rgba: (224,224,224,1)" }}
                >
                  <Badge
                    badgeContent={
                      !props.notifications.notification
                        ? ""
                        : `${props.notifications.notification.results.is_not_read}`
                    }
                    color="secondary"
                  >
                    <img
                      className={classes.iconButton}
                      src={NotificationIcon}
                      alt="Notification Icon"
                    />
                  </Badge>
                </IconButton>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                {props.tabs ? (
                  props.tabs
                ) : (
                  <div style={{ marginTop: "70px" }}>
                    <h4>{props.title}</h4>
                  </div>
                )}
              </div>
            </div>
          </Hidden>
          <Hidden mdUp xsDown>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginRight: "18px",
                }}
              >
                <Button
                  className={classes.topButton}
                  variant="contained"
                  onClick={() => openConferenceModal()}
                  size="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#1890ff",
                    outline: "none",
                    border: "1px solid rgba(224,224,224,1)",
                  }}
                >
                  Schedule Conference
                </Button>

                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  style={{
                    outline: "none",
                    color: "#FFFFFF",
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <img
                    className={classes.iconButton}
                    src={AccountIcon}
                    alt="Account Icon"
                  />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={handleNotificationMenuOpen}
                  style={{
                    outline: "none",
                    color: "rgba: (224,224,224,1)",
                    position: "relative",
                  }}
                >
                  <Badge
                    badgeContent={
                      !props.notifications.notification
                        ? ""
                        : `${props.notifications.notification.results.is_not_read}`
                    }
                    color="secondary"
                  >
                    <img
                      className={classes.iconButton}
                      src={NotificationIcon}
                      alt="Notification Icon"
                    />
                  </Badge>
                </IconButton>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                {props.tabs ? (
                  props.tabs
                ) : (
                  <div>
                    <h4>{props.title}</h4>
                  </div>
                )}
              </div>
            </div>
          </Hidden>

          <Hidden smDown>
            <Typography className={classes.title} variant="h6" noWrap>
              {props.title ? props.title : props.tabs}
            </Typography>
          </Hidden>
          <div className={classes.grow} />

          {props.topButton && (
            <>
              <Hidden smDown>
                <Button
                  variant="contained"
                  onClick={() => openConferenceModal()}
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#273240",
                    outline: "none",
                    border: "1px solid rgba(224,224,224,1)",
                  }}
                  startIcon={<VideocamIcon />}
                >
                  Schedule Conference
                </Button>
              </Hidden>
            </>
          )}

          <Modal
            openPop={openConference}
            onClick={() => setOpenConference(!openConference)}
            backdropClicked={() => setOpenConference(!openConference)}
            title="Add New Meeting"
            roomName={roomName}
            button="Host Meeting"
            task="task"
            getUpcomingConferenceList={props.getUpcomingConferenceList}
            date="Date"
            time="time"
            details="details"
          ></Modal>

          <div
            className={`${classes.sectionDesktop} ${classes.sectionMobile}`}
            style={{ marginRight: "1%" }}
          >
            <>
              <Hidden smDown>
                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  style={{ outline: "none", color: "#FFFFFF" }}
                >
                  {/* {props.company_head.company_head ? (
                    <Avatar
                      src={`http://nascent.link${props.company_head.photo}`}
                      alt="Account Icon"
                    />
                  ) : (
                    <Avatar
                      src={`http://nascent.link${props.company_head.context.user.avatar}`}
                      alt="Account Icon"
                    />
                  )} */}

                  <React.Fragment>
                    <Avatar
                      className={classes.accountIcon}
                      src={url}
                      alt="Account Icon"
                    />
                  </React.Fragment>
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={handleNotificationMenuOpen}
                  style={{ outline: "none", color: "rgba: (224,224,224,1)" }}
                >
                  <Badge
                    badgeContent={
                      !props.notifications.notification
                        ? ""
                        : `${props.notifications.notification.results.is_not_read}`
                    }
                    color="secondary"
                  >
                    <img src={NotificationIcon} alt="Notification Icon" />
                  </Badge>
                </IconButton>
              </Hidden>
            </>
          </div>
        </Toolbar>
      </AppBar>
      {/* {openNotification ? <NotificationPopOver /> : null} */}
      {anchorEl && renderUserMenu}

      {notificationAnchorEl && renderNotifications}
    </React.Fragment>
  );
};

NotificationBar.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    company_head: state.auth.data,
    auth: state.auth.data,
    notifications: state.notificationList,
    companyinfolist: state.companyList,
    userprofileinfo: state.userProfile,
  };
};

export default connect(mapStateToProps, {
  logout,
  setAlert,
  getNotifications,
  getUserProfileInfo,
})(NotificationBar);

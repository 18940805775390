import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import { TaskDocumentSnapshot, DocumentSnapshot } from '../../components';


const useStyles = makeStyles({
  table: {
    backgroundColor: "white",
    borderRadius:'3px',
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  },
});

const DocumentList = (props) => {
  const classes = useStyles();
  
  

  return (
    <React.Fragment>
      <p className={classes.p}>Documents</p>

      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className="table-row-data">
              <TableCell>S.N</TableCell>
              <TableCell>Name of Documents</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.taskRoom ? 
            <TaskDocumentSnapshot /> : <DocumentSnapshot />}

            
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

DocumentList.propTypes = {};

export default DocumentList;

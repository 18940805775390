import axios from 'axios';
import { apiEndPoints } from './apiConfig';
import store from '../store';
import { refreshAuthToken } from '../actions/auth';

const axiosInstance = axios.create({
    headers: {
        'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});



export default axiosInstance;
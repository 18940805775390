import React from "react";
import Button from "../Button";
import { connect } from "react-redux";
import { CANCEL_SUBSCRIPTION } from "../../action_types";
import axiosInstance from "../../utils/axiosConfig";
import store from "../../store/index";

function CancleSubscription(props) {
  return (
    <div>
      <h5>Are you sure?</h5>
      <div>
        If you select 'Cancel Subscription', your subscription billing will end
        before the next billing cycle and you will be able to continue using
        your subscription until the end of your current billing cycle.
      </div>
      <br />
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Button
          onClick={() => {
            props.onClick();
          }}
          button={"Keep Subscription"}
        />
        <Button
          onClick={() => {
            axiosInstance
              .post(`https://nascent.link/api/subscription/cancel/`, {
                sub_id: store.getState().userProfile.data.subs_id,
                user_email: store.getState().userProfile.data.email,
              })
              .then((res) => {
                props.setAlert(
                  "Subscription cancelled successfully",
                  "success"
                );
              })
              .catch((err) => {
                props.setAlert("Error while cancelling subscription", "error");
              });
            props.cancelSubsription();
            props.onClick();
          }}
          button={"Cancel Subscription"}
          cancel={true}
        />
      </div>
    </div>
  );
}

const mapDispatchTOPros = (dispatch) => {
  return {
    cancelSubsription: () =>
      dispatch({
        type: CANCEL_SUBSCRIPTION,
      }),
  };
};

export default connect(null, mapDispatchTOPros)(CancleSubscription);

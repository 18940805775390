export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const PROJECTLIST_ERROR = "PROJECTLIST_ERROR";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const ERROR_TO_GET_NOTIFICATIONS = "ERROR_TO_GET_NOTIFICATIONS";

export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const USER_COMPANY_FETCH_FAIL = "USER_COMPANY_FETCH_FAIL";
export const USER_COMPANY_FETCH_SUCCESS = "USER_COMPANY_FETCH_SUCCESS";

export const USER_INFO_FETCH_FAIL = "USER_INFO_FETCH_FAIL";
export const USER_INFO_FETCH_SUCCESS = "USER_INFO_FETCH_SUCCESS";

export const NEW_CONFERENCE = "NEW_CONFERENCE";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

import React from "react";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { formatDate, getTimeFromDateTime } from '../../utils/dateTimeUtility';
import "./InfoModal.css";

const InfoModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <div key={props.getInfo && props.getInfo.id}>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onBackdropClick={props.onClick}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="modalTitle">
          <DialogTitle id="responsive-dialog-title">
            Conference Details{" "}
          </DialogTitle>
          <span className="closeDialog">
            <Close onClick={props.onClick} />
          </span>
        </div>
        <DialogContent>
          <DialogContentText>
            {props.getInfo ? (
              <div style={{ width: "400px" }} key={props.getInfo.id}>
                <div className="labels">
                  <div className="titles">Meeting Name :</div>{" "}
                  <div>{props.getInfo.meeting_name}</div>
                </div>
                <div className="labels">
                  <div className="titles">Description :</div>{" "}
                  <div>{props.getInfo.description}</div>
                </div>
                <div className="labels">
                  <div className="titles">Start :</div>{" "}
                  <div>{getTimeFromDateTime(new Date(props.getInfo.start))}</div>
                </div>
                <div className="labels">
                  <div className="titles">End :</div>{" "}
                  <div>{getTimeFromDateTime(new Date(props.getInfo.end))}</div>
                </div>
                <div className="labels">
                  <div className="titles">Project :</div>
                  <div>
                    {" "}
                    {props.getInfo.project_name
                      ? props.getInfo.project_name
                      : "No Project"}
                  </div>
                </div>
                <div className="labels">
                  <div className="titles">Members: </div>
                  <div>
                    {props.getInfo.members.map((mem) => (
                      <div className="members">{mem.username}</div>
                    ))}
                  </div>
                </div>

              </div>
            ) : (
              <div>Loading...</div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoModal;

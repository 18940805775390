import React, { useState, useEffect } from 'react';

import { Document } from '../../containers';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import TaskDocumentListContext from './TaskDocumentListContext';
import useFullPageLoader from '../../hooks/UseFullPageLoader';

const TaskDocuments = (props) => {
    
    const taskTitle = props.computedMatch.params.tasktitle;
    const taskID = props.computedMatch.params.taskId;
    const proID = props.computedMatch.params.id;
    const [ loader, showLoader, hideLoader ] = useFullPageLoader();
    const [ taskDocumentsList, setTaskDocumentsList ] = useState(null)

    useEffect(() => {
        getTaskDocumentsList()
    }, [])


    const getTaskDocumentsList = () => {
        showLoader()
        axiosInstance.get(API.ListTaskDocument + `${taskID}/document/`)
                     .then(res => {
                         setTaskDocumentsList(res.data.results)
                         hideLoader()
                        })
                     .catch(err => console.log(err))
    }

    
    return (
        <TaskDocumentListContext.Provider value={[taskDocumentsList, proID, getTaskDocumentsList, loader]}>
            <Document taskRoom="Task Room" getTaskDocumentsList={getTaskDocumentsList} taskDocument="task Document" proID={proID} taskID={taskID} taskTitle = {taskTitle}/>
        </TaskDocumentListContext.Provider>
    )

}

export default TaskDocuments;
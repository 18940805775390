import React, { useState, useEffect } from 'react';

import { Document } from '../../containers';
import axiosInstance from '../../utils/axiosConfig';

import API from '../../urls/index';
import ProjectDocumentContext from './ProjectDocumentContext';
import useFullPageLoader from '../../hooks/UseFullPageLoader';

const ProjectDocument = (props) => {
    const [ loader, showLoader, hideLoader ] = useFullPageLoader()
    const projectID = props.computedMatch.params.id;
    const projectTitle = props.computedMatch.params.title;
    const [ projectDocumentList, setProjectDocumentList ] = useState(null);
   
    useEffect(() => {
       getProjectDocumentList()
    }, [])


    const getProjectDocumentList = () => {
        showLoader()
        axiosInstance.get(API.ProjectDocumentList + `${projectID}/document/`)
                     .then(res => { if(res.data.results){
                         
                          setProjectDocumentList(res.data.results); 
                          hideLoader()
                        }
                        })
                     .catch(err => console.log(err))
    }

    return (
        <ProjectDocumentContext.Provider value = {[projectDocumentList, getProjectDocumentList, loader]}>
             <Document projectTitle={projectTitle} projectID={projectID} getProjectDocumentList={getProjectDocumentList}  />
        </ProjectDocumentContext.Provider>
        
    )

}

export default ProjectDocument;
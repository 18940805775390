import React from "react";
import styles from "./conferencemodal.module.css";

const ConferenceModal = (props) => {
  if (props.type === "withButton") {
    return (
      <>
        <div className={styles.conference}>
          <label hidden={props.hidden} htmlFor={props.htmlFor}>{props.label}</label>
          <input
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            required

            hidden={props.hidden}
          />

        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.conference}>
        <label hidden={props.hidden} htmlFor={props.htmlFor}>{props.label}</label>
        <input
          type={props.type}
          name={props.name}

          onChange={props.onChange}
          value={props.value}
          hidden={props.hidden}
          required
        />
      </div>
    </>
  );
};

export default ConferenceModal;

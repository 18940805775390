export function formatDate(date) {
  return new Intl.DateTimeFormat("en-GB", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  }).format(new Date(date));
}


export function formatDateDayMonth(date) {
  return new Intl.DateTimeFormat("en-GB", {
    month: "short",
    day: "2-digit"
  }).format(new Date(date));
}

export function formatDuration(totalSeconds) {
  if (totalSeconds == undefined) {
    totalSeconds = 0;
  }
  let hour = 0;
  let min = 0;

  if (totalSeconds <= 60) {
    min = 1;
    return `${min} min`;
  } else {
    min = Math.round(totalSeconds / 60);
    if (min >= 60) {
      hour = Math.round(min / 60);
      min = Math.round(min % 60);
      return `${hour} hr ${min} min`;
    } else {
      return `${min} min`;
    }
  }
}

export function formatDurationToInteger(totalSeconds) {
  let hour = 0;
  let min = 0;
  let day = 0;
  let year = 0;

  if (totalSeconds <= 60) {
    min = 1;
    return `${Math.floor(min)} min ago`;
  } else {
    min = totalSeconds / 60;
    if (min >= 60) {
      hour = min / 60;
      min = min % 60;

      if (hour >= 24) {
        day = hour / 24;
        if (day >= 365) {
          year = day / 365;
          return `${Math.floor(year)} year ago`;
        }
        return `${Math.floor(day)} day ago`;
      } else {
        return `${Math.floor(hour)} hr ${Math.floor(min)} min ago`;
      }
    } else {
      return `${Math.floor(min)} min ago`;
    }
  }
}

export function getTimeAgo(date) {
  let d = new Date(date);
  let diff = Math.abs((new Date().getTime() - d.getTime()) / 1000);
  return this.formatDurationToInteger(diff);
}

export function formatDurationForCall(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  // If you want strings with leading zeroes:
  let mm = String(minutes).padStart(2, "0");
  let hh = String(hours).padStart(2, "0");
  let ss = String(seconds).padStart(2, "0");
  return hh + ":" + mm + ":" + ss;
}


export function getTimeInLocalTimeStamp(date){
    var localeSpecificTime = date.toLocaleTimeString();
    return localeSpecificTime.replace(/:\d+ /, ' ');
}

export function getTimeFromDateTime(date){
  return date.toLocaleTimeString();
}
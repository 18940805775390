import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { Members } from '../../containers';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import ProjectMembersContext from './ProjectMembersContext';

const ProjectMember = (props) => {
    const [ loader, showLoader, hideLoader ] = useFullPageLoader();
    const projectID = props.computedMatch.params.id;
    const projectTitle = props.computedMatch.params.title;
    const [ projectMembersList, setProjectMembersList ] = useState(null);
    
    useEffect(() => {
       getProjectMembersList()
    },[])

    const getProjectMembersList = () => {
        showLoader()
        axiosInstance.get(API.ListProjectMembers + `${projectID}/members/`)
                           .then(res => {if(res.status === 200){
                               hideLoader()
                              setProjectMembersList(res.data.results)
                           }
                           else{
                               props.setAlert("Error Loading Data", "Error")
                           }
                        })
                           .catch(err => console.log(err))
    }

    return (
        <ProjectMembersContext.Provider value={[ projectMembersList, loader ]}>
            <Members projectTitle={projectTitle} projectID={projectID} projectMembers={projectMembersList} getProjectMembersList={getProjectMembersList} />
        </ProjectMembersContext.Provider>
        
    )

}

export default connect(null, { setAlert })(ProjectMember);
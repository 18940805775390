import React from 'react';
import { Button } from '@material-ui/core';
import styles from './delete.module.css';

const DeleteReminder = (props) => {


    return (
        <>
            <p className={styles.sure}>Are You Sure You Want to delete?</p>
            <div className={styles.ask}>
                <Button style={{ background: "#f50057", color: "white", margin: "10px", outline: "none" }} disabled={props.loading} onClick={props.confirmDeleteData}>Yes</Button>
                <Button style={{ backgroundColor: "#2196f3", color: "white", margin: "10px", outline: "none" }} onClick={props.confirmDelete}>No</Button>
            </div>
        </>
    )
}

export default DeleteReminder;

import {
  GET_NOTIFICATION,
  ERROR_TO_GET_NOTIFICATIONS,
} from "../action_types/index";
import axiosInstance from "../utils/axiosConfig";
import API from "../urls/index";

export const getNotifications = (id) => async (dispatch) => {
  await axiosInstance
    .get(API.Notifications + `${id}/notification/`)
    .then((res) =>
      dispatch({
        type: GET_NOTIFICATION,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: ERROR_TO_GET_NOTIFICATIONS,
      })
    );
};

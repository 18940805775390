import React, { useState, useContext } from "react";
import { connect } from "react-redux";

import {
  ConferenceModal,

  Button,
  DocumentModal,

} from "../../components";
import { setAlert } from '../../actions/alert';
import API from '../../urls/index';
import ProjectDocumentContext from '../../containers/ProjectDocument/ProjectDocumentContext';
import axiosInstance from '../../utils/axiosConfig';


const ProjectDocument = (props) => {

  const { projectID } = props;  
  const projectDocumentList = useContext(ProjectDocumentContext);

  const [ loading, setLoading ] = useState(false);
  const [document, setDocument] = useState({ name: ""});
  const [postImage, setPostImage] = useState(null);

  const handleProjectDocumentChange = (event) => {
    setDocument({[event.target.name]: event.target.value });
  };

  const handleProjectDocumentImageChange = (event) => {
    setPostImage({ document: event.target.files });
  };


  

  const handleProjectDocumentSubmit = (id) => {
    
    if(!postImage){
        props.setAlert("Please Enter Valid Fields", "error")
    }
    else{
      let form_data = new FormData();
    form_data.append("document", postImage.document[0]);
    // console.log(postImage.document[0], document.name, document.status);
    form_data.append("name", document.name);
    // form_data.append("status", document.status);
    setLoading(true)
    
    axiosInstance
      .post(API.AddDocument + `${projectID}/document/add/`,
        form_data
      )
      .then((res) => {
        if (res.status === 200) {
          props.getProjectDocumentList()
          setLoading(false);
          props.setAlert("SuccessFully Posted Document", "success");
          setDocument({name : ''})
          props.onClick()
        }
      })

      .catch((err) => props.setAlert("Please Enter All required Fields before Submission file", "error"));
    }


  };
  return (
    <>
      <DocumentModal
        htmlFor="name"
        type="text"
        label="Document Name"
        name="name"
        value={document.name}
        onChange={handleProjectDocumentChange}
      />
      {/* {getSelectBoxAccordingToRole()} */}
      <DocumentModal
        htmlFor="upload"
        type="file"
        name="document"
        label="Upload"
        
        onChange={handleProjectDocumentImageChange}
      />

      <Button
        button={props.button}
        loading={loading}
        onClick={() => handleProjectDocumentSubmit(projectID)}
      />
    </>
  );
};

export default connect(null, { setAlert })(ProjectDocument);

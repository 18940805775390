import React, { useContext, useState } from "react";
import { connect } from 'react-redux';
import Hidden from "@material-ui/core/Hidden";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import { setAlert } from '../../actions/alert';


import { SimpleMenu, Modal } from "../../components";

import TaskMembersContext from "../../components/TaskMembers/TaskMembersContext";

const TaskMembersTable = (props) => {

  
  const { taskID } = props;  
  const [taskMembers, loader] = useContext(TaskMembersContext);
  const [ openDelete, setOpenDelete ] = useState(false)
  const [ response, setResponse ] = useState(false)
  const [ ID, setID ] = useState();
  const [ loading, setLoading]= useState(false);
  

  const deleteTaskMember = (id) => {
      setOpenDelete(!openDelete)
      setID(id)
  }

  const deleteTaskMemberConfirm = (ID) => {
    setLoading(true)
 
      axiosInstance.delete(API.RemoveTaskMember + `${taskID}/member/${ID}/remove/`)
                   .then(res => {if(res.status === 200) {
                       setResponse(true)
                       props.setAlert("Successfully Deleted Member", "success")
                       setOpenDelete(false)
                       setLoading(false)
                   }
                })
                   .catch(err => { 
                       props.setAlert("You Cannot Perform this action!!", "error")
                       setOpenDelete(false)
                       setLoading(false)
                   })
  }

  const getFilteredTaskMember = (taskMembers) => {
    if(response){
        return taskMembers.filter(member => member.id !== ID)
    }
    else{
    return taskMembers
}}

  return !taskMembers ? <div>{loader}</div> : getFilteredTaskMember(taskMembers).map((taskMember,index) => (
    <React.Fragment key={taskMember.id}>
      
        <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }} className="largeScreen">
          <TableCell align="left">{index+1}</TableCell>
          <TableCell component="th" scope="row" align="left">
          {taskMember.first_name} {taskMember.last_name} 
          </TableCell>
     
          <TableCell align="left">{taskMember.username}</TableCell>
          <TableCell align="left">{taskMember.contact}</TableCell>
          <TableCell align="left">
            <SimpleMenu delete="Delete" deleteProject = {() => deleteTaskMember(taskMember.id)} />
          </TableCell>
        </TableRow>
     
        <TableRow component={Paper} className="smallScreen">
          <TableCell
            align="left"
            style={{
              width: "40%",
              lineHeight: "0.5rem",
              padding: "20px",
            }}
          >
            <p>S.No.</p>
            <p>Name</p>
            
            <p>Email</p>
            <p>Contact</p>
          </TableCell>
          <TableCell
            align="left"
            style={{
              width: "40%",
              lineHeight: "0.5rem",
              padding: "20px",
            }}
          >
            <p>{index+1}</p>
            <p>{taskMember.first_name} {taskMember.last_name}</p>
     
            <p>{taskMember.username}</p>
            <p>{taskMember.contact}</p>
          </TableCell>
          <TableCell align="left" style={{ width: "20%" }}>
            <SimpleMenu  delete="Delete" deleteProject = {() => deleteTaskMember(taskMember.id)} />
          </TableCell>
        </TableRow>

      <Modal
            openPop={openDelete}
            onClick={() => setOpenDelete(!openDelete)}
            backdropClicked={() => setOpenDelete(!openDelete)}
            deleteBar="Delete Bar"
            title="Confirm Delete"
            loading={loading}
            ConfirmDeleteData={() => deleteTaskMemberConfirm(ID)}
            closeDialog={() => setOpenDelete(!openDelete)}
    />
    </React.Fragment>
  ));
};

export default connect(null, { setAlert })(TaskMembersTable);

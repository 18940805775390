import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ProjectButton, Modal, NotificationBar } from "../../components";
import { setAlert } from "../../actions/alert";
import {
  Table,
  Grid,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
  Avatar,
} from "@material-ui/core";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import styles from "./admins.module.css";


const Admins = (props) => {

  const [openAdmin, setOpenAdmin] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [companyAdmins, setCompanyAdmins] = useState([]);
  const [ID, setID] = useState();

  useEffect(() => {
    getCompanyAdmins();
  }, []);

  const getCompanyAdmins = () => {
    axiosInstance
      .get(API.ListCompanyAdmins + `${props.companyId}/admins/`)
      .then((res) => {
        if (res.status === 200) {
          setCompanyAdmins(res.data.results);
        }
      })
      .catch((err) => {
        if (err) {
          props.setAlert("Error Loading Data, Something went Wrong", "error");
        }
      });
  };

  const deleteAdmin = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const deleteAdminConfirm = (id) => {

    const data = { member_id: [ID], type: "remove" };

    axiosInstance
      .post(API.AppointAdmin + `${id}/admin/`, data)
      .then((res) => {
        if (res.status === 200) {
          getCompanyAdmins()
          setOpenDelete(!openDelete)
          props.setAlert("Admin Removed Successfully", "success")
        }
      })
      .catch((err) => {
        if (err) {
          props.setAlert("Delete Action Denied", "error")
        }
      });
  };

  return (
    <React.Fragment>
      <NotificationBar title="Admins" topButton="button" />

      <main>
        <div className={styles.myitemOuterWrapper}>
          <Grid container>
            <Grid container lg={12}>
              <Grid item style={{ margin: "15px 0px" }}>
                <ProjectButton
                  onClick={() => setOpenAdmin(!openAdmin)}
                  text="+ Appoint Admin"
                />
              </Grid>
            </Grid>
            <div
              style={{
                borderBottom: "1px solid rgba(224,224,224,1)",
                width: "100%",
              }}
            ></div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyAdmins.length === 0 ? (
                  <div>No Admins yet</div>
                ) : (
                  companyAdmins.map((admin) => (
                    <TableRow>
                      <TableCell>
                        <Grid container lg={5}>
                          <Grid item lg={4}><Avatar /></Grid>
                          <Grid item lg={6} style={{ margin: 'auto' }}>{admin.username}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>
                        <Button color="secondary" onClick={() => deleteAdmin(admin.id)}>
                          Remove From Admin
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Grid>
        </div>
      </main>
      <Modal
        onClick={() => setOpenAdmin(!openAdmin)}
        backdropClicked={() => setOpenAdmin(!openAdmin)}
        openPop={openAdmin}
        title="Appoint Company Admin"
        admin="Admins"
        button="Add Admin"
        getCompanyAdmins={getCompanyAdmins}
      />
      <Modal
        openPop={openDelete}
        onClick={() => setOpenDelete(!openDelete)}
        backdropClicked={() => setOpenDelete(!openDelete)}
        deleteBar="Delete Bar"
        title="Confirm Delete"
        ConfirmDeleteData={() => deleteAdminConfirm(props.companyId)}
        closeDialog={() => setOpenDelete(!openDelete)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { companyId: state.auth.data.company_id };
};

export default connect(mapStateToProps, { setAlert })(Admins);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ActionItems } from "../../containers";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import { setAlert } from "../../actions/alert";
import TaskActionItemsContext from "./TaskActionItemsContext";
import useFullPageLoader from "../../hooks/UseFullPageLoader";

const TaskAction = (props) => {
  const taskTitle = props.computedMatch.params.tasktitle;
  const taskID = props.computedMatch.params.taskId;
  const proID = props.computedMatch.params.id;
  const [ loader, showLoader, hideLoader] = useFullPageLoader()
  const [taskActionItems, setTaskActionItems] = useState(null);

  useEffect(() => {
    getTaskActionItems();
  }, []);

  const getTaskActionItems = () => {
    showLoader()
    axiosInstance
      .get(API.ListTaskActionItems + `${taskID}/actionitem/`)
      .then((res) => {
        if (res) {
          setTaskActionItems(res.data.results);
          hideLoader()
        }
      })
      .catch((err) => {
        if (err) {
          props.setAlert("Error Loading Data", "error");
        }
      });
  };

  return (
    <TaskActionItemsContext.Provider value={[taskActionItems, getTaskActionItems, proID, loader]}>
      <ActionItems
        taskRoom="Task Room "
        proID={proID}
        taskID={taskID}
        taskTitle={taskTitle}
        getTaskActionItems={getTaskActionItems}
      />
    </TaskActionItemsContext.Provider>
  );
};

export default connect(null, { setAlert })(TaskAction);

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls";
import { ConferenceModal, UserList, Button } from "../../components";
import ConferenceDetail from "../ConferenceDetail";
import styles from "./conferencemodal.module.css";
import { NEW_CONFERENCE } from "../../action_types";
import store from "../../store";

let newConference = {};

const AddConferenceModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [conference, setConference] = useState({
    date: new Date(),
    start: "",
    meeting_name: "",
    end: "",
    project: null,
    description: "",
    title: props.roomName,
    members: [],
  });

  useEffect(() => {
    if (props.meetingId) {
      axiosInstance
        .get(API.GetConferenceList + `${props.meetingId}/`)
        .then((res) => {
          if (res) {
            let memberArr = [];

            res.members.map((item) => {
              memberArr.push(item.id);
            });

            setConference({
              title: res.title,
              meeting_name: res.meeting_name,
              members: memberArr,
              project: res.project,
              start: new Date(res.start),
              end: new Date(res.end),
              description: res.description,
              date: new Date(),
            });
          }
        })
        .catch((err) => {
          props.setAlert("Something went wrong", "error");
        });
    }
  }, [props.meetingId > 0]);

  const handleConferenceChange = (e) => {
    setConference({ ...conference, [e.target.name]: e.target.value });
  };

  const handleMembersFieldChange = (value) => {
    setConference({ ...conference, members: value });
  };

  const submitNewConference = (e) => {
    e.preventDefault();

    let memArr = [];

    conference.members.map((item, idx) => {
      memArr.push(item.id);
    });

    var data = {
      company: localStorage.getItem("companyId")
        ? localStorage.getItem("companyId")
        : props.companyId.companies[0].id,
      title: conference.title,
      meeting_name: conference.meeting_name,
      members: memArr,
      project: conference.project,
      start: new Date(conference.date + " " + conference.start),
      end: new Date(conference.date + " " + conference.end),
      description: conference.description,
    };

    setLoading(true);
    axiosInstance
      .post(API.SaveNewConference, data)
      .then((res) => {
        if (res) {
          props.setAlert("Conference added successfully", "success");
          props.onClick();
          props.getUpcomingConferenceList();
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          props.setAlert("Failed to added conference", "error");
          props.onClick();
          setLoading(false);
        }
      });
  };

  return (
    <form
      className={styles.formSection}
      onSubmit={(e) => submitNewConference(e)}
      key={conference.id && conference.id}
    >
      <ConferenceModal
        htmlFor="title"
        type="text"
        label="Room Name"
        name="title"
        type="withButton"
        hidden={true}
        value={conference.title}
        onChange={handleConferenceChange}
      />

      <ConferenceModal
        htmlFor="Conference Title"
        type="text"
        label="Title"
        name="meeting_name"
        value={conference.meeting_name}
        onChange={handleConferenceChange}
      />

      <div className={styles.projectSelect}>
        <label htmlFor="project" className={styles.label}>
          Project
        </label>
        <select
          className={styles.selectBox}
          onChange={handleConferenceChange}
          name="project"
        >
          <option>Select Project</option>
          {props.list.map((list) => (
            <option typeof="number" value={list.id}>
              {list.title}
            </option>
          ))}
        </select>
      </div>

      <ConferenceModal
        htmlFor="date"
        type="date"
        label="Date"
        name="date"
        onChange={handleConferenceChange}
      />

      <ConferenceModal
        htmlFor="start"
        type="time"
        label="Start Time"
        name="start"
        onChange={handleConferenceChange}
      />

      <ConferenceModal
        htmlFor="end"
        type="time"
        label="End Time"
        name="end"
        onChange={handleConferenceChange}
      />

      <UserList handleMembersFieldChange={handleMembersFieldChange} />

      <ConferenceDetail
        htmlFor="description"
        type="text"
        label="Description"
        name="description"
        maxlength="20"
        value={conference.description}
        onChange={handleConferenceChange}
      />

      <Button
        loading={loading}
        button={props.button}
        onClick={() => {
          newConference = { ...conference };
          props.newConference();
        }}
        type="submit"
      />
    </form>
  );
};

const mapDispatchTOPros = (dispatch) => {
  return {
    newConference: () =>
      dispatch({
        type: NEW_CONFERENCE,
        payload: newConference,
      }),
  };
};

const mapStateToProps = (state) => {
  return { companyId: state.auth.data, list: state.projectList.data };
};

export default connect(mapStateToProps, mapDispatchTOPros)(AddConferenceModal);

import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { logout } from '../../actions/auth';

const AdminHeader = ({ logout }) => {

  const history = useHistory()

  const handleLogoutSubmit = () => {
    logout();
    history.push('/login')
  }
  return (
    <AppBar style={{ backgroundColor: "#091936" }}>
      <Toolbar
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h3>Nascent</h3>
        <div >
          <Button onClick={() => history.push('/details')} style={{ color: "white", textTransform: 'none' }}>
            Company List
          </Button>
          <Button onClick={() => history.push('/contact-messages')} style={{ color: "white", textTransform: 'none' }}>
            Contact Messages
          </Button>
          <Button onClick={handleLogoutSubmit} style={{ color: "white", textTransform: 'none' }}>
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default connect(null, { logout })(AdminHeader);
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { apiEndPoints } from "../../utils/apiConfig";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  formatDate,
  getTimeFromDateTime,
  formatDateDayMonth,
} from "../../utils/dateTimeUtility";
import API from "../../urls";
import axiosInstance from "../../utils/axiosConfig";
import { setAlert } from "../../actions/alert";
import { Modal } from "../../components";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import styles from "./meeting.module.css";
import InfoModal from "../InfoModal";

const Meeting = (props) => {
  const history = useHistory();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const urlRef = useRef();
  const [openDetails, setOpenDetails] = useState(false);
  const tooltipRef = useRef();
  const [editState, setEditState] = useState(null);
  const [copied, setCopied] = useState(false);
  const [info, setInfo] = useState(null);

  const joinMeeting = () => {
    var url = "";
    // if(localStorage.getItem('token'))
    // url = `${ apiEndPoints.meetingUrl+props.meetingInfo.title}?jwt=${localStorage.getItem('token')}`
    url = `${apiEndPoints.meetingUrl + props.meetingInfo.title}?jwt=${
      props.meetingInfo.jwt
    }`;
    // else
    // url = `${apiEndPoints.meetingUrl + props.meetingInfo.title}`

    var win = window.open(url, "_blank");
    win.focus();
  };

  const handleTaskEditSubmit = (id) => {
    // axiosInstance
    //   .put(API.UpdateTask + `${edit}/update/`, taskData)
    //   .then((res) => { if(res.status === 200){
    //     props.setAlert("Task Editted SuccessFully", "success")
    //     props.getUpcomingConference()
    //   }
    //  })
    //   .catch((err) => console.log(err));
  };

  const copyMeetingInfo = () => {
    let tooltip = tooltipRef.current;
    tooltip.innerHTML = "Copied";
    props.setAlert("Meeting info copied successfully", "success");
  };

  // const copyText = (text) => {
  //   navigator.clipboard.writeText(text);
  // };

  const outFunc = () => {
    let tooltip = tooltipRef.current;
    tooltip.innerHTML = "Copy Link";
  };

  const deleteConfirm = async (ID) => {
    await axiosInstance
      .delete(API.DeleteConference + `${ID}/`)
      .then((res) => {
        if (res) {
          setOpenDelete(!openDelete);
          props.setAlert("Conference Deleted Successfully", "success");
          props.getUpcomingConference();
        }
      })
      .catch((err) => props.setAlert("Error Deleting Conference", "error"));
  };

  const updateConference = (
    id,
    title,
    description,
    project,
    project_name,
    meeting_name,
    start,
    end,
    members
  ) => {
    setOpenEdit(!openEdit);
    setEditState({
      id: id,
      title: title,
      description: description,
      project: project,
      project_name: project_name,
      meeting_name: meeting_name,
      start: start,
      end: end,
      members: members,
    });
  };

  const sendMeetingInfoToModal = (
    id,
    title,
    description,
    project_name,
    meeting_name,
    start,
    end,
    members
  ) => {
    setInfo({
      id: id,
      title: title,
      description: description,
      project_name: project_name,
      meeting_name: meeting_name,
      start: start,
      end: end,
      members: members,
    });
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <div className={styles.meetingOuterWrapper}>
        <div className={styles.meetingDate}>
          <p>{formatDateDayMonth(props.meetingInfo.start)}</p>
        </div>
        <div className={styles.meetingSchedule}>
          <h6>
            {props.meetingInfo.meeting_name}
            <div className={styles.tooltip}>
              <span className={styles.tooltiptext} ref={tooltipRef}>
                Copy Link
              </span>
              <CopyToClipboard
                text={apiEndPoints.meetingUrl + props.meetingInfo.title}
                onCopy={() => setCopied(true)}
              >
                <span style={{ marginLeft: "10px" }}>
                  <FileCopyOutlinedIcon
                    onClick={() => copyMeetingInfo()}
                    onMouseOut={outFunc}
                    fontSize="small"
                    style={{ fontSize: "16px" }}
                  />
                </span>
              </CopyToClipboard>
            </div>
          </h6>
          <p ref={urlRef} style={{ display: "none" }}>
            {apiEndPoints.meetingUrl + props.meetingInfo.title}{" "}
          </p>
          <p>
            Project:{" "}
            {props.meetingInfo.project
              ? props.meetingInfo.project_name
              : "No Project"}
          </p>
          {/* <p>Room name : {props.meetingInfo.title} </p> */}
          {/* <p>Description: {props.meetingInfo.description.substring(0,100)+ `${'..'}`}</p> */}
          <p>Meeting Date: {formatDate(props.meetingInfo.start)}</p>
          <p>
            Meeting Start Time:{" "}
            {getTimeFromDateTime(new Date(props.meetingInfo.start))}
          </p>
          <p>
            Meeting End Time :{" "}
            {getTimeFromDateTime(new Date(props.meetingInfo.end))}
          </p>

          <div className={styles.meetingAnchor}>
            <button
              onClick={() =>
                // updateConference(
                //   props.meetingInfo.id,
                //   props.meetingInfo.title,
                //   props.meetingInfo.description,
                //   props.meetingInfo.project,
                //   props.meetingInfo.project_name,
                //   props.meetingInfo.meeting_name,
                //   props.meetingInfo.start,
                //   props.meetingInfo.end,
                //   props.meetingInfo.members,

                // )
                history.push(`/conference-edit/${props.meetingInfo.id}`)
              }
              className={styles.meetingEditDelete}
            >
              Edit
            </button>
            <button
              className={styles.meetingEditDelete}
              onClick={() => setOpenDelete(!openDelete)}
            >
              Delete
            </button>
            <button
              className={styles.meetingEditDelete}
              onClick={() =>
                sendMeetingInfoToModal(
                  props.meetingInfo.id,
                  props.meetingInfo.title,
                  props.meetingInfo.description,
                  props.meetingInfo.project_name,
                  props.meetingInfo.meeting_name,
                  props.meetingInfo.start,
                  props.meetingInfo.end,
                  props.meetingInfo.members
                )
              }
            >
              Details
            </button>
          </div>
          {/* <div className={styles.tooltip}>
            <span className={styles.tooltiptext} ref={tooltipRef}>
              Copy Meeting Link
            </span> */}

          {/* <CopyToClipboard
              text={apiEndPoints.meetingUrl + props.meetingInfo.title}
              onCopy={() => setCopied(true)}
            >
              <button
                className={styles.meetinCopyInfo}
                onClick={() => copyMeetingInfo()}
                onMouseOut={outFunc}
              >
                Copy Link
              </button>
            </CopyToClipboard> */}
          {/* </div> */}
        </div>
        <div className={styles.meetingButton}>
          <button className={styles.joinButton} onClick={joinMeeting}>
            Join
          </button>
        </div>
      </div>
      <Modal
        openPop={openDelete}
        onClick={() => setOpenDelete(!openDelete)}
        backdropClicked={() => setOpenDelete(!openDelete)}
        title="Delete Meeting"
        deleteBar="Delete Meeting"
        closeDialog={() => setOpenDelete(!openDelete)}
        ConfirmDeleteData={() => deleteConfirm(props.meetingInfo.id)}
      />
      <Modal
        openPop={openEdit}
        conferenceEdit={true}
        title="Update Conference"
        button="Update"
        // fullWidth={true}
        getUpcomingConference={props.getUpcomingConference}
        onClick={() => setOpenEdit(!openEdit)}
        backdropClicked={() => setOpenEdit(!openEdit)}
        editState={editState}
      />
      <InfoModal
        onClick={() => setOpenDetails(!openDetails)}
        conferenceDetails={true}
        open={openDetails}
        getInfo={info}
      />
    </>
  );
};

export default connect(null, { setAlert })(Meeting);

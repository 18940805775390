import {
   GET_PROJECT_LIST, PROJECTLIST_ERROR
} from '../action_types';

const initialState = { data : []}


export const projectList = (state=initialState, action) => {
    switch(action.type){
        case GET_PROJECT_LIST:
            return {...state, data: action.payload}
             
        default:
            return state;    
    }
}
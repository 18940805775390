import React, { useState ,useEffect } from 'react';
import { Members } from '../../containers';
import TaskMembersContext from './TaskMembersContext';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import useFullPageLoader from '../../hooks/UseFullPageLoader';

const TaskMembers = (props) => {
    const taskTitle = props.computedMatch.params.tasktitle;
    const [ loader, showLoader, hideLoader ] = useFullPageLoader();
    const taskID = props.computedMatch.params.taskId;
    const id = props.computedMatch.params.id; 
    const [ taskMembers, setTaskMembers ] = useState(null)
    useEffect(() => {
       getTaskMembersList()
    }, [])

    

    const getTaskMembersList = () => {
        showLoader()
       axiosInstance.get(API.TaskMembersList + `${taskID}/members/`)
                    .then(res => {
                        setTaskMembers(res.data.results);
                        hideLoader()
                    })
                    .catch(err => console.log(err))
    }


    return (
        <TaskMembersContext.Provider value={[taskMembers, loader]}>
            <Members taskRoom="Task Room" getTaskMembersList={getTaskMembersList} taskTitle={taskTitle} taskID={taskID} proID={id} />
        </TaskMembersContext.Provider>
        
    )


}

export default TaskMembers;
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { withRouter, useHistory } from 'react-router-dom';

import MuiTableCell from "@material-ui/core/TableCell";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';

import MuiTableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';


import TableRow from '@material-ui/core/TableRow';
import FileIcon from '../../fileIcon.svg';


import { Modal, SimpleMenu } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import { formatDate } from '../../utils/dateTimeUtility';
import './projectList.css';
import { getprojectlist } from '../../actions/projectList';

  
const TableCell = withStyles({
    root: {
      borderBottom: "none",
      padding:"2px",
    },
    
})(MuiTableCell);


const TableBody = withStyles({
  root :{
     backgroundColor:"#fff"
  }
})(MuiTableBody);

const ProjectListTable = (props) => {

    const history = useHistory(); 
    const { projectslist } = props;

    const [ response, setResponse ] = useState(false)
    const [openDelete, setOpenDelete] = React.useState(false)

    const [ ID, setID ] = React.useState();
    const [ showText, setShowText ] = useState(false)
    const [ loading, setLoading ] = useState(false);
    const [ editState, setEditState ] = React.useState();

  const [ openNewProjectEdit, setOpenNewProjectEdit ] = React.useState(false)


    const DeleteProjectData = (id) => {
      setOpenDelete(!openDelete)
      setID(id)
    }
  
    const DeleteProject = (ID) => {
      setLoading(true)
      axiosInstance.delete(API.ProjectDelete + `${ID}/`)
                   .then(res =>  { if(res){
                     setOpenDelete(false)
                     setResponse(true)               
                     props.setAlert("Project Deleted successfully", "success")
                     props.getprojectlist(props.company_id.id);
                     setLoading(false)
                
                     
                   }})
                   .catch(err => {
                     props.setAlert("Cannot Delete Project", "error");
                     setOpenDelete(!openDelete);
                     setLoading(false);
                   })
    }


  const updateProject = (id, title, description) => {
    setOpenNewProjectEdit(!openNewProjectEdit)
    setEditState({id, title, description})
  }

  const getFilteredProject = (projectslist) => {
    if(response) {
      return projectslist.data.filter(project => project.id !== ID)
    }
    else{
      return projectslist.data
    }
  }
    return projectslist.data.length === 0 ? (
        <TableRow>
          <TableCell>You Have No Project</TableCell>
        </TableRow>
      ) : (
        getFilteredProject(projectslist).map((project) => (
          <React.Fragment key={project.id}>
            <TableRow className="projectList">
              <Hidden smDown xsDown>
                <TableCell
                  onClick={() => {
                    const str = `${project.title}`;
                    history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                   
                  }}

                  align="center"
                  style={{ width: "7%" }}
                >
                  <img
                    style={{ height: "60px" }}
                    src={FileIcon}
                    alt="File Icon"
                  />
                </TableCell>
                <TableCell
                   onClick={() => {
                    const str = `${project.title}`;
                    history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                   
                  }}

                  align="left"
                  style={{ width: "19%" }}
                >
                  <div style={{ marginTop: "10px" }}>
                    {project.title}
                    <p style={{ color: "gray" }}>
                      created on : {formatDate(project.created_date)}
                    </p>
                  </div>
                </TableCell>
                <TableCell
                 onClick={() => {
                  const str = `${project.title}`;
                  history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                 
                }}

                  align="left"
                  style={{ width: "55%" }}
                >
                  
                  {project.description}
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  <SimpleMenu
                    edit="Edit"
                    delete="Delete"
                    
                    updateProject = {() => updateProject(project.id, project.title, project.description)}
                    deleteProject = {() => DeleteProjectData(project.id)}
                  />
                </TableCell>
              </Hidden>

              <Hidden mdUp>
                <TableCell
                   onClick={() => {
                    const str = `${project.title}`;
                    history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                   
                  }}

                  align="center"
                  style={{ width: "15%" }}
                >
                  <img
                    style={{ height: "70px" }}
                    src={FileIcon}
                    alt="File Icon"
                  />
                </TableCell>
                <TableCell align="left" style={{ width: "55%" }}  
                >
                  <div style={{ marginLeft: "20px" }}>
                    <h6 onClick={() => {
                    const str = `${project.title}`;
                    history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                   
                  }}>{project.title}</h6>
                    <div style={{ color: "gray" }} onClick={() => {
                    const str = `${project.title}`;
                    history.push(`/conversation/${str.replace(/\\|\//g,'')}/${project.id}`);
                   
                  }}>
                      created at: {project.join_date}
                    </div>
                    <p
                      style={{ fontSize: "10px", color: "graytext" }}
                    >
                      {project.description}{" "}
                      {!showText && (
                        <ExpandMoreIcon
                          onClick={() => setShowText(!showText)}
                        />
                      )}{" "}
                      {showText && (
                        <>
                          and this project uses the frontend in react
                          and also uses redux for state management and
                          uses django for the backend{" "}
                          <ExpandLessIcon
                            onClick={() => setShowText(!showText)}
                          />{" "}
                        </>
                      )}
                    </p>
                  </div>
                </TableCell>
                <TableCell align="center" style={{ width: "30%" }}>
                  <SimpleMenu
                    edit="Edit"
                    delete="Delete"
                    project = {project}
                    deleteProject = {() => DeleteProjectData(project.id)}
                  />
                </TableCell>
              </Hidden>
            </TableRow>
            <Modal
          openPop={openDelete}
          onClick = {() => setOpenDelete(!openDelete)}
          backdropClicked = {() => setOpenDelete(!openDelete)}
          deleteBar = "Delete Bar"
          title="Confirm Delete"
          loading={loading}
          ConfirmDeleteData = {() => DeleteProject(ID)}
          closeDialog = { () => setOpenDelete(!openDelete)}
        /> 
        <Modal
        noTitle = {true}
        editState = {editState}
        openPop={openNewProjectEdit} 
        onClick={props.onClick}
        fullWidth={true}
        backToHome = {() => setOpenNewProjectEdit(!openNewProjectEdit)} /> 
          </React.Fragment>
        ))
      )}


      const mapStateToProps = (state) => {
        return {
          projectslist : state.projectList
        }
      }



      const mapDispatchToProps = (dispatch) => {
        return {
          getprojectlist : (id) => dispatch(getprojectlist(id))
        }
      }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectListTable));     
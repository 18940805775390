import React from 'react';
import styles from './projectdocument.module.css';


const ProjectDocumentSelectBox = (props) => {
    return (<>
    <div className={styles.projectSelectBoxOuterWrapper}>
    <label htmlFor={props.htmlFor}>{props.htmlFor}</label>
    <div className={styles.projectSelectBoxModal}>
       <select name={props.name} id="subscription" placeholder="filter" onChange={props.onChange} className={styles.projectSelectForm}>
           <option value={props.htmlFor}>{props.htmlFor}</option>
           <option value={props.value1}>{props.opt1}</option>
           <option value={props.value2}>{props.opt2}</option>
           <option value={props.value3}>{props.opt3}</option>
           <option value={props.value4}>{props.opt4}</option>
       </select>
       <br />
     </div>

   </div>    
   </>)
}

export default ProjectDocumentSelectBox;
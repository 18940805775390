import React from "react";
import { connect } from 'react-redux';
import Profile from "../Profile";

const CompanyProfile = ({ info }) => {
  
  return (
    <Profile
      name={info.username}
      email="nascent@gmail.com"
      upgrade="Premium"
      link="Company profile"
    />
  );
};

const mapStateToProps = ( state ) => {
  return { info : state.auth.data }
}

export default connect(mapStateToProps)(CompanyProfile);

import React, { useState, useEffect } from 'react';
import ProjectSnapshotContext from './ProjectSnapshotContext';
import { SnapShot } from '../../containers';
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';

const ProjectSnapshot = (props) => {
    const projectTitle = props.computedMatch.params.title;
    const projectID = props.computedMatch.params.id;
    const [snapshotView, setSnapshotView ] = useState(null);

    const [ loader, showLoader, hideLoader ] = useFullPageLoader();

    useEffect(() => {
        showLoader()
        axiosInstance.get(API.ProjectSnapshot + `${projectID}/snapshot/`)
                     .then(res => {
                         setSnapshotView(res.data.results);
                         hideLoader()
                     })
                     .catch(err => console.log(err))

    }, [])

    

    return(
        <ProjectSnapshotContext.Provider value={[snapshotView, loader ]}>
            <SnapShot projectTitle={projectTitle} projectID={projectID} />
        </ProjectSnapshotContext.Provider>
    )
}

export default ProjectSnapshot;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import axiosInstance from "../../utils/axiosConfig";
import { logout } from "../../actions/auth";
import API from "../../urls/index";

import { Filter, Search, DetailComponent, AdminHeader } from "../../components";
import styles from "./details.module.css";
import useFullPageLoader from "../../hooks/UseFullPageLoader";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Details = (props) => {
  const classes = useStyles();

  const [query, setQuery] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [companyDetail, setCompanyDetails] = useState(null);
  const [searchCompany, setSearchCompany] = useState(null);
  const [filterCompany, setFilterCompany] = useState({ type: "", status: "" });
  const [filteredProject, setFilteredProject] = useState(null);
  // const [ companyFiltered , setCompanyFiltered ] = useState([])
  // const [ activeState, setActiveState ] = useState([])
  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  useEffect(() => {
    if (companyDetail) {
      const filtered = companyDetail.results.filter((company) => {
        return company.title.toLowerCase().includes(query.toLowerCase());
      });

      setSearchCompany(filtered);
      setFilteredProject(null);
    }
  }, [companyDetail, query]);

  const getCompanyDetails = () => {
    showLoader();
    axiosInstance
      .get(API.CompanyList)
      .then((res) => {
        setCompanyDetails(res.data);
        hideLoader();
      })
      .catch((err) => console.log(err));
  };

  const getFilteredValue = (e) => {
    setFilterCompany({ ...filterCompany, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (filterCompany.type !== "" && filterCompany.status !== "") {
      axiosInstance
        .post(API.CompanyFilter, filterCompany)
        .then((res) => {
          if (res.status === 200) {
            setFilteredProject(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    }
    if (filterCompany.type !== "" && filterCompany.status === "") {
      axiosInstance
        .post(API.CompanyFilter, { type: filterCompany.type })
        .then((res) => {
          if (res.status === 200) {
            setFilteredProject(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    } else if (filterCompany.status !== "" && filterCompany.type === "") {
      axiosInstance
        .post(API.CompanyFilter, { status: filterCompany.status })
        .then((res) => {
          if (res.status === 200) {
            setFilteredProject(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    } else if (filterCompany.status === "All" && filterCompany.type === "All") {
      setFilteredProject(companyDetail.results);
    } else if (
      filterCompany.status === "Filter" &&
      filterCompany.type === "Filter"
    ) {
      setFilterCompany({ type: "", status: "" });
      setFilteredProject(null);
      // const filtered = companyDetail.results.filter(company => {
      //   return company.title.toLowerCase().includes(query.toLowerCase())
      // });

      // setFilteredProject(filtered)
    }
  }, [filterCompany]);

  // const getFilteredValue = (e) => {
  //     const value = e.target.value;

  //     let companyFiltered = companyDetail.results.filter(company => company.title.toLowerCase().includes(value.toLowerCase()))

  //     setCompanyFiltered(companyFiltered)
  // }

  // console.log(companyFiltered)

  return (
    <div style={{ height: "90vh", overflow: "hidden" }}>
      <AdminHeader companyDetail={companyDetail} />

      <div style={{ width: "90%", margin: "auto", marginTop: "100px" }}>
        <div className={styles.filterWrapper}>
          <Search onChange={handleSearchChange} />
          <Filter
            opt1="Standard"
            opt2="Premium"
            value1="standard"
            name="type"
            value2="premium"
            onChange={getFilteredValue}
          />
          <Filter
            opt1="Active"
            value1="active"
            name="status"
            value2="inactive"
            opt2="In Active"
            onChange={getFilteredValue}
          />
        </div>
      </div>

      {companyDetail ? (
        <DetailComponent
          getCompanyDetails={getCompanyDetails}
          companyDetail={
            !filteredProject
              ? query !== ""
                ? searchCompany
                : companyDetail.results
              : filteredProject
          }
        />
      ) : (
        <div>{loader}</div>
      )}
    </div>
  );
};

export default connect(null, { logout })(Details);

import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import ProjectSnapshotContext from '../../containers/ProjectSnapshot/ProjectSnapshotContext';
import TableCell from "@material-ui/core/TableCell";


import TableRow from "@material-ui/core/TableRow";


const useStyles = makeStyles({
  table: {
    backgroundColor: "white",
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  },
});

const MemberListSnapshot = (props) => {
  const classes = useStyles();
  const [ snapshotView , loading ] = useContext(ProjectSnapshotContext);
  
  return (
    (!snapshotView ? <TableRow><TableCell><i className="fa fa-refresh fa-spin"></i>Loading...</TableCell></TableRow> : snapshotView.members.map((member, index) => (
        <TableRow key={member.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{member.user.first_name} {member.user.last_name}</TableCell>
        <TableCell>{member.role}</TableCell>
       
      </TableRow>
      )) )
  )
}

export default MemberListSnapshot;


// (!taskSnapshotView ? <TableRow>{loading}</TableRow> : taskSnapshotView.members.map((member, index) => (
//     <TableRow key={member.id}>
//     <TableCell>{index + 1}</TableCell>
//     <TableCell>{member.user.username}</TableCell>
//     <TableCell>{member.role}</TableCell>
//     <TableCell align="left">
//        <SimpleMenu edit="Edit" delete="Delete" />
//     </TableCell>
//   </TableRow>
//   )))


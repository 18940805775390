import React, { createContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";

import { Dialog, DialogTitle, DialogContent, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  AddUserModal,
  ProjectDocument,
  AppointAdmin,
  AddConferenceModal,
  UpdateActionItemModal,
} from "../../components";
import styles from "./modal.module.css";

import {
  DeleteReminder,
  AddMemberModal,
  TaskModalUpdate,
  AddTaskMember,
  AddProjectActionItems,
  AddTaskActionItem,
  ProjectActionItemEdit,
} from "../../components";
import { NewProject } from "../../containers";
import { EditModal } from "../../components";
import TaskDocumentModal from "../TaskDocumentModal";
import TaskDocumentStatusChange from "../TaskDocumentStatusChange";
import MyActionItemModal from "../MyActionItemModal";
import DocumentEditModal from "../DocumentEditModal";
import ConferenceEditModal from "../ConferenceEditModal";
import ResubmitDocument from "../ResubmitDocument";
import CancleSubscription from "../CancleSubscription";

export const CompanyContext = createContext();

const Modal = (props) => {
  const { projectID } = props;

  return (
    <>
      {props.noTitle ? (
        <Dialog
          open={props.openPop}
          fullScreen={props.fullWidth}
          onBackdropClick={props.backdropClicked}
        >
          <DialogContent>
            {props.newProject ? (
              <NewProject
                id={props.id}
                backToHome={props.backToHome}
                deleteproject={props.deleteProject}
              />
            ) : (
              <EditModal
                editState={props.editState}
                backToHome={props.backToHome}
              />
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={props.openPop}
          fullScreen={props.fullWidth}
          onBackdropClick={() => {
            props.backdropClicked();
          }}
          style={{ position: "absolute", bottom: "40" }}
        >
          <DialogTitle>
            <div className={styles.modalHeader}>
              <div>{props.title}</div>
              <Link to="#" onClick={props.onClick}>
                <Close
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                  }}
                />
              </Link>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {props.deleteBar ? (
              <DeleteReminder
                editProject={props.editProject}
                confirmDelete={props.closeDialog}
                confirmDeleteData={props.ConfirmDeleteData}
                loading={props.loading}
              />
            ) : null}

            {props.name ? (
              <>
                <AddUserModal button={props.button} onClick={props.onClick} />
              </>
            ) : null}

            {props.task ? (
              <AddConferenceModal
                setAlert={props.setAlert}
                onClick={props.onClick}
                button={props.button}
                roomName={props.roomName}
                getUpcomingConferenceList={props.getUpcomingConferenceList}
              />
            ) : null}

            {props.subs ? (
              <CancleSubscription
                setAlert={props.setAlert}
                onClick={props.onClick}
              />
            ) : null}

            {props.admin ? (
              <AppointAdmin
                onClick={props.onClick}
                button={props.button}
                getCompanyAdmins={props.getCompanyAdmins}
              />
            ) : null}

            {props.edit ? (
              <ProjectActionItemEdit
                setAlert={props.setAlert}
                getProjectActionItems={props.getProjectActionItems}
                projectID={projectID}
                editActionItem={props.editActionItems}
                onClick={props.onClick}
                button={props.button}
              />
            ) : null}

            {props.actions ? (
              props.taskRoom ? (
                <AddTaskActionItem
                  getTaskActionItems={props.getTaskActionItems}
                  onClick={props.onClick}
                  setAlert={props.setAlert}
                  taskID={props.taskID}
                  button="Add Task Action Items"
                  proID={props.proID}
                />
              ) : (
                <AddProjectActionItems
                  getProjectActionItems={props.getProjectActionItems}
                  projectID={projectID}
                  close={props.close}
                  button={props.button}
                  onClick={props.onClick}
                  setAlert={props.setAlert}
                />
              )
            ) : null}

            {props.status ? (
              props.taskRoom ? (
                <TaskDocumentModal
                  onClick={props.onClick}
                  getTaskDocumentsList={props.getTaskDocumentsList}
                  button={props.button}
                  setAlert={props.setAlert}
                  proID={props.proID}
                  taskID={props.taskID}
                />
              ) : (
                <ProjectDocument
                  setAlert={props.setAlert}
                  projectID={projectID}
                  button="Add Document"
                  onClick={props.onClick}
                  getProjectDocumentList={props.getProjectDocumentList}
                />
              )
            ) : null}

            {props.members ? (
              props.taskRoom ? (
                <AddTaskMember
                  getTaskMembersList={props.getTaskMembersList}
                  setAlert={props.setAlert}
                  taskID={props.taskID}
                  proID={props.proID}
                  button={props.button}
                  onClick={props.onClick}
                />
              ) : (
                <AddMemberModal
                  setAlert={props.setAlert}
                  getProjectMembersList={props.getProjectMembersList}
                  button={props.button}
                  onClick={props.onClick}
                  projectID={props.projectID}
                />
              )
            ) : null}

            {props.statusChange ? (
              <TaskDocumentStatusChange
                setAlert={props.setAlert}
                onClick={props.onClick}
                documentID={props.documentID}
                button={props.button}
                proID={props.proID}
              />
            ) : null}

            {props.update ? (
              <UpdateActionItemModal
                onClick={props.onClick}
                setAlert={props.setAlert}
                editState={props.editState}
                button={props.button}
              />
            ) : null}

            {props.todo ? (
              <MyActionItemModal
                getMyActionItems={props.getMyActionItems}
                setAlert={props.setAlert}
                onClick={props.onClick}
                button={props.button}
                companyId={props.companyId}
              />
            ) : null}

            {props.actionItemupdate ? (
              <TaskModalUpdate
                editState={props.editState}
                button={props.button}
                onClick={props.onClick}
                setAlert={props.setAlert}
              />
            ) : null}

            {props.updateDocument ? (
              <DocumentEditModal
                onClick={props.onClick}
                setAlert={props.setAlert}
                button={props.button}
                editState={props.editState}
              />
            ) : null}

            {/* {props.conferenceEdit ? <ConferenceEditModal getUpcomingConference={props.getUpcomingConference} button={props.button} editState={props.editState} onClick={props.onClick} setAlert={props.setAlert} /> : null} */}
            {props.resubmit ? (
              <ResubmitDocument
                ID={props.ID}
                onClick={props.onClick}
                button={props.button}
              />
            ) : null}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { company: state.auth.data };
};

export default connect(mapStateToProps, { setAlert })(Modal);

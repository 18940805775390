import React, { useEffect, useRef, useContext, useState } from "react";
import { connect } from "react-redux";
import {  useHistory } from "react-router-dom";
import MuiTableCell from "@material-ui/core/TableCell";
import {  withStyles } from "@material-ui/core/styles";


import { setAlert } from "../../actions/alert";

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { formatDate } from '../../utils/dateTimeUtility';

import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";

import ConferenceContext from "../../containers/ListOfConferences/conferenceContext";
import {
  SimpleMenu,
  Modal,
  TaskActionItemsList,
  TaskMembersTable,
  DocumentTable,
  ContactMessageData,
  ProjectMembersList,
  TaskDocumentList,
} from "../../components";
import "./table.css";

import ProjectActionItemsList from "../ProjectActionItemsList";
import { MyActionItems } from "../../components";


const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "10px",
  },
})(MuiTableCell);

const BasicTable = (props) => {

 

  const { projectID } = props;
  const history = useHistory();

  const conferenceList = useContext(ConferenceContext);
  const { taskList } = props;
  const [taskStatus, setTaskStatus] = useState({ status: "" , weight:'', title: ''});
  const [showHoverItems, setShowHoverItems] = useState(false);
  const [value, setValue] = React.useState(false);
  const [statusEdit, setStatusEdit] = useState();

  const [ ID, setID ] = useState(null);
  const [slider, setSlider] = useState(false);
  const [ statusValue , setStatusValue ] = useState({status : ''});
  const [clickEdit, setClickEdit] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [ taskValue, setTaskValue ] = useState({title : '', status: '', weight:'', start_date:'', end_date:''});
  const [edit, setEdit] = useState();
  const [ loading, setLoading] = useState(false);

  const [taskData, setTaskData] = useState({
    title: "",
    status: "",
    weight: "",
    start_date: "",
    end_date: "",
    active: true,
  });

  

  const EditTableData = (id, title, status, weight, start_date, end_date) => {
    setEdit(id);
    setTaskData({
      title: title,
      status: status,
      start_date: start_date,
      weight: weight,
      end_date: end_date,
      active: true,
    });
    setTaskValue({
      title: title,
      status: status,
      start_date: start_date,
      weight: weight,
      end_date: end_date,
      active: true,
    });
    
  };


  const handleTaskEditChange = (event) => {
    
    setTaskData({ ...taskData, [event.target.name]: event.target.value });
  };

  const capitalizeFirstChar = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  
 
  const handleTaskEditSubmit = () => {
   
    if(edit){
      axiosInstance
      .put(API.UpdateTask + `${edit}/update/`, taskData)
      .then((res) => { if(res.status === 200){
        props.setAlert("Task Editted SuccessFully", "success")
        props.getTasks();
        setEdit()
      }
      
     })
      .catch((err) => {
        if(err.response.status === 403){props.setAlert("You do not have permission to perform this action", "error")
        setEdit()
      }
    else{
       props.setAlert("Something went wrong", "error");
       setEdit()
    }});
    }
  };

  const deleteTaskAsk = (id) => {
    setOpenDelete(!openDelete)
    setID(id)
  }

  const deleteTaskConfirm = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.UpdateTask + `${ID}/update/`)
      .then((res) => {
        if (res) {
          setOpenDelete(!openDelete);
          props.setAlert("Task Deleted Successfully", "success");
          props.getTasks();
          setLoading(false)
        }
      })
      .catch((err) => {
        props.setAlert("Error Deleting Task", "error");
        setOpenDelete(!openDelete);
        setLoading(false)
      });
  };

  const updateStatusField = (id, status, weight, title) => {
    setStatusEdit(id);
    setTaskStatus({status : status, title: title, weight: weight});

    setStatusValue({status : status})
  
  };

  const handleTaskStatusChange = (e) => {
    setTaskStatus({...taskStatus,[e.target.name]: e.target.value });

  };

  const handleTaskStatusSubmit = (e) => {
    if(taskStatus.status === statusValue.status){
      setStatusEdit();
    }
    else {

      const data = {
        status: taskStatus.status,
        weight : taskStatus.weight,
        title : taskStatus.title,
      }

      
      axiosInstance
      .put(API.UpdateTask + `${statusEdit}/update/`, data)
      .then((res) => {
        if (res) {
          props.setAlert("Task Editted Successfully", "success");
          props.getTasks();
          
          setStatusEdit();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
       
          props.setAlert("You Do Not have permission to perform this action", "error")
          setStatusEdit();
        }
        else{
          props.setAlert("Something Went Wrong", "error")
        }
        setStatusEdit()
      });
    }
  };

  const getTaskDuration = (duration) => {
    const dur = duration.split(',')[0]

    return dur
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
       
        if (ref.current && !ref.current.contains(event.target)) {
          if(taskValue.title === taskData.title || taskValue.weight === taskData.weight){
            
          }
          if(taskValue.title !== taskData.title || taskValue.weight !== taskData.weight || taskValue.start_date !== taskData.start_date || taskValue.end_date !== taskData.end_date){
            if(taskData.start_date > taskData.end_date){
              props.setAlert("Start Date cannot be greater than end data", "error");
              
            }
            else{
              handleTaskEditSubmit()
            }
          }
         
          
        }
        
       
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, edit, taskData, taskValue, slider]);
  };

  const useOutsideSlider = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOut = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleTaskStatusSubmit(event);
        }
        
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOut);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOut);
      };
    }, [ref, statusEdit, taskStatus, slider]);
  };

 

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const sliderRef = useRef(null);
  useOutsideSlider(sliderRef);
 

  return (
    <>
      <TableContainer style={{ overflowX: "hidden" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="largeScreen">
              {props.member ? (
                <>
                 
                    <TableCell>
                      <h6>{props.td1}</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>Name</h6>
                    </TableCell>
                    {props.taskRoom ? null : (
                      <TableCell align="left">
                        <h6>{props.td3}</h6>
                      </TableCell>
                    )}
                    
                    <TableCell align="left">
                      <h6>{props.listOfConference ? "Date" : props.td4}</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>{props.td5}</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>{props.td6}</h6>
                    </TableCell>
                 
                </>
              ) : null}
              {props.document ? (
                <>
                  
                    <TableCell align="center">
                      <h6>{props.sno}</h6>
                    </TableCell>
                    <TableCell align="center">
                      <h6>{props.name}</h6>
                    </TableCell>
                    {props.taskDocument ? (
                      <TableCell align="center">
                        <h6>Status</h6>
                      </TableCell>
                    ) : null}
                    <TableCell align="center">
                      <h6>Submitted By</h6>
                    </TableCell>
                    <TableCell align="center">
                      <h6>{props.taskDocument ? "Status Change Date" : "Uploaded Date"}</h6>
                    </TableCell>
                    
                    {props.taskDocument ? <TableCell><h6>Logs</h6></TableCell> : null}
                    <TableCell align="center">
                      <h6>{props.action}</h6>
                    </TableCell>
                  
                </>
              ) : null}

              {props.task ? (
                <>
                 
                    <TableCell
                      style={{
                        fontSize: "12px",
                        width: "4%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                      align="center"
                    >
                      {props.id}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "55%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "5%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      Priority
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "10%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.stDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "10%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.endDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "6%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.duration}
                    </TableCell>
                    {/* <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "6%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.sid}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "6%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.pid}
                    </TableCell> */}
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "6%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      {props.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontSize: "12px",
                        width: "4%",
                        border: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      Delete
                    </TableCell>
                  
                </>
              ) : null}

              {props.actionItems && (
                <>
                  
                    <TableCell>
                      <h6>{props.sno}</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>Action Item</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>Created By</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>Assigned To</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>{props.dueDate}</h6>
                    </TableCell>
                    {props.taskRoom ? null : 
                    <TableCell align="left">
                      <h6>Task</h6>
                    </TableCell>}
                    <TableCell align="left">
                      <h6>Status</h6>
                    </TableCell>
                    <TableCell align="left">
                      <h6>Edit/Delete</h6>
                    </TableCell>
                    
                 
                </>
              )}

              {props.myActionItems && (
                <>
                  <TableCell>
                    <h6>{props.sno}</h6>
                  </TableCell>
                  
                  <TableCell align="left">
                    <h6>Action Item Description</h6>
                  </TableCell>
                  
                  <TableCell align="left">
                    <h6>{props.actionProject}</h6>
                  </TableCell>
                  
                  <TableCell align="left">
                    <h6>{props.tas}</h6>
                  </TableCell>
                  <TableCell align="left">
                    <h6>Created By</h6>
                  </TableCell>
                  <TableCell align="left">
                    <h6>{props.date}</h6>
                  </TableCell>
                  <TableCell align="left"><h6>Status</h6></TableCell>
                  <TableCell align="left"><h6>Edit/Delete</h6></TableCell>
                  
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.ProMember ? (
              props.taskRoom ? (
                <TaskMembersTable
                  taskID={props.taskID}
                  onClick={props.onClick}
                />
              ) : (
                <ProjectMembersList
                  getProjectMembersList={props.getProjectMembersList}
                  projectID={props.projectID}
                />
              )
            ) : null}


            {props.contactMessage ? <ContactMessageData /> : null}

            {props.listOfConference && conferenceList
              ? conferenceList.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                  
                        <TableRow
                          style={{
                            borderBottom: "1px solid rgba(224,224,224,1)",
                          }}
                          className="largeScreen"
                      
                        >

                        
                          <TableCell style={{paddingLeft : '20px'}}>{index+1}</TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {item.meeting_name}
                          </TableCell>
                          <TableCell align="left">{item.project ?  item.project_name : "No Project"}</TableCell>
                          <TableCell align="left">{formatDate(item.start)}</TableCell>
                          <TableCell align="left" style={{width : '500px'}}>
                            {item.members.map((memberItem, memIdx) => {
                              return (memIdx ? ", " : "") + memberItem.username;
                            })}
                          </TableCell>
                          <TableCell align="left">
                            <SimpleMenu edit="Edit" delete="Delete" />
                          </TableCell>
                        </TableRow>
                      
                        <TableRow component={Paper} className="smallScreen">
                          <TableCell
                            align="left"
                            style={{
                              width: "40%",
                              lineHeight: "0.5rem",
                              padding: "20px",
                            }}
                          >
                            <p>S.No.</p>
                            <p>Name</p>
                            <p>Project Role</p>
                            <p>Email</p>
                            <p>Phone Number</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              width: "40%",
                              lineHeight: "0.5rem",
                              padding: "20px",
                            }}
                          >
                            <p>{item.id}</p>
                            <p>{item.title}</p>
                            <p>Client</p>
                            <p>Singh.dvya05@gmail.com</p>
                            <p>9808916325</p>
                          </TableCell>
                          <TableCell align="center" style={{ width: "20%" }}>
                            <SimpleMenu edit="Edit" delete="Delete" />
                          </TableCell>
                        </TableRow>
                     
                    </React.Fragment>
                  );
                })
              : null}

            {props.actionItems ? (
              props.taskRoom ? (
                <TaskActionItemsList />
              ) : (
                <ProjectActionItemsList
                  getProjectActionItems={props.getProjectActionItems}
                  projectID={projectID}
                />
              )
            ) : null}

            {props.document ? (
              props.taskDocument ? (
                <TaskDocumentList
                  getIDForDrawer={props.getIDForDrawer}
                  showDrawer={props.showDrawer}
                />
              ) : (
                <DocumentTable setAlert={props.setAlert} />
              )
            ) : null}

            {props.myActionItems ? (
              <MyActionItems getMyActionItems={props.getMyActionItems} />
            ) : null}

            {props.task && (
              <>
                {props.dynamicTable}

              
                  {!taskList ? <div>{props.loader}</div> : taskList.map((task) => (
                      
                    <React.Fragment key={task.id}>
                      
                      <TableRow key={task.id} ref={wrapperRef} className="largeScreen">
                        <TableCell
                          onClick={() =>
                            history.push(
                              `/task/${projectID}/taskJournal/${task.title.replace(/\\|\//g,'')}/${task.id}`,
                              { projectName : task.project.title}
                         
                            )
                          }
                          align="center"
                          style={{
                            wdith: "4%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                            cursor:"pointer"
                          }}
                        >
                          {task.id}
                          
                        </TableCell>
                        <TableCell
                          className={edit === task.id ? "valueAssigned" : null}
                          onMouseOver={() => setShowHoverItems(true)}
                          onClick={() => {
                            setEdit(task.id);
                            setValue(true);

                            EditTableData(
                              task.id,
                              task.title,
                              task.status,
                              task.weight,
                              task.start_date,
                              task.end_date
                            );
                          }}
                          onMouseEnter={() => setClickEdit(task.id)}
                          style={{
                            width: "55%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                            cursor:"pointer"
                           
                          }}
                          onMouseLeave={() => setClickEdit()}
                        >
                          {edit === task.id ? (
                            <div className="inputField">
                              <input
                                autoFocus
                                
                                onChange={handleTaskEditChange}
                                className="inputState"
                                name="title"
                                value={taskData.title}
                              />
                              <div className="deleteIcon">
                              <div className="commentIcon" onClick={() => history.push(`/task/${projectID}/taskJournal/${task.title.replace(/\\|\//g,'')}/${task.id}`)}>
                                     
                                  <p>Click here for Task Details</p>
                                  <KeyboardArrowRightIcon fontSize="small" />
                               
                                   
                                  </div>
                              </div>
                            </div>
                          ) : (
                            <div className="inputField">
                              <div>{task.title}</div>
                              {clickEdit === task.id ? (
                                <div className="deleteIcon">
                                  <div className="commentIcon" onClick={() => history.push(`/task/${projectID}/taskJournal/${task.title.replace(/\\|\//g,'')}/${task.id}`)}>
                                    
                                    
                                   <p>Click here for Task Details</p>
                                   <KeyboardArrowRightIcon fontSize="small" />
                                  </div>
                                  
                                </div>
                              ) : null}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                           className={edit === task.id ? "valueAssigned" : null}
                           
                          style={{
                            width: "5%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                            cursor:"pointer"
                          }}
                        >
                          {edit === task.id ? <input type="number" className="inputState"  onChange={handleTaskEditChange} value={taskData.weight} name="weight" /> : task.weight}
                        </TableCell>
                        <TableCell
                          
                          className={edit === task.id ? "valueAssigned" : null}
                          style={{
                            width: "6%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                            cursor:"pointer"
                          }}
                        >
                          {edit === task.id ? <input type="date" className="inputState" onChange={handleTaskEditChange} value={taskData.start_date} name="start_date" /> : task.start_date}
                        </TableCell>
                        <TableCell
                         className={edit === task.id ? "valueAssigned" : null}
                          style={{
                            width: "10%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",

                          }}
                        >
                         {edit === task.id ? <input type="date" className="inputState" onChange={handleTaskEditChange} value={taskData.end_date} name="end_date" /> : task.end_date} 
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                          }}
                        >
                          {getTaskDuration(task.get_duration)}
                        </TableCell>
                        {/* <TableCell
                          style={{
                            width: "6%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                          }}
                        >
                          1122
                        </TableCell>
                        <TableCell
                          style={{
                            width: "6%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                          }}
                        >
                          1123
                        </TableCell> */}
                        <TableCell
                          style={{
                            width: "6%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                          }}
                          className={
                            statusEdit === task.id ? "valueAssigned" : null
                          }
                          className={edit === task.id ? "valueAssigned" : null}
                          
                        >
                          
                          {statusEdit === task.id ? (
                            <select
                              autoFocus
                              ref={sliderRef}
                              name="status"
                              onChange={handleTaskStatusChange}
                              className="selectStatus"
                            >
                              <option value="open">Open</option>
                              <option value="inprogress">Inprogress</option>
                              <option value="parked">Parked</option>
                              <option value="closed">Closed</option>
                            </select>
                          ) : (
                            <div onClick={() => {
                              updateStatusField(task.id, task.status, task.weight, task.title);
                              // EditTableData(
                              //   task.id,
                              //   task.title,
                              //   task.status,
                              //   task.weight,
                              //   task.start_date,
                              //   task.end_date
                              // );
                            }}>{capitalizeFirstChar(task.status)}</div>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            width: "4%",
                            border: "1px solid rgba(224,224,224,1)",
                            fontSize: "11px",
                          }}
                        >
                         <SimpleMenu delete="Delete" deleteProject={() => deleteTaskAsk(task.id)} />
                        </TableCell>
                      </TableRow>
                      
                  <TableRow component={Paper} className="smallScreen">
                    <TableCell
                      onClick={() =>
                        history.push(
                          `/task/${projectID}/taskJournal/${task.title.replace(/\\|\//g,'')}/${task.id}`,
                          { projectName : task.project.title}
                     
                        )
                      }
                      align="left"
                      style={{
                        width: "40%",
                        lineHeight: "0.5rem",
                        paddingLeft: "10px",
                        cursor:"pointer"
                      }}
                    >
                      <p>Task ID</p>
                      <p>Task Name</p>
                      <p>Priority</p>
                      <p>Date</p>
                      <p>Duration</p>
                      {/* <p>Successor ID</p>
                      <p>Predecessor ID</p> */}
                      <p>Status</p>
                    </TableCell>
                    <TableCell
                     onClick={() =>
                      history.push(
                        `/task/${projectID}/taskJournal/${task.title.replace(/\\|\//g,'')}/${task.id}`,
                        { projectName : task.project.title}
                   
                      )
                    }
                      align="left"
                      style={{
                        width: "80%",
                        lineHeight: "0.5rem",
                        padding: "10px",
                        cursor:"pointer"
                      }}
                    >
                      <p>{task.id}</p>
                      <p>{task.title}</p>
                      <p>{task.weight}</p>
                      <p>{task.start_date} to {task.end_date}</p>
                      <p>{task.get_duration} </p>
                      {/* <p>1080</p>
                      <p>1209</p> */}
                      <p>{task.status}</p>
                    </TableCell>
                    <TableCell align="center" style={{ width: "10%" }}>
                      <SimpleMenu delete="Delete" deleteProject={() => deleteTaskAsk(task.id)} />
                    </TableCell>
                  </TableRow>
                
                    </React.Fragment>
                  ))}
                

               
              </>
            )}
          </TableBody>
        </Table>
        <Modal
          openPop={openDelete}
          onClick={() => setOpenDelete(!openDelete)}
          backdropClicked={() => setOpenDelete(!openDelete)}
          deleteBar="Delete Bar"
          title="Confirm Delete"
          loading={loading}
          ConfirmDeleteData={() => deleteTaskConfirm(ID)}
          closeDialog={() => setOpenDelete(!openDelete)}
        />
      </TableContainer>

      
    </>
  );
};

export default connect(null, { setAlert })(BasicTable);

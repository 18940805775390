import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
} from "../action_types";
import jwt from "jwt-decode";

const initialState = {
  isAuthenticated: localStorage.getItem("token") ? true : false,
  error: false,
  loading: false,
  data: localStorage.getItem("token")
    ? jwt(localStorage.getItem("token"))
    : null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      if(payload.access !== undefined && payload.refresh !== undefined){
        localStorage.setItem("token", payload.access);
        localStorage.setItem("refresh_token", payload.refresh);
      }
     
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: false,

        data: jwt(localStorage.getItem("token")),
      };

    case LOGIN_FAIL:
      if(payload.access === undefined && payload.refresh === undefined){
        localStorage.removeItem("token")
        localStorage.removeItem("refresh_token")
      }
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        data: null,
        error: true,
      };
    case LOGOUT: {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("companyId");
      localStorage.removeItem("companyTitle");
      localStorage.removeItem("companyPhoto");

      return {
        ...state,
        isAuthenticated: false,

        loading: false,
        data: null,
      };
    }
    case REFRESH_TOKEN: {
      return {
        ...state,
        isAuthenticated: true,
        loading: false,

        data: jwt(localStorage.getItem("token")),
      };
    }
    default:
      return state;
  }
}

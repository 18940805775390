import React, { useEffect, useState, useContext} from 'react';
import { ProjectManagerSelectBox, SelectBoxModal, Button } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import TaskDocumentListContext from "../TaskDocuments/TaskDocumentListContext";
import API from '../../urls/index';




const TaskDocumentStatusChange = ({ onClick ,button, documentID, setAlert }) => {
    const [ getTaskDocument, proID, getTaskDocumentsList ] = useContext(TaskDocumentListContext)
    const [ userRole, setUserRole ] = useState(null)
    const [ status, setStatus ] = useState({ status: ''})
    const [ loading, setLoading ] = useState(false)
    const handleTaskDocumentChange = (e) => {
        setStatus({[e.target.name ] : e.target.value})
    }

    useEffect(() => {
        getUserRole();
     }, [])
 
     const getUserRole = () => {
         axiosInstance.get(API.UserRoleInProject + `${proID}/user/position/`)
                      .then(res => {if(res.status === 200){
                          setUserRole(res.data.results)
                      }})
                      .catch(err => console.log(err))
     }
 

  const handleTaskStatusSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    axiosInstance.post(API.TaskDocumentStatusUpdate + `${documentID}/status/update/`, status)
                 .then(res => {if(res){
                    getTaskDocumentsList();
                    onClick();
                    setAlert("Successfully editted task document", "success")
                    setLoading(false)
                 }})
                 .catch(err => {if(err){
                   setAlert("You do not have permission to perform this action", "error");
                   setLoading(false);
                   onClick()
                 }})
  }


    const handleTaskDocumentStatusSubmit = () => {
       
        if(!userRole){
          return <div>Loading........</div>
        }
        else{
        if(userRole.company_admin || userRole.role === "PM"){
          return <ProjectManagerSelectBox
                 htmlFor="Status"
                 name="status"
                 opt1="Not Deliverable"
                 opt2="Initial Submission"
                 opt3="Internal Review"
                 opt4="PM Rejected"
                 opt5="Submitted to Client"
                 opt6="Overcome By Event"
                 value1="Not Deliverable"
                 value2="Initial Submission"
                 value3="Internal Review"
                 value4="PM Rejected"
                 value5="Submitted to Client"
                 value6="Overcome By Event"
                 onChange={handleTaskDocumentChange}
    
           />
        }
        else if(userRole.role === "PMem"){
          return <SelectBoxModal
                 htmlFor="Status"
                 name="status"
                 opt1="Not Deliverable"
                 opt2="Initial Submission"
                 opt3="Resubmission"
                 value1="Not Deliverable"
                 value2="Initial Submission"
                 value3="Resubmission"
                 onChange={handleTaskDocumentChange}
           />
        }
        else if(userRole.role === "PC"){
          return <SelectBoxModal
                 htmlFor="Status"
                 name="status"
                 opt1="Not Deliverable"
                 opt2="Approved by Client"
                 opt3="Rejected by Client"
                 value1="Not Deliverable"
                 value2="Approved by Client"
                 value3="Rejected by Client"
                 onChange={handleTaskDocumentChange}
           />
        }
        
      }
    }     
   return (
       <>
        <div style={{textAlign:"center"}}>Give the Review To the Submitted Document!! </div>
        {handleTaskDocumentStatusSubmit()}
        <Button loading={loading} button={button} onClick={handleTaskStatusSubmit} />
       </>
   )
}

export default TaskDocumentStatusChange;
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getprojectlist } from "../../actions/projectList";
import { getUserCompany } from "../../actions/company";
import { getUserProfileInfo } from "../../actions/userProfile";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import home from "../../home.svg";
import projectIcon from "../../projects.svg";

import { styles } from "./styles";

const SideBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [companyVal, setCompanyVal] = useState(null);

  useEffect(() => {
    if (props.info.companies.length > 1) {
      if (localStorage.getItem("companyId")) {
        setCompanyVal({
          id: localStorage.getItem("companyId"),
          title: localStorage.getItem("companyTitle"),
          url: localStorage.getItem("companyPhoto"),
        });
      } else {
        const [companyId] = props.info.companies;
        setCompanyVal(companyId);
      }
    } else {
      const [companyId] = props.info.companies;
      setCompanyVal(companyId);
    }
  }, []);

  useEffect(() => {
    if (companyVal) {
      props.getprojectlist(companyVal.id);
      props.getuserprofileinfo();
    }
  }, [companyVal]);

  const categories = [
    {
      id: "MENU",
      children: [
        {
          id: "Home",
          icon: <img style={{ marginLeft: "-5px" }} src={home} />,
          urlTo: "/",
        },
        {
          id: "My Action Items",
          icon: <SystemUpdateAltIcon />,
          urlTo: `/myitems/${companyVal ? companyVal.id : "Loading..."}`,
        },
        {
          id: "Conference",
          icon: <VideocamOutlinedIcon />,
          urlTo: "/conferences",
        },
      ],
    },
    {
      id: "PROJECTS",
      children: props.projectslist.data.map((project) => ({
        id: project.title,
        urlTo: `/conversation/${project.title.replace(/\\|\//g, "")}/${
          project.id
        }`,
        projectId: project.id,
        projectTitle: project.title,
        icon: <img src={projectIcon} alt="icon" />,
      })),
      // [
      //   { id: "Project A", icon: <img src={projects} alt='Folder Icon'/> },
      //   { id: "Project B", icon: <img src={projects} alt='Folder Icon' /> },
      //   { id: "Project C", icon: <img src={projects} alt='Folder Icon' /> },
      // ],
    },
  ];

  const { classes, getusercompany, getprojectlist, ...PaperProps } = props;

  return companyVal ? (
    <Drawer variant="permanent" {...PaperProps}>
      <List disablePadding className={clsx(classes.sidebarWidth)}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <ListItem
            className={clsx(
              classes.firebase,
              classes.sidbarBrand,
              classes.item,
              classes.itemCategory
            )}
          >
            <ListItemIcon className={classes.itemIcon}>
              {props.info.company_head ? (
                <React.Fragment>
                  {props.userprofile.data ? (
                    <Avatar
                      style={{ height: "45px", width: "45px" }}
                      src={`${props.userprofile.data.photo}`}
                    />
                  ) : (
                    <AccountCircleRoundedIcon fontSize="large" />
                  )}
                </React.Fragment>
              ) : (
                <Avatar
                  src={`http://nascent.link${companyVal.url}`}
                  style={{ height: "45px", width: "45px" }}
                />
              )}
            </ListItemIcon>
            {/* {props.info.companies
              ? props.info.companies.map((company) => company.title)
              : props.info.username} */}

            {companyVal.title}
          </ListItem>
        </Link>

        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>

            {children.map(({ id: childId, icon, urlTo, active }) => (
              <div
                key={childId}
                // to={{urlTo, state : {companyData : companyVal}}}
                onClick={() => history.push(urlTo, { companyData: companyVal })}
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  key={childId}
                  button
                  className={clsx(
                    classes.item,
                    active && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </div>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>

      <div className={classes.socialMediaIcon}>
        <a
          style={{
            color: "inherit",
          }}
          href="https://www.facebook.com/NascentLink-106128815016731"
          target="_blank"
        >
          <FacebookIcon className={classes.iconpart} fontSize="small" />
        </a>
        <a
          style={{
            color: "inherit",
          }}
          href="https://www.linkedin.com/company/augment-consulting-llc"
          target="_blank"
        >
          <LinkedInIcon className={classes.iconpart} fontSize="small" />
        </a>

        <a
          style={{
            color: "inherit",
          }}
          href="https://www.youtube.com/channel/UC1R-JbswGhDf7h40z3zWEWA/"
          target="_blank"
        >
          <YouTubeIcon className={classes.iconpart} fontSize="small" />
        </a>
        <a
          style={{
            color: "inherit",
          }}
          href="https://twitter.com/nascentlink"
          target="_blank"
        >
          <TwitterIcon className={classes.iconpart} fontSize="small" />
        </a>
      </div>
      <div>
        <p className={classes.copywright}>
          2021 Nascent INC, All rights reserverd
        </p>
      </div>
    </Drawer>
  ) : (
    <div>Loading...</div>
  );
};

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getprojectlist: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    info: state.auth.data,
    projectslist: state.projectList,
    companyinfolist: state.companyList,
    userprofile: state.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getusercompany: () => dispatch(getUserCompany()),
    getuserprofileinfo: () => dispatch(getUserProfileInfo()),
    getprojectlist: (id) => dispatch(getprojectlist(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(SideBar)));

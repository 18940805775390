/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";



import API from "../../urls";
import axiosInstance from "../../utils/axiosConfig";



const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  color: black;
  margin: 15px 25px 15px 20px;
`;

const InputWrapper = styled("div")`
  width: 300px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 7px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  outline: none;

  &.focused {
    outline: none;
  }

  & input {
    font-size: 14px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
    color: black;
  }
`;

const Listbox = styled("ul")`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: black;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const ProjectUsers = (props) => {


  const { proID } = props;
  const [usersList, setUsersList] = useState([]);
  const [ propVal, setPropValue] = useState(null);
  const [ editValue, setEditValue ] = useState(false);
 
  useEffect(() => {
    let unmounted = false;
    getProjectMembersList()

    return () => {
      unmounted = true;
    }
  },[])

  // useEffect(() => {
  //   if(props.value.length === 0){
  //     setPropValue(null)
  //   }
  //   else{
  //     setPropValue(props.value)
  //   }
  // }, [props.value])

  const getProjectMembersList = async () => {
    try {
      const res = await axiosInstance.get(API.ListProjectMembers + `${proID}/members/`);
      setUsersList(res.data.results);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
   if(props.value){
    if(props.value.length === 1){
      setEditValue(true)
    }
    else if(props.value.length === 0){
      setEditValue(false)
    }
   }
   
  }, [props.value])



  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",  
    multiple:true,
    options: usersList,
    getOptionLabel: (option) => option.user.first_name + ' ' + option.user.last_name,
    
    onChange: (event, value) => props.handleMembersFieldChange(value)
  });

  if (usersList.length == 0) {
    return <div>Loading...</div>;
  }




  return (
    <NoSsr>
      <div>
        <div
          {...getRootProps()}
          style={{ display: "flex", flexDirection: "row", marginLeft: "8px" }}
        >
          <Label style={{width : '24%'}} {...getInputLabelProps()}>{props.label ? props.label : "Members"}</Label>
          <InputWrapper
            ref={setAnchorEl}
            placeholder="Add Members"
            className={focused ? "focused" : ""}
            
          >
            {editValue ? props.value.map((option, index) => (
              <Tag label={option.user.first_name + ' ' + option.user.last_name} {...getTagProps({ index })} />))
             : 
            value.map((option, index) => (
              <Tag label={option.user.first_name + ' ' + option.user.last_name} {...getTagProps({ index })}  />
            ))}
            
            <input {...getInputProps()}   />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option.user.first_name + ' ' + option.user.last_name}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
};


export default ProjectUsers;

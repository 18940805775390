import React from 'react';
import styles from './selectmodal.module.css';



const ProjectManagerSelectBox = (props) => {
    return (
    <>
    <div className={styles.selectBoxOuterWrapper}>
     <label htmlFor={props.htmlFor}>{props.htmlFor}</label>
     <div className={styles.selectBoxModal}>
        <select name={props.name} id="subscription" placeholder="filter" onChange={props.onChange} className={styles.selectForm}>
            <option value={props.htmlFor}>{props.htmlFor}</option>
            <option value={props.value1}>{props.opt1}</option>
            <option value={props.value2}>{props.opt2}</option>
            <option value={props.value3}>{props.opt3}</option>
            <option value={props.value4}>{props.opt4}</option>
            <option value={props.value5}>{props.opt5}</option>
            <option value={props.value6}>{props.opt6}</option>
        </select>
        <br />
      </div>

    </div>    
    </>
    )
}

export default ProjectManagerSelectBox;
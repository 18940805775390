import React from "react";
import { Grid, Avatar } from "@material-ui/core";

import "./reply.css";

const Reply = ({ commented_by, last_name, photo, comment, time }) => {
  return (
    <Grid container wrap="nowrap" spacing={2} style={{marginLeft:'20px', marginTop:"20px"}} >
      <Grid item >
        <Avatar variant="square" alt="Remy Sharp" src={`http://nascent.link${photo}`} />
      </Grid>
      <Grid justifyContent="left" item xs zeroMinWidth>
        <p style={{ margin: 0, textAlign: "left", fontWeight:"bold" }}>{commented_by} {last_name} <span className="commentTime">{time}</span></p>
      
        <p style={{ textAlign: "left" }}>
         {comment}
        </p>
       </Grid>
          
        
      
    </Grid>
  );
};

export default Reply;

import React, { useState } from "react";

import { connect } from "react-redux";

import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls";

import {
  Button,

} from "../../components";


import {

  SelectBoxModal,
  UserList,
} from "../../components";


const AddMemberModal = (props) => {

  const { projectID } = props;
  const [loading, setLoading] = useState(false);
  const [error, setErrorValue] = useState(false);
  const [members, setMembers] = useState(false);
  const [role, setRole] = useState(false);
  const [projectMember, setProjectMember] = useState({
    role: "",
    members: "",
  });

  const handleMembersFieldChange = (value) => {
    if (projectMember.members !== '') {
      setMembers(false);
      setErrorValue(false);
    }
    setProjectMember({ ...projectMember, members: value });
  };

  const handleMemberChange = (e) => {

    if (projectMember.role !== '') {
      setRole(false);
      setErrorValue(false);
    }
    setProjectMember({ ...projectMember, [e.target.name]: e.target.value });

  };

  // let ID = projectMember.members.map(mem => mem.id)

  const handleProjectMemberSubmit = (e) => {
    e.preventDefault();


    if (projectMember.members === '') {
      props.setAlert("Please Add Atleast one Member", "error")
    }
    else if (projectMember.role === '') {
      props.setAlert("Please Add Member Role", "error")
    }


    else {

      const data = {
        role: projectMember.role,
        id: projectMember.members.map(member => member.id)
      }
      setLoading(true)

      axiosInstance
        .post(API.AddProjectMember + `${projectID}/member/add/`, data)
        .then((res) => {
          if (res) {
            setProjectMember({ role: '', members: '' })
            //   setProjectMember({
            // role: "",
            // members: ""})
            props.getProjectMembersList()

            setLoading(false);

            props.setAlert("Member Added Successfully", "success");
            props.onClick();


          } else {
            props.setAlert(
              "Error adding member Please Check Your Information",
              "error"
            );
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert("You Do not have permission to perform this action", "error");
          }
        });


    }


  };

  return (
    <>



      <UserList
        name="members"
        handleMembersFieldChange={handleMembersFieldChange}
      />

      <SelectBoxModal
        htmlFor="Roles"
        label="Member Role"
        value1="PM"
        value2="PMem"
        value3="PC"
        name="role"
        opt1="Project Manager"
        opt2="Project Member"
        opt3="Project Client"
        onChange={handleMemberChange}
      />
      <Button button={props.button} loading={loading} onClick={handleProjectMemberSubmit} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { notifications: state.notificationList }
}

export default connect(mapStateToProps)(AddMemberModal);

import { CANCEL_SUBSCRIPTION } from "../action_types";

const initialState = {
  cancelled: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelled: true,
      };

    default:
      return state;
  }
}

import {
    USER_INFO_FETCH_SUCCESS,
    USER_INFO_FETCH_FAIL,
} from '../action_types';


const initialState = {
    error: false, 
    loading: false,
    data : null,   
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_INFO_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data : payload
            }
     
        case USER_INFO_FETCH_FAIL:
            return {
                ...state,
                loading: false,
                data : null,
                error : true,
            }
        
        default:
            return state

    }
}
import API from "../urls/index";
import axiosInstance from "../utils/axiosConfig";
import {
    USER_COMPANY_FETCH_SUCCESS,
    USER_COMPANY_FETCH_FAIL,
    UPDATE_COMPANY_INFO
} from "../action_types";
import { setAlert } from "./alert";


export const getCompanyList = (payload) => (dispatch) => {};

export const getUserCompany = (payload) => (dispatch) => {
  axiosInstance
    .get(API.GetUserCompanyInfo)
    .then((res) => {
      dispatch({
        type: USER_COMPANY_FETCH_SUCCESS,
        payload: res.data,
      });
    //   dispatch(setAlert("Logged in Successfully", "success"));
    })
    .catch((err) => {
      dispatch({ type: USER_COMPANY_FETCH_FAIL, payload: err });
      dispatch(setAlert("Invalid Username or password", "error"));
    });
};

export const updateCompany = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_INFO,
    payload: payload,
  });
};

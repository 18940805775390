import {
    USER_COMPANY_FETCH_SUCCESS,
    USER_COMPANY_FETCH_FAIL,
    UPDATE_COMPANY_INFO
} from '../action_types';


const initialState = {
    error: false, 
    loading: false,
    data : null,   
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_COMPANY_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data : payload
            }
     
        case USER_COMPANY_FETCH_FAIL:
            return {
                ...state,
                loading: false,
                data : null,
                error : true,
            }

        case UPDATE_COMPANY_INFO: {
            return {
                ...state,
                loading: false,
                data : [...state.data, payload]
            }
        }
        
        default:
            return state

    }
}
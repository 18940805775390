import React from 'react';
import styles from './usermodalinput.module.css';

const UserModalInput = (props) => {
    return(
    
    <div className={styles.contentSection}>
        
        <label htmlFor={props.htmlFor}>{props.label}</label>
        <input type={props.type} name={props.name} onChange={props.onChange} className={props.validate ? "redInputBox" : null} />
        {props.validate === true ? <div style={{color:'red'}}>This Field is required</div> : null}
    </div>
    )
}

export default UserModalInput;
import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";

import TaskSnapshotContext from '../../components/TaskSnapshot/TaskSnapshotContext';
import TableRow from "@material-ui/core/TableRow";

import { SimpleMenu } from '../../components';


const useStyles = makeStyles({
  table: {
    backgroundColor: "#e5e7ea",
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  },
});

const TaskDocumentSnapshot = (props) => {
  const classes = useStyles();
  const [ taskSnapshotView , loading ] = useContext(TaskSnapshotContext);
  
  return (
    (!taskSnapshotView ? <TableRow>{loading}</TableRow> : taskSnapshotView.documents.map((document, index) => (
        <TableRow key={document.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{document.name}</TableCell>
        <TableCell align="left">
           <SimpleMenu edit="Edit" delete="Delete" />
        </TableCell>
      </TableRow>
      )) )
  )
}

export default TaskDocumentSnapshot;
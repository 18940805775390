import React, { useState } from "react";
import { Button } from '@material-ui/core';
import axiosInstance from "../../utils/axiosConfig";
import API from '../../urls/index';
import "./reply.css";


const ReplyComponent = (props) => {
   
    const { commentID } = props;
    const [ reply, setReply ] = useState({ reply : ''});
    const [ loading, setLoading ] = useState(false);
    const handleReplyChange = (e) => {
        setReply({[e.target.name] : e.target.value})
    }

    const handleReplySubmit = (e) => {
       e.preventDefault();
       setLoading(true)
       axiosInstance.post(API.ReplyComment + `${commentID}/reply/add/`, { comment : reply.reply})
                    .then(res => {if(res){
                      setReply({reply : ''})
                        props.setAlert("Reply Added Successfully", "success")
                       
                        props.getTaskComments();

                        setLoading(false)
                        
                    }})
                    .catch(err => {if(err) {
                        if(err.response.status === 403){
                            
                          props.setAlert(err.response.data.detail, "error");
                          setReply({reply : ''});
                          setLoading(false)
                        }
                    }})
    }
  return (
    <div>
      <form className="replyComment">
     
        <textarea value={reply.reply} placeholder="Add Reply" name="reply" onChange={handleReplyChange} rows={3} type="text" />
        <div className="replyButton">
          <Button disabled={loading} color='primary' onClick={handleReplySubmit}>Add Reply</Button>
        </div>
      </form>
    </div>
  );
};

export default ReplyComponent;

import React, { useState, useContext } from "react";
import MyItemsContext from "../../containers/MyItems/MyItemsContext";
import { Button, ConferenceDetail } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import ConferenceModal from "../ConferenceModal";
import API from '../../urls/index';


const UpdateActionItemModal = (props) => {
    const { editState } = props;
    const [ loading, setLoading ] = useState(false);
    const [ myItems, getMyActionItems] = useContext(MyItemsContext)
    
    const [ updateData, setUpdateData ] = useState({ title: editState.title, assigned_to :editState.assigned_to, due_date : editState.due_date, status:editState.status  })

    const handleActionItemChange = (e) => {
        setUpdateData({...updateData, [e.target.name] : e.target.value})
    
    }

    const handleActionItemUpdate = (e) => {
        e.preventDefault()
      
       
        const data = { title : updateData.title, assigned_to: updateData.assigned_to, due_date: updateData.due_date, status: updateData.status}
        setLoading(true)
        axiosInstance.put(API.DeleteActionItem + `${editState.id}/update/`, data)
                     .then(res => {if(res.status === 200){
                         getMyActionItems()
                         props.onClick()
                         props.setAlert("Action Item Updated Successfully", "success")
                     }})
                     .catch(err => {
                       props.onClick();
                       props.setAlert(err.response.data.detail, "error");
                       setLoading(false)
                     })


                
    }

  return (
    <>
      <ConferenceDetail label="Title" type="text" name="title" value={updateData.title} onChange={handleActionItemChange} />
      <ConferenceModal label="Due Date" type="date" name="due_date" value={updateData.due_date} onChange={handleActionItemChange}  />
      
      <Button loading={loading} button={props.button} onClick={handleActionItemUpdate} />
    </>
  );
};

export default UpdateActionItemModal;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {  MemberListSnapshot, MemberSnapshotList } from '../../components';


const useStyles = makeStyles({
  table: {
    backgroundColor: "white",
    borderRadius:'3px',
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  },
});

const MembersList = (props) => {
  const classes = useStyles();




  return (
    <React.Fragment>
      <p className={classes.p}>Members</p>

      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className="table-row-data">
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {props.taskRoom ? <MemberSnapshotList /> : <MemberListSnapshot />}
            
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

MembersList.propTypes = {};

export default MembersList;

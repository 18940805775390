import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import { projectList } from "./projectList";
import { notificationList } from "./notificationList";
import companyList from "./company";
import userProfile from "./userProfile";
import newConference from "./newConference";
import cancelSubscription from "./cancelSubscription";

export default combineReducers({
  alert,
  auth,
  projectList,
  notificationList,
  companyList,
  userProfile,
  newConference,
  cancelSubscription,
});

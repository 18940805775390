import React, { useState} from 'react';
import { Button, ConferenceModal, ConferenceDetail } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';


const MyActionItemModal = (props) => {
    const { companyId } = props;
    const [ loading, setLoading ] = useState(false);
    const [ todo, setTodo ] = useState({title:'', due_date:'', status:"incomplete"})

    const handleTodoChange = (event) => {
        setTodo({...todo, [event.target.name] : event.target.value})
    }

    const handleTodoSubmit = (e) => {
       e.preventDefault()
       if(todo.due_date === '' || todo.title === ''){
           props.setAlert("Please Add the required Fields", "error")
       }
       else{
        setLoading(true)
      
        axiosInstance.post(API.MyActionItems + `${companyId}/user/actionitem/`, todo)
                     .then(res => {if(res){
                         props.onClick()
                         setLoading(false)
                         setTodo({title : '', due_date : ''})
                         props.getMyActionItems()
                         props.setAlert("Added You Todo in The List", "success")
                         
                     }
                     
                 })
                     .catch(err => {if(err){
                         props.setAlert("Action not completed", "error")
                     }})
       }

    }

    
    
    return (
        <>
       <ConferenceModal
        htmlFor="date"
        type="date"
        label="Date"
        name="due_date"
        onChange={handleTodoChange}
      />
      <ConferenceDetail type="text" label="Description" name="title" onChange={handleTodoChange} />
        <Button loading={loading} button={props.button} onClick={handleTodoSubmit} />
        </>
    )
}

export default MyActionItemModal;
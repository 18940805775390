import { NEW_CONFERENCE } from "../action_types";

const initialState = {
  date: new Date(),
  start: "",
  meeting_name: "",
  end: "",
  project: null,
  description: "",
  title: "",
  members: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEW_CONFERENCE:
      return {
        ...state,
        date: payload.date,
        start: payload.start,
        meeting_name: payload.meeting_name,
        end: payload.end,
        project: payload.project,
        description: payload.description,
        title: payload.title,
        members: [...payload.members],
      };

    default:
      return state;
  }
}

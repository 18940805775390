import React from "react";

import styles from "./button.module.css";

const Button = (props) => {
  return (
    <button
      type={props.type}
      className={props.cancel ? styles.cancel : styles.signInButton}
      onClick={props.onClick}
      disabled={props.loading}
    >
      {props.loading ? <i className="fa fa-refresh fa-spin"></i> : null}
      {props.button}
    </button>
  );
};

export default Button;

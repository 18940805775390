import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axiosInstance from '../../utils/axiosConfig';
import { getprojectlist } from '../../actions/projectList';
import API from '../../urls/index';
import { setAlert } from '../../actions/alert';

import ArrowBack from '@material-ui/icons/ArrowBack';
import styles from './editmodal.module.css';
import { NascentImage, Button } from '../../components';
import application from '../../application.svg';



const EditModal = (props) => {
  


  const [company_id] = props.companyId

  const { id, title, description } = (props.editState);
  const [ loading, setLoading ] = useState(false);
   const [ newProject, setNewproject ] = useState({title : title, description : description});
   const handleNewProjectChange = (event) => {
       setNewproject({...newProject,[event.target.name] : event.target.value})
   }
   
  
   const handleNewProjectSubmit = async () => {  
     setLoading(true)
     await axiosInstance.put(API.ProjectEdit + `${id}/`, newProject)
                   .then(res => { if(res.data) { 
                       setLoading(true);   
                       setNewproject({title : '', description : ''})        
                       props.getprojectlist(company_id.id)
                       props.setAlert("Project Edited Successfully", "success")
                       props.backToHome()
                   }})
                   .catch(err => {
                       setLoading(true)
                       setNewproject({title : '', description : ''}) 
                    props.setAlert(err.response.data.detail, "error");
                    props.backToHome()
                   })
                   
   }

   useEffect(() => {
       props.getprojectlist()
   }, [])
    return (
        <>
         
          <div className={styles.newProjectWrapper}>
              <Link to='/' onClick={props.backToHome}><ArrowBack/></Link>
          </div>
          <div className={styles.innerProjectWrapper}>
              <div className={styles.innerProjectForm}>
                  <h2 className={styles.newProjectHeader}>New Project</h2>
                  <form onSubmit={props.backToHome}>
                      <div className={styles.projectForm}>
                          <label htmlFor="projectName">Project Name</label>
                          <input type="text" name='title' onChange={handleNewProjectChange} value={newProject.title} />
                      </div>
                      <div className={styles.projectForm}>
                          <label htmlFor="projectDescription">Project Description</label>
                          <textarea type="text" rows="4" name='description' value={newProject.description} onChange={handleNewProjectChange} />
                      </div>
                      <Button loading={loading} onClick = {handleNewProjectSubmit} button="Edit Project" />
                  </form>
              </div>
              <NascentImage application={application} />      
          </div>
          
        </>
    )
}

const mapStateToProps = (state) => {
    return { companyId : state.auth.data.companies}
}

export default connect(mapStateToProps, { getprojectlist, setAlert })(EditModal);
import API from "../urls/index";
import axiosInstance from "../utils/axiosConfig";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
} from "../action_types";
import { setAlert } from "./alert";

export const login = (username, password) => (dispatch) => {
  axiosInstance
    .post(API.SignIn, { username, password })
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Logged in Successfully", "success"));
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAIL, payload: err });
      dispatch(setAlert("Invalid Username or password", "error"));
    });
};

export const logout = () => (dispatch) => {
  dispatch(setAlert("Logout success", "success"));
  dispatch({
    type: LOGOUT,
  });
};

export const refreshAuthToken = (payload) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: payload,
  });
};

import React, { useEffect, useState } from 'react';
import { ActionItems } from '../../containers';
import API from '../../urls/index';
import axiosInstance from '../../utils/axiosConfig';
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import ProjectActionItemContext from './ProjectActionItemContext';

const ProjectActionItems = (props) => {
    const [ loader, showLoader, hideLoader ] = useFullPageLoader();
    const projectID = props.computedMatch.params.id;
    const projectTitle = props.computedMatch.params.title;
    const [ actionItemsList, setActionItemsList ] = useState(null)


    useEffect(() => {
       getProjectActionItems()
    }, [])


    const getProjectActionItems = () => {
        showLoader()
        axiosInstance.get(API.ProjectActionItem + `${projectID}/actionitem/`)
                     .then(res => { 
                         hideLoader()
                         setActionItemsList(res.data.results);
                        })
                     .catch(err => console.log(err))
    }


    
    return (
        <ProjectActionItemContext.Provider value={[actionItemsList, loader, getProjectActionItems]}>
            <ActionItems projectTitle={projectTitle} projectID={projectID} getProjectActionItems={getProjectActionItems} />
        </ProjectActionItemContext.Provider>
        
    )
   
}

export default ProjectActionItems;
import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import ProjectSnapshotContext from '../../containers/ProjectSnapshot/ProjectSnapshotContext';
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";

import { Visibility } from "@material-ui/icons";


const useStyles = makeStyles({
  table: {
    backgroundColor: "white",
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  },
});

const DocumentSnapshot = (props) => {
  const classes = useStyles();
  const [ snapshotView , loading ] = useContext(ProjectSnapshotContext);
  
  return (
    (!snapshotView ? <TableRow><TableCell><i className="fa fa-refresh fa-spin"></i>Loading...</TableCell></TableRow> : snapshotView.documents.map((document, index) => (
        <TableRow key={document.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{document.name}</TableCell>
        <TableCell><a href={`http://nascent.link${document.document_file.map(file => file.file)}`} target="_blank"><Visibility /></a></TableCell>
        
      </TableRow>
      )) )
  )
}

export default DocumentSnapshot;
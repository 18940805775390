import React, { useState } from "react";
import { UserModalInput, Button } from "../../components";

import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import axiosInstance from "../../utils/axiosConfig";
import API from '../../urls/index';


const AddUserModal = (props) => {
  const { company } = props;
  const [companyId] = company;

  const [loading, setLoading] = useState(false)
  const [userEmail, setUserEmail] = useState(false)
  const [userContact, setUserContact] = useState(false)
  const [user, setUser] = useState({ email: '' });

  const handleUserChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })

    if (user.email !== '') {
      setUserEmail(false)
    }
    if (user.contact !== '') {
      setUserContact(false)
    }

  }

  const handleUserSubmit = (event) => {
    event.preventDefault();
    if (user.email === '') {
      setUserEmail(true)
    }

    else if (user.contact === '') {
      setUserContact(true)
    }

    else {
      setLoading(true)
      axiosInstance.post(API.CollabReq + `${companyId.id}/collabreq/add`, user)
        .then(res => {
          if (res.data) {
            setLoading(false)
            props.setAlert('User Request Sent Successfully', 'success')
            props.onClick()
            setUser({ email: '' })
          }
        })
        .catch(err => {
          if (err.response.status === 403) {
            props.setAlert(err.response.data.detail, "error");
            setLoading(false);
            props.onClick();
          }
          else {
            props.setAlert("Something Went Wrong, Try Again later", "error");
            setLoading(false)
            props.onClick()
          }
        });
    }

  }


  return (
    <>
      <UserModalInput htmlFor="name" name="name" type="text" label="Name" />

      <UserModalInput
        htmlFor="email"
        validate={userEmail}
        type="text"
        name="email"
        label="Email"
        value={user.email}
        onChange={handleUserChange}
      />
      <Button button={props.button} onClick={handleUserSubmit} loading={loading} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { company: state.auth.data.companies }
}

export default connect(mapStateToProps, { setAlert })(AddUserModal)

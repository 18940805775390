import React, { useState } from 'react';
import { DocumentModal, Button, ProjectUsers } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';


const ProjectActionItemEdit = (props) => {
    const { projectID } = props;

    const { editActionItem } = props;



    const [actionItems, setActionItems ] = useState({ title: editActionItem.title, due_date: editActionItem.due_date, status : editActionItem.status })
    const [ assigned, setAssigned ] = useState({ assigned :   [{user : editActionItem.assigned_to}] })

    const handleProjectActionItemsChange = (e) => {
        setActionItems({...actionItems,[e.target.name] : e.target.value})
    }

    const handleMembersFieldChange = (value) => {
       setAssigned({assigned : value})
       
    }




    const handleProjectActionItemsSubmit = (e) => {
        e.preventDefault();
        
          const [ assignedTo ] = assigned.assigned
        const data = {
            title: actionItems.title,
            due_date : actionItems.due_date,
            assigned_to : assignedTo.user.id,
            status : actionItems.status
        }


     
        axiosInstance.put(API.EditprojectActionItem + `${editActionItem.id}/update/`,data)
                     .then(res => { if(res.status === 200){
                         props.setAlert("Successfully edited action item", "success")
                        props.getProjectActionItems()
                        props.onClick()
                        
                     }})
                     .catch(err => {
                        
                         props.onClick()
                         props.setAlert(err.response.data.detail, "error")
                        })



                    
    }

    return (
        <>
         <ProjectUsers label="Assigned To" proID={projectID} value={assigned.assigned} handleMembersFieldChange={handleMembersFieldChange} />
          <DocumentModal label="Description" value={actionItems.title} type="text" name="title" onChange={handleProjectActionItemsChange} />
       
          <DocumentModal name="due_date" value={actionItems.due_date} label="Due Date" type="date"  onChange={handleProjectActionItemsChange} />
          <Button button={props.button} onClick={handleProjectActionItemsSubmit} />
        </>
    )
}

export default ProjectActionItemEdit;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Member, AdminHeader } from '../../components';
import { logout } from '../../actions/auth';
import API from '../../urls/index';
import axiosInstance from '../../utils/axiosConfig';

export const MessageContext = React.createContext();

const ContactMessages = (props) => {
  const history = useHistory()
  const [messages, setMessages] = useState([])

  useEffect(() => {
    axiosInstance.get(API.MessageList)
      .then(res => setMessages(res.data.results))
      .catch(err => console.log(err))
  }, [])

  const handleLogoutSubmit = () => {
    props.logout();
    history.push('/login')
  }

  return (
    <>
      <MessageContext.Provider value={messages}>
        <AdminHeader />
        <Grid container style={{ marginTop: '70px' }}>
          <Grid item style={{ width: '90%', margin: 'auto' }}>

            <Member td1="S.No" td2="Name" td3="Email" td4="Phone Number" td5="Message" td6="Action" ContactMessages="Contact Message" />


          </Grid>
        </Grid>
      </MessageContext.Provider>
    </>
  )
}

export default connect(null, { logout })(ContactMessages);
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import styles from './search.module.css';

const Search = (props) => {
    return(
        <div className={styles.searchOuterWrapper}>
           <form>
              <SearchIcon style={{color:"black"}}/>   
              <input placeholder="Search" onChange={props.onChange} />  
           </form>
        </div>
    )
}

export default Search;
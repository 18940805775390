import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import axiosInstance from "../../utils/axiosConfig";
import { Grid, Button, Avatar } from "@material-ui/core";

import {
  NotificationBar,
  Modal,
  CustomizedTab,
  Reply,
  ChatForm,
} from "../../components";

import API from "../../urls/index.js";
import { formatDate } from "../../utils/dateTimeUtility";
import styles from "./conversationtask.module.css";
import ReplyComponent from "../ReplyComponent";

const TaskConversation = (props) => {
  const taskTitle = props.computedMatch.params.tasktitle;
  const taskID = props.computedMatch.params.taskId;
  const proID = props.computedMatch.params.id;
  const [comment, setComment] = useState({ comment: "" });
  const [taskComment, setTaskComment] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [ID, setID] = useState(null);
  const [reply, setReply] = useState(false);
  const [editID, setEditID] = useState(null);
  const [editComment, setEditComment] = useState(false);
  const [commentValue, setCommentValue] = useState({
    id: "",
    comment: comment,
  });
  const [commentID, setCommentID] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTaskComments();
  }, []);

  const getTaskComments = () => {
    axiosInstance
      .get(API.DeleteTask + `${taskID}/comment/`)
      .then((res) => {
        setTaskComment(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  const handleCommentChange = (e) => {
    setComment({ [e.target.name]: e.target.value });
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    if (comment.comment === "") {
      props.setAlert("Add Your comment first", "error");
    } else if (comment.comment !== "") {
      setLoading(true);
      axiosInstance
        .post(API.DeleteTask + `${taskID}/comment/add/`, comment)
        .then((res) => {
          if (res.status === 201) {
            props.setAlert("Comment Added Successfully", "success");
            getTaskComments();
            setComment({ comment: "" });
            setLoading(false);
          }
        })
        .catch((err) => {
          props.setAlert("Something went wrong", "error");
          setComment({ comment: "" });
          setLoading(false);
        });
    }
  };

  const commentDelete = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const commentDeleteConfirm = (ID) => {
    setLoading(true);
    if (ID) {
      axiosInstance
        .delete(API.DeleteTaskComment + `${ID}/`)
        .then((res) => {
          if (res) {
            setOpenDelete(!openDelete);
            props.setAlert("Comment deleted successfully", "success");
            getTaskComments();
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(err.response.data.detail, "error");
            setOpenDelete(!openDelete);
            setLoading(false);
          }
        });
    }
  };

  const commentEdit = (id, comment) => {
    setEditID(id);
    setEditComment(!editComment);
    setCommentValue({ id: id, comment: comment });
  };

  const handleCommentEditChange = (e) => {
    setCommentValue({ [e.target.name]: e.target.value });
  };

  const handleCommentEditSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if(commentValue.comment === ''){
      props.setAlert("Please add your reply first", "error");
      setLoading(false);
    }
    else{
      axiosInstance
      .put(API.DeleteTaskComment + `${editID}/`, {
        comment: commentValue.comment,
      })
      .then((res) => {
        if (res) {
          props.setAlert("Comment Editted Successfully", "success");
          getTaskComments();
          setCommentValue({ comment: "" });
          setEditComment(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        props.setAlert("Cannot Edit task comment try again later", "error");
        setTaskComment({ comment: "" });
        setEditComment(false);
        setLoading(false);
      });
    }
  };

  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
            done="70"
            taskRoom="TaskRoom"
            proID={proID}
            taskTitle={taskTitle}
            taskID={taskID}
          />
        }
        topButton="button"
      />

      <main>
        {/* <div className={styles.conversationOuterWrapper}>
                <div className={styles.conversationInnerWrapper}>
                  <div className={styles.userImage}>
                    <Avatar />
                  </div>
                  <div className={styles.conversationMessage}>
                    <p className={styles.message}>hello world</p>
                  </div>
                  <p className={styles.time}>4:21 PM</p>
                </div>
              </div>
              <Hidden mdDown>
                <div className={styles.conversationOuterWrapper}>
                  <div
                    className={styles.conversationInnerWrapper}
                    style={{ marginLeft: "50%" }}
                  >
                    <p className={styles.timeTwo}>4:21 PM</p>
                    <div className={styles.conversationMessage}>
                      <p className={styles.message}>
                        hello world welcome to the world of react and redux, we
                        will discuss some important aspects of react and redux
                      </p>
                    </div>
                    <div className={styles.userImage}>
                      <Avatar />
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <div className={styles.conversationOuterWrapper}>
                  <div className={styles.conversationInnerWrapper}>
                    <p className={styles.timeTwo}>4:21 PM</p>
                    <div className={styles.conversationMessage}>
                      <p className={styles.message}>
                        hello world welcome to the world of react and redux, we
                        will discuss some important aspects of react and redux
                      </p>
                    </div>
                    <div className={styles.userImage}>
                      <Avatar />
                    </div>
                  </div>
                </div>
              </Hidden> */}

        {/* <ConversationMessageList /> */}

        <div className={styles.taskOuterWrapper}>
          <div className={styles.taskInnerWrapper}>
            {!taskComment ? (
              <div>Loading ...</div>
            ) : taskComment.length === 0 ? (
              <div>No comments for this task Add one!</div>
            ) : (
              taskComment.map((comment) => (
                <Grid key={comment.id} container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar
                      style={{ height: "50px", width: "50px" }}
                      variant="square"
                      alt="Remy Sharp"
                      src={`http://nascent.link${comment.commented_by.photo}`}
                    />
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <p
                      style={{
                        margin: 0,
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {comment.commented_by.first_name}{" "}
                      {comment.commented_by.last_name}{" "}
                      <span className={styles.commentTime}>
                        {formatDate(comment.created_date)}
                      </span>
                    </p>

                    <p style={{ textAlign: "left" }}>{comment.comment}</p>

                    <span
                      onClick={() => {
                        setCommentID(comment.id);
                        setReply(!reply);
                      }}
                      style={{ color: "#009be5", textDecoration: "none", cursor:"pointer" }}
                    >
                      <span className={styles.replyLength}>
                        {comment.reply.length === 0
                          ? null
                          : comment.reply.length}
                      </span>
                      Reply
                    </span>
                    {props.auth.username === comment.commented_by.username ? (
                      <span
                        onClick={() => {
                          commentEdit(comment.id, comment.comment);
                        }}
                        style={{
                          color: "#009be5",
                          textDecoration: "none",
                          paddingLeft: "10px",
                          cursor:"pointer"
                        }}
                      >
                        Edit
                      </span>
                    ) : null}
                    {props.auth.username === comment.commented_by.username ? (
                      <span
                        onClick={() => {
                          commentDelete(comment.id);
                        }}
                        style={{
                          color: "red",
                          textDecoration: "none",
                          paddingLeft: "10px",
                          cursor:"pointer"
                        }}
                      >
                        Delete
                      </span>
                    ) : null}

                    <br />

                    {reply && commentID === comment.id
                      ? comment.reply.map((rep) => (
                          <Reply
                            key={rep.id}
                            commented_by={rep.commented_by.first_name}
                            last_name={rep.commented_by.last_name}
                            comment={rep.comment}
                            time={formatDate(rep.created_date)}
                            photo={rep.commented_by.photo}
                          />
                        ))
                      : null}
                    {reply && commentID === comment.id ? (
                      <ReplyComponent
                        setAlert={setAlert}
                        getTaskComments={getTaskComments}
                        commentID={commentID}
                      />
                    ) : null}
                    <hr />
                  </Grid>
                </Grid>
              ))
            )}
          </div>
          <div style={{ width: "80%", margin: " auto" }}>
            <form className="commentForm">
              <textarea
                style={{ padding: "10px" }}
                className="commentSection"
                type="text"
                rows="5"
                value={editComment ? commentValue.comment : comment.comment}
                name="comment"
                onChange={
                  editComment ? handleCommentEditChange : handleCommentChange
                }
                placeholder="Add Comment for this task"
              ></textarea>
              <div className={styles.commentButton}>
                <Button
                  color="secondary"
                  onClick={() => {
                    editComment ? setCommentValue({ comment : ''}) : setComment({ comment: "" });
                    
                  }}
                >
                  Clear Text
                </Button>

                <Button
                  disabled={loading}
                  color="primary"
                  onClick={
                    editComment ? handleCommentEditSubmit : handleCommentSubmit
                  }
                >
                  Add Comment
                </Button>
              </div>
              <br />
            </form>
          </div>
        </div>

        <div className={styles.chatForm}>{/* <ChatForm /> */}</div>
      </main>
      <Modal
        openPop={openDelete}
        onClick={() => setOpenDelete(!openDelete)}
        backdropClicked={() => setOpenDelete(!openDelete)}
        deleteBar="Delete Bar"
        title="Confirm Delete"
        loading={loading}
        ConfirmDeleteData={() => commentDeleteConfirm(ID)}
        closeDialog={() => setOpenDelete(!openDelete)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.auth.data };
};

export default connect(mapStateToProps, { setAlert })(
  withRouter(TaskConversation)
);

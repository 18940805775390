import React, { useState, useContext } from "react";
import { DocumentModal, Button } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import ProjectDocumentContext from '../../containers/ProjectDocument/ProjectDocumentContext';
import API from "../../urls/index";

const DocumentEditModal = (props) => {
  const { editState } = props;
  const [ projectDocumentList, getProjectDocumentList ] = useContext(ProjectDocumentContext);
  const [updateData, setUpdateData] = useState({ name: editState.name });
  const [ loading, setLoading ] = useState(false);
  const handleDocumentEditUpdate = (e) => {
    setUpdateData({ [e.target.name]: e.target.value });
  };

  const handleDocumentEditSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    axiosInstance
      .put(API.UpdateDocument + `${editState.id}/`, updateData)
      .then((res) => {
        if (res.status === 200) {
          props.setAlert("Document Name Changed Successfully", "success");
          getProjectDocumentList();
          props.onClick()
        }
      })
      .catch((err) => {
        props.setAlert("Error Updating name", "error");
        setLoading(false);
        props.onClick()
      });
  };
  return (
    <>
      <DocumentModal
        type="text"
        name="name"
        value={updateData.name}
        label="Name of Document"
        value={updateData.name}
        onChange={handleDocumentEditUpdate}
      />
      <Button loading={loading} button={props.button} onClick={handleDocumentEditSubmit} />
    </>
  );
};

export default DocumentEditModal;

import React, { useEffect, useContext, useState, useRef } from "react";
import { connect } from "react-redux";
import { TableCell } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TableRow from "@material-ui/core/TableRow";
import { setAlert } from "../../actions/alert";
import Paper from "@material-ui/core/Paper";

import {
  SimpleMenu,
  Modal,
 
} from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import ProjectMembersContext from "../../containers/ProjectMember/ProjectMembersContext";


const ProjectMembersList = (props) => {
  const { projectID } = props;

  const [projectMembers, loader] = useContext(ProjectMembersContext);
  const [ prevRole, setPrevRole ] = useState(null);
  const [ role, setRole ] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [ID, setID] = useState();
  const [ roleID, setRoleID ] = useState(null);
  const [ loading, setLoading ] = useState(false)

  const getRole = (role) => {
    if (role === "PM") {
      return "Project Manager";
    } else if (role === "PMem") {
      return "Project Member";
    } else {
      return "Project Client";
    }
  };

  const deleteProjectMember = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const setProjectRole = (id, role) => {

    setRoleID(id);
    
    setPrevRole({role : role});
    setRole({ role : role});
  }

 

  const handleMemberRoleChange = (e) => {
   setRole({[e.target.name] : e.target.value})
  }

  const handleMemberRoleSubmit = () => {
    if(prevRole.role === role.role){
      setRoleID(null)
    }
    else{
      axiosInstance.patch(API.RemoveProjectMember + `${roleID}/update/`, role)
                  .then(res => {if(res){
                      props.getProjectMembersList();
                      props.setAlert("Member updated Successfully", "success");
                      setRoleID(null)
                  }})
                  .catch(err => {
                    props.setAlert(err.response.data.detail, "error");
                    setRoleID(null)
                  })
    }
  }

  const deleteProjectMemberConfirm = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.RemoveProjectMember + `${ID}/update/`)
      .then((res) => {
        if (res) {
          setOpenDelete(!openDelete);
          props.setAlert("Member Deleted SuccessFully", "success");
          props.getProjectMembersList();
          setLoading(false)
        }
      })
      .catch((err) => {
        setOpenDelete(!openDelete);
        props.setAlert(err.response.data.detail, "error");
        setLoading(false)
      });
  };

  const useOutsideClickRef = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleMemberRoleSubmit();
          setRoleID(null);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, role]);
  };



  const wrapperRef = useRef(null);
  useOutsideClickRef(wrapperRef);

  

  return projectMembers ? (
    projectMembers.map((member, index) => (
      <React.Fragment key={member.id}>
        <Hidden smDown>
          <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}>
            <TableCell>{index + 1}</TableCell>
            
            <TableCell align="left">
              {member.user.first_name} {member.user.last_name}
            </TableCell>
            <TableCell align="left" style={{cursor:"pointer"}}>
              {(roleID === member.id) ? (
                <select ref={wrapperRef} name="role" onChange={handleMemberRoleChange}>
                  <option value="PM" selected={prevRole.role === "PM" ? true : false}>Project Manager</option>
                  <option value="PMem" selected={prevRole.role === "PMem" ? true : false}>Project Member</option>
                  <option value="PC" selected={prevRole.role === "PC" ? true : false}>Project Client</option>
                </select>
              ) : 
                <div onClick={() => setProjectRole(member.id, member.role)}>
                  {getRole(member.role)} <EditOutlinedIcon fontSize = "small" />
                </div>
              }
              
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              {member.user.username}
              
            </TableCell>
           
            <TableCell align="left">{member.user.contact}</TableCell>
            <TableCell align="left">
              <SimpleMenu
                delete="Delete"
                deleteProject={() => deleteProjectMember(member.id)}
              />
            </TableCell>
          </TableRow>
        </Hidden>

        <Hidden mdUp>
          <TableRow component={Paper}>
            <TableCell
              align="left"
              style={{
                width: "40%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>S.No.</p>
              <p>Name</p>
              <p>Project Role</p>
              <p>Email</p>
              <p>Phone Number</p>
            </TableCell>
            <TableCell
              align="left"
              style={{
                width: "50%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>{index + 1}</p>
              <p>{member.user.first_name} {member.user.last_name}</p>
              {(roleID === member.id) ? (
                <select ref={wrapperRef} name="role" onChange={handleMemberRoleChange}>
                  <option value="PM" selected={prevRole.role === "PM" ? true : false}>Project Manager</option>
                  <option value="PMem" selected={prevRole.role === "PMem" ? true : false}>Project Member</option>
                  <option value="PC" selected={prevRole.role === "PC" ? true : false}>Project Client</option>
                </select>
              ) : 
                <p onClick={() => setProjectRole(member.id, member.role)}>
                  {getRole(member.role)} 
                </p>
              }
              <p>{member.user.username}</p>
              <p>{member.user.contact}</p>
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              <SimpleMenu edit="Edit" delete="Delete" />
            </TableCell>
          </TableRow>
        </Hidden>
        <Modal
          openPop={openDelete}
          onClick={() => setOpenDelete(!openDelete)}
          backdropClicked={() => setOpenDelete(!openDelete)}
          deleteBar="Delete Bar"
          title="Confirm Delete"
          loading={loading}
          ConfirmDeleteData={() => deleteProjectMemberConfirm(ID)}
          closeDialog={() => setOpenDelete(!openDelete)}
        />
      </React.Fragment>
    ))
  ) : (
    <div>{loader}</div>
  );
};

export default connect(null, { setAlert })(ProjectMembersList);

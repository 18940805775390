const styles = (theme) => ({
  sidebarWidth: {
    width: "14rem !important",
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    fontSize: "0.65rem",
    fontWeight: "800",
  },
  item: {
   
    paddingTop: 5,
    paddingBottom: 5,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sidbarBrand: {
    padding: "1.5rem 1rem",
    height: "4.375rem",
  },
  firebase: {
    fontSize: "1rem",
    color: theme.palette.common.white,
    fontWeight: "400",
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "1rem",
    fontFamily: "Arial",
    fontWeight: 400,
    color: "#fff",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
    color: "#fff",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  socialMediaIcon:{
    display:'flex',
    color:'white',
    position:'absolute',
    bottom:'90px',
    alignItems:'center',
    justifyContent:'center',
    width:'100%'
  },
  iconpart:{
    padding:'5px',
  },
  copywright:{
    position:'absolute',
    bottom:'30px',
    color:'white',
    display:'flex',
    justifyContent:'center',
    width:'100%',
    fontSize:'smaller'

  }
});

export { styles };

import React, { useState, useContext, useEffect } from "react";
import { connect } from 'react-redux';
import { Paper, Avatar } from "@material-ui/core";
import { setAlert } from '../../actions/alert';
import "./taskslider.css";
import { Button } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import { SimpleMenu, Modal } from '../../components';
import API from "../../urls/index";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useFullPageLoader from '../../hooks/UseFullPageLoader';

const TaskSlider = (props) => {
  const [ loader, showLoader, hideLoader ] = useFullPageLoader()
  const [ comment, setComment ] = useState({comment : ''})
  const [taskComment, setTaskComment] = useState(null);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ ID, setID ] = useState(null)

  useEffect(() => {
    getTaskComments()
  }, []);

  const getTaskComments = () => {
      showLoader()
    axiosInstance
    .get(API.DeleteTask + `${props.value}/comment/`)
    .then((res) => { 
        hideLoader()
        setTaskComment(res.data.results)
        
    })
    .catch((err) => console.log(err));
  }


  const handleCommentChange = (e) => {
     setComment({[e.target.name] : e.target.value})


  }

  const handleCommentSubmit = (e) => {
      e.preventDefault()

      if(comment.comment === ''){
          props.setAlert("Add Your comment first", "error")
      }
      else if(comment.comment !== ''){
        axiosInstance.post(API.DeleteTask + `${props.value}/comment/add/`, comment)
        .then(res => { if(res.status === 201){
            props.setAlert("Comment Added Successfully", "success")
            getTaskComments()
            setComment({ comment : ''})
        }})
        .catch(err => {
          props.setAlert("Something went wrong", "error");
          hideLoader()
        })
      }

  }

  const deleteTask = (id) => {
      setOpenDelete(!openDelete)
      setID(id);
  }

  const DeleteTaskConfirm = (ID) => {
   

        axiosInstance.delete(API.DeleteTaskComment + `${ID}/delete/`)
                     .then(res => { if(res.status === 204){
                         props.setAlert("Successfully Deleted Task", "success")
                         setOpenDelete(!openDelete)
                         getTaskComments()
                     }})
                     .catch(err => {if(err){
                         props.setAlert("Error Deleting Task", "error")
                     }})

  }

  return (
    <div className="sliderWrapper">
      <div className="slider">
        <div className="sliderInnerWrapper">
          <div className="comments">
              <ArrowForwardIcon className="frontArrow" onClick = {() => props.showDrawer()} />
            {!taskComment ? (
              <div>{loader}</div>
            ) : taskComment.length === 0 ? (
              <div>No Comment for this task!! Add Your first Comment</div>
            ) : (
              taskComment.map(comment => (
                <div className="commentInfo">
                <div className="commentAvatar">
                  <Avatar src={`http://nascent.link${comment.commented_by.photo}`} />
                </div>
                <div className="commentsBy">
                  <div className="commentedBy">
                    <h6 >{comment.commented_by.username} <small className="date">{comment.created_date}</small> </h6>
                    <div><SimpleMenu delete="Delete" deleteProject={() => 
                        
                        deleteTask(comment.id)
                    } /></div>
                  </div>
                  
                  <p>
                    {comment.comment}
                  </p>
                </div>
              </div>
              ))
            )}
          </div>

          <form className="commentForm">
            <label>Add Comment For this Task</label>
            <textarea
              className="commentSection"
              type="text"
              rows="5"
              name="comment"
              value={comment.comment}
              onChange={handleCommentChange}
              placeholder="Add Comment"
            ></textarea>
            {/* <div className="commentButton">
              <Button button="Add comment" onClick={handleCommentSubmit} />
            </div>
            <br /> */}
          </form>
          
        </div>
      </div>
      <Modal
      openPop={openDelete}
      onClick={() => setOpenDelete(!openDelete)}
      backdropClicked={() => setOpenDelete(!openDelete)}
      deleteBar="Delete Bar"
      title="Confirm Delete"
      ConfirmDeleteData={() => DeleteTaskConfirm(ID)}
      closeDialog={() => setOpenDelete(!openDelete)}
       />
    </div>
  );
};

export default connect(null, { setAlert })(TaskSlider);

import { ActionShoppingBasket } from "material-ui/svg-icons";
import { apiEndPoints } from "../utils/apiConfig";

let API = {};

export default API = {
  SignIn: apiEndPoints.baseUrl + "token/",
  // TestRequest: apiEndPoints.baseUrl + "/user/info/",
  // CreateNewProject: apiEndPoints.baseUrl + "project/new/",
  // DeleteProject: apiEndPoints.baseUrl + "project/new/",

  // CompanyList : apiEndPoints.baseUrl + 'list-company/',

  // USERS
  GetUserList: apiEndPoints.baseUrl + "user/company/",
  SaveNewConference: apiEndPoints.baseUrl + "videomeetings/",
  GetConferenceList: apiEndPoints.baseUrl + "videomeetings/",
  GetUpcomingConference: apiEndPoints.baseUrl + "user/company/",
  RemoveCompanyMember: apiEndPoints.baseUrl + "user/company/",

  //  Project
  AddProject: apiEndPoints.baseUrl + "user/company/",
  ProjectList: apiEndPoints.baseUrl + "user/company/",
  ProjectEdit: apiEndPoints.baseUrl + "project/",
  ProjectDelete: apiEndPoints.baseUrl + "project/",
  CreateNewProject: apiEndPoints.baseUrl + "project/new",

  //Project Task

  TestRequest: apiEndPoints.baseUrl + "user/info",

  DeleteProject: apiEndPoints.baseUrl + "project/new",

  CompanyList: apiEndPoints.baseUrl + "company/",
  MessageList: apiEndPoints.baseUrl + "message/",
  DeleteMessage: apiEndPoints.baseUrl + "message/",

  CompanyMembers: apiEndPoints.baseUrl + "user/company/",
  CollabReq: apiEndPoints.baseUrl + "user/company/",
  AddTask: apiEndPoints.baseUrl + "project/",
  AddDocument: apiEndPoints.baseUrl + "project/",
  AcceptCollabReq: apiEndPoints.baseUrl + "collabreq/",
  ProjectTaskList: apiEndPoints.baseUrl + "project/",
  RequestPasswordChange: apiEndPoints.baseUrl + "user/password/change/",
  ProjectDocumentList: apiEndPoints.baseUrl + "project/",
  CompanyDetails: apiEndPoints.baseUrl + "company/",
  UserProfileUpdate: apiEndPoints.baseUrl + "userprofile/",
  GetUserProfileInfo: apiEndPoints.baseUrl + "userprofile/",
  ProjectDocumentDelete: apiEndPoints.baseUrl + "document/",
  ResetPassword: apiEndPoints.baseUrl + "password_reset/",
  ResetPasswordConfirm: apiEndPoints.baseUrl + "password_reset/confirm/",
  ProjectActionItem: apiEndPoints.baseUrl + "project/",
  CompanyFilter: apiEndPoints.baseUrl + "company/filter/",
  DeleteCompany: apiEndPoints.baseUrl + "company/",

  AddProjectMember: apiEndPoints.baseUrl + "project/",
  ListProjectMembers: apiEndPoints.baseUrl + "project/",
  RemoveProjectMember: apiEndPoints.baseUrl + "member/",

  UserRoleInProject: apiEndPoints.baseUrl + "project/",

  // Task Section
  TaskMembersList: apiEndPoints.baseUrl + "task/",
  AddTaskMember: apiEndPoints.baseUrl + "task/",
  RemoveTaskMember: apiEndPoints.baseUrl + "task/",
  AddTaskdocument: apiEndPoints.baseUrl + "task/",
  ListTaskDocument: apiEndPoints.baseUrl + "task/",
  DeleteTaskDocument: apiEndPoints.baseUrl + "task/",

  AddProjectActionItems: apiEndPoints.baseUrl + "project/",
  DeleteActionItem: apiEndPoints.baseUrl + "actionitem/",
  AppointAdmin: apiEndPoints.baseUrl + "user/company/",
  ListCompanyAdmins: apiEndPoints.baseUrl + "user/company/",
  TaskDocumentStatusUpdate: apiEndPoints.baseUrl + "document/file/",
  DeleteTask: apiEndPoints.baseUrl + "task/",

  SingleTaskDocument: apiEndPoints.baseUrl + "document/",
  //Task ActionItem
  ListTaskActionItems: apiEndPoints.baseUrl + "task/",
  AddTaskActionItem: apiEndPoints.baseUrl + "task/",
  DeleteTaskActionItem: apiEndPoints.baseUrl + "actionitem/",
  UpdateDocument: apiEndPoints.baseUrl + "document/",
  UpdateTask: apiEndPoints.baseUrl + "project/task/",

  //MyActionItems

  MyActionItems: apiEndPoints.baseUrl + "user/company/",
  EditprojectActionItem: apiEndPoints.baseUrl + "actionitem/",
  DeleteMyActionItem: apiEndPoints.baseUrl + "actionitem/",
  UpdateTask: apiEndPoints.baseUrl + "project/task/",

  //Notifications
  Notifications: apiEndPoints.baseUrl + "user/company/",
  DeleteTaskComment: apiEndPoints.baseUrl + "task/comment/",

  //Progress bar
  ProgressBar: apiEndPoints.baseUrl + "project/",

  //Snapshot
  ProjectSnapshot: apiEndPoints.baseUrl + "project/",

  // snapshot task
  TaskSnapshot: apiEndPoints.baseUrl + "task/",

  // All Documents
  AllDocument: apiEndPoints.baseUrl + "project/",

  //CompanyUpdate
  CompanyUpdate: apiEndPoints.baseUrl + "user/company/",

  // Conference
  DeleteConference: apiEndPoints.baseUrl + "videomeetings/",

  //Update Notification
  UpdateNotification: apiEndPoints.baseUrl + "user/company/notification/",

  //Reply comment

  ReplyComment: apiEndPoints.baseUrl + "task/comment/",

  GetUserCompanyInfo: apiEndPoints.baseUrl + "user/company/",

  GetSingleProject: apiEndPoints.baseUrl + "project/",
};

import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import styles from "./login.module.css";
import SVGImage from "../../components/SVGImage";
import { setAlert } from "../../actions/alert";
import Pro from "../../pro.svg";
import { login } from "../../actions/auth";
import { logout } from "../../actions/auth";

const Login = ({ login, isAuthenticated, info, error, setAlert, logout }) => {
  const history = useHistory();

  const location = useLocation();
  const registration = location.search;

  const [showPassword, setShowPassword] = useState(false);

  const [loginInfo, setLoginInfo] = useState({ username: "", password: "" });
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginChange = (event) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    login(loginInfo.username, loginInfo.password);
  };

  if (isAuthenticated && info.company_head && !info.payment_status) {
    localStorage.clear();
    window.location.href = `http://nascent.link/payment/?email=${info.email}`;
  } else {
    if (isAuthenticated && info.companies.length > 1) {
      return (window.location.href = "/multiple-company");
    } else {
      if (isAuthenticated && info.superuser) {
        return (window.location.href = "/details");
      }

      if (isAuthenticated && !info.superuser) {
        return (window.location.href = "/");
      }
    }
  }

  return (
    <>
      <div className={styles.loginPage}>
        <SVGImage pro={Pro} />
        <div className={styles.loginForm}>
          <div className={styles.loginFormText}>
            {registration !== "" ? (
              <div className={styles.registration}>
                Registration Complete! Please Login To Continue
              </div>
            ) : null}
            <h1 className={styles.loginFormHeader}>Welcome Back</h1>
            <p>Sign in with Your Nascent ID</p>
            <form onSubmit={handleLoginSubmit}>
              <div className={styles.toggle}>
                <input
                  type="text"
                  name="username"
                  placeholder="Your Email"
                  onChange={handleLoginChange}
                />
              </div>
              <div className={styles.toggle}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Your Password"
                  onChange={handleLoginChange}
                />
                <span
                  className={
                    registration !== ""
                      ? styles.registrationToggle
                      : styles.togglePassword
                  }
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </span>
              </div>
              <button type="submit" className={styles.signInButton}>
                Sign In
              </button>
            </form>
            <div className={styles.signIntext}>
              <p className={styles.signinPolicy}>
                By signing up you agree to our{" "}
                <Link to="/" className={styles.policy}>
                  Privacy Policy and Terms
                </Link>
              </p>
              <h6 className={styles.forgetPassword}>
                <a href="http://nascent.link" className={styles.goHome}>
                  Home |
                </a>
                <Link to="/reset" className={styles.forgetSignIn}>
                  Forgot your Password |
                </Link>
                <a href="http://nascent.link" className={styles.goHome}>
                  Sign Up
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    info: state.auth.data,
    error: state.auth.error,
  };
};

export default connect(mapStateToProps, { login, setAlert, logout })(Login);

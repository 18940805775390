import React, { useEffect, useState, useRef } from "react";

import { Grid} from "@material-ui/core";
import {
  NotificationBar,
  
  CustomizedTab,

  TaskTable,
  
} from "../../components";
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import styles from './Alldocument.module.css';
import AllDocumentContext from './AllDocumentContext';
import axiosInstance from "../../utils/axiosConfig";
import API from '../../urls/index';

import { AllDocumentSlider } from '../../components';

const AllDocumentPage = (props) => {
  const projectTitle = props.computedMatch.params.title;
  const projectID = props.computedMatch.params.id;
  const [ loader, showLoader, hideLoader ] = useFullPageLoader();
  const [ openSlider, setOpenSlider ] = useState(false);
  const [ allDocuments, setAllDocuments ] = useState(null);
  const [ documents, setDocuments ] = useState(null);

  

  useEffect(() => {
      showLoader()
      axiosInstance.get(API.AllDocument + `${projectID}/alldocument/`)
                   .then(res => {
                    hideLoader()
                    setAllDocuments(res.data.results);

                   })
                   .catch(err => console.log(err))
  }, [])

  const showSlider = (document) => {
    setDocuments(document)
    setOpenSlider(!openSlider);
  }


 

  const useOutsideClickRef = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSlider(false)
          
      };
    }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, openSlider]);
  };

  const wrapperRef = useRef(null);
  useOutsideClickRef(wrapperRef);
  
  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
            projectTitle={projectTitle}
            projectID={projectID}
          />
        }
        topButton="button"
      />

      <main ref={wrapperRef}>
        {openSlider ? <AllDocumentSlider documents={documents}  /> : null}
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "100%" }}>
            <Grid container>
              

              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <div
                  style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}
                ></div>
                <AllDocumentContext.Provider value={[allDocuments, loader, showSlider ]}>
                  <TaskTable />
                </AllDocumentContext.Provider>
              </Grid>
            </Grid>
           
          </div>
        </div>
      </main>
    
    </React.Fragment>
  );
};

export default AllDocumentPage;

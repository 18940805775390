import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { CompanyContext } from "../../components/Modal";
import axiosInstance from "../../utils/axiosConfig";
import API from '../../urls/index';
import { setAlert } from '../../actions/alert';
// import { Button } from "@material-ui/core";
import { Button } from '../../components';
import ArrowBack from "@material-ui/icons/ArrowBack";
import styles from "./newproject.module.css";
import { NascentImage } from "../../components";
import application from "../../application.svg";
import db from "../../firebase";

const NewProject = (props) => {
  const { projectslist } = props;

  const history = useHistory()
  const { company } = props;
 
  const [ companyId ] = company;


 
  const [newProject, setNewproject] = useState({ title: "", description: "" });

  const [ loading, setLoading ] = useState(false);
  const [titleError, setTitle] = useState(false);
  const [descriptionError, setDescription] = useState(false);
  const handleNewProjectChange = (event) => {
    setNewproject({ ...newProject, [event.target.name]: event.target.value });
    if (newProject.title !== "") {
      setTitle(false);
    }
    if (newProject.description !== "") {
      setDescription(false);
    }
  };

  const handleNewProjectSubmit = (event) => {

    event.preventDefault();
    if (newProject.title === "") {
      setTitle(true);
    } else if (newProject.description === "") {
      setDescription(true);
    } else {
      setLoading(true)
      axiosInstance
        .post(API.AddProject + `${companyId.id}/project/add/`, newProject)
        .then(res => { if(res.data) {
          projectslist.push(res.data.results)
          setLoading(true)
          history.push('/')
          setNewproject({ title: "", description: "" })
          props.setAlert("Project Added Successfully", "success")
          props.backToHome()
          db.collection("rooms").add({
            projectId : res.data.results.id,
            name: res.data.results.title,
          })
          
        }})
        .catch(err => {if(err){
          props.setAlert("You Do Not Have permission to perform this action", "error");
          props.backToHome()
          setLoading(false);
          
        }}) 
      
        
    }
  };

  return (
    <>
      <div className={styles.newProjectWrapper}>
        <Link to="/" onClick={props.backToHome}>
          <ArrowBack />
        </Link>
      </div>
      <div className={styles.innerProjectWrapper}>
        <div className={styles.innerProjectForm}>
          <h2 className={styles.newProjectHeader}>New Project</h2>
          <form onSubmit={props.backToHome}>
            <div className={styles.projectForm}>
              <label htmlFor="projectName">Project Name</label>
              <input
                type="text"
                name="title"
                onChange={handleNewProjectChange}
                value={newProject.title}
                className={titleError ? `${styles.redInput}` : null}
              />
              {titleError && (
                <div style={{ color: "red" }}>This field is Required</div>
              )}
            </div>
            <div className={styles.projectForm}>
              <label htmlFor="projectDescription">Project Description</label>
              <textarea
                type="text"
                rows="4"
                name="description"
                value={newProject.description}
                onChange={handleNewProjectChange}
                className={descriptionError ? `${styles.redInput}` : null}
              />
              {descriptionError && (
                <div style={{ color: "red" }}>This field is Required</div>
              )}
            </div>
            <Button
              onClick={handleNewProjectSubmit}
              loading={loading}
              button = "Create Project"
            />
              
           
          </form>
        </div>
        <NascentImage application={application} />
      </div>
    </>
  );
};

const mapStateToProject = (state) => {
  return { company : state.auth.data.companies, projectslist : state.projectList.data }
}

export default connect(mapStateToProject, { setAlert })(NewProject);

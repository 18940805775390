import React, { useContext, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import MyItemsContext from "../../containers/MyItems/MyItemsContext";

import { TableRow, TableCell } from "@material-ui/core";
import { setAlert } from "../../actions/alert";
import { SimpleMenu, Modal } from "../../components";

import "./actions.css";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";


const MyActionItems = (props) => {
  const [myItems, getMyActionItems, loader] = useContext(MyItemsContext);

  const [completed, setCompleted] = useState(false);
  const [editState, setEditState] = useState({
    title: "",
    assigned_to: "",
    due_date: "",
    status: false,
  });
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openActionItemModal, setOpenActionItemModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [ID, setID] = useState();
  const [status, setStatus] = useState({ status: "" });
  const [prevStatus, setPrevStatus] = useState(null);
  const [actionID, setActionID] = useState(null);

  const handleCompleted = (id, status) => {
    setLoading(true)
    setID(id);
    if (!status) {
      axiosInstance
        .patch(API.DeleteMyActionItem + `${id}/update/`, { status: true })
        .then((res) => {
          if (res) {

            setCompleted(!completed);
            props.getMyActionItems();
            props.setAlert("You completed This Action Item", "success");
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
          }
        });
    } else if (status) {
      axiosInstance
        .patch(API.DeleteMyActionItem + `${id}/update/`, { status: false })
        .then((res) => {
          if (res.status === 200) {

            props.getMyActionItems();
            props.setAlert("Marked This Task Incomplete", "error");
            setCompleted(!completed);
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
          }
        });
    }
  };

  const handleStatusChange = (e) => {
    setStatus({ [e.target.name]: e.target.value });
  };

  const handleStatusSubmit = () => {

    if (prevStatus.status === status.status) {
      setActionID(null)
    } else {

      axiosInstance
        .patch(API.DeleteMyActionItem + `${actionID}/update/`, status)
        .then((res) => {
          if (res) {
            props.setAlert("Action Items Editted Successfully", "success");
            props.getMyActionItems();
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
            setLoading(false);
          }
        });
    }
  };
  const setPreviousStatus = (status, id) => {
    setStatus({ status: status });
    setPrevStatus({ status: status });
    setActionID(id);
  };

  const handleActionItemDelete = (id) => {
    setID(id);
    setOpenDelete(!openDelete);
  };

  const deleteMyActionItem = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.DeleteMyActionItem + `${ID}/update/`)
      .then((res) => {
        if (res) {
          setResponse(true);
          props.setAlert("Action Item Deleted Successfully", "success")
          setOpenDelete(!openDelete);
          setLoading(false)
        }
      })
      .catch((err) => {
        props.setAlert(err.response.data.detail, "error")
        setOpenDelete(!openDelete);
        setLoading(false)
      });
  };

  const getFilteredItems = (myItems) => {
    if (response) {
      return myItems.filter((item) => item.id !== ID);
    } else {
      return myItems;
    }
  };

  const updateMyActionItems = (id, title, assigned_to, due_date, status) => {
    setOpenActionItemModal(!openActionItemModal);
    setEditState({
      id: id,
      title: title,
      assigned_to: assigned_to,
      due_date: due_date,
      status: status,
    });
  };

  const getCapitalizeFirstChar = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }


  const useOutsideClickRef = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleStatusSubmit();
          setActionID(null);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, status]);
  };



  const wrapperRef = useRef(null);
  useOutsideClickRef(wrapperRef);


  return !myItems ? (
    <div>{loader}</div>
  ) : (
    getFilteredItems(myItems).map((item, index) => (
      <TableRow key={item.id}>
        <TableCell>{index + 1}</TableCell>

        <TableCell align="left">
          {item.title}
        </TableCell>

        <TableCell align="left">
          {!item.project && !item.task
            ? "No Project"
            : item.task
              ? item.task.project.title
              : item.project.title}
        </TableCell>
        <TableCell align="left">
          {item.task ? item.task.title : "No Task"}
        </TableCell>
        <TableCell align="left">{item.owner.first_name} {item.owner.last_name}</TableCell>
        <TableCell align="left">{item.due_date}</TableCell>

        {/* <TableCell align="left">
        { loading && ID === item.id ? <i className="fa fa-refresh fa-spin" aria-disabled></i> : 
        <CheckCircleOutlineOutlinedIcon
            className={item.status ? "green" : "gray"}
            onClick={() => handleCompleted(item.id, item.status)}
          />}
        </TableCell> */}
        <TableCell align="left" >
          {actionID === item.id ?
            <select
              ref={wrapperRef}
              name="status"
              onChange={handleStatusChange}
            >
              <option value="incomplete" selected={prevStatus.status === "incomplete" ? true : false}>Incomplete</option>
              <option value="complete" selected={prevStatus.status === "complete" ? true : false}>Complete</option>
              <option value="parked" selected={prevStatus.status === "parked" ? true : false}>Parked</option>
            </select> : (<div onClick={() => setPreviousStatus(item.status, item.id)}>{getCapitalizeFirstChar(item.status)}</div>)}
        </TableCell>
        <TableCell align="left">
          <SimpleMenu
            edit="Edit"
            delete="Delete"
            updateProject={() =>
              updateMyActionItems(
                item.id,
                item.project ? item.project.description : item.title,
                item.owner.id,
                item.due_date,
                item.status
              )
            }
            deleteProject={() => handleActionItemDelete(item.id)}
          />{" "}
        </TableCell>
        <Modal
          openPop={openDelete}
          onClick={() => setOpenDelete(!openDelete)}
          backdropClicked={() => setOpenDelete(!openDelete)}
          deleteBar="Delete Bar"
          title="Confirm Delete"
          loading={loading}
          ConfirmDeleteData={() => deleteMyActionItem(ID)}
          closeDialog={() => setOpenDelete(!openDelete)}
        />
        <Modal
          openPop={openActionItemModal}
          onClick={() => setOpenActionItemModal(!openActionItemModal)}
          backdropClicked={() => setOpenActionItemModal(!openActionItemModal)}
          title="Update Action Item"
          button=" Update Action Item"
          update={true}
          editState={editState}
          getMyActionItems={props.getMyActionItems}
          closeDialog={() => setOpenActionItemModal(!openActionItemModal)}
        />
      </TableRow>
    ))
  );
};

export default connect(null, { setAlert })(MyActionItems);

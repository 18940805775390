import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown } from '@material-ui/icons';


const SimpleMenu = (props) => {
  const [ editState, setEditState ] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ openNewProjectEdit, setOpenNewProjectEdit ] = React.useState(false)
  
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    props.deleteProject();
    handleClose()
  }

  const handleEditClick = () => {
    props.updateProject()
    handleClose()
  }

 
  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{outline:"none"}}>
        <ArrowDropDown style={{outline:"none"}}/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { props.edit ? <MenuItem onClick={handleEditClick}>{props.edit}</MenuItem> : null}
        <MenuItem onClick={handleDelete}>{props.delete}</MenuItem>
        
      </Menu>  
     
      
    </>
  );
}


export default SimpleMenu;
import React from 'react';
import { BasicTable } from '../../components';


const Member = (props) => {

    return (

      <BasicTable
      member="member"
      contactMessage={props.ContactMessages}
      listOfConference={props.listOfConference}
      ProMember = {props.proMember}
      td1 = {props.td1}
      td2 = {props.td2}
      td3 = {props.td3}
      td4 = {props.td4}
      td5 = {props.td5}
      td6 = {props.td6}
      projectID = {props.projectID}
      taskRoom = {props.taskRoom}
      taskID={props.taskID}
      getProjectMembersList = {props.getProjectMembersList}

    />
  
    )
}

export default Member;
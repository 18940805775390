import React from 'react';

import { Task } from '../../containers';

const ProjectTask = (props) => {
  
  const projectID = props.computedMatch.params.id;
  const projectTitle = props.computedMatch.params.title;
  

   
    return (
        <Task projectID={projectID} projectTitle={projectTitle} />
    )

}

export default ProjectTask;
import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";

import {

  NotificationBar,
  BasicTable,
  CustomizedTab,
  ProjectButton,
  Modal,
} from "../../components";
import { withStyles } from '@material-ui/core/styles';
import styles from "./actionitems.module.css";




const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding:"0px",
    height:'100%',
  },

})(MuiTableCell);

const ActionItems = (props) => {
  const [ openActionItems, setOpenActionItems ] = useState(false)
  const { taskTitle } = props;
  const { projectID } = props;
  const { proID } = props
  const { projectTitle } = props;

  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
          done = "70"
            taskRoom={props.taskRoom}
            taskID={props.taskID}
            taskTitle={taskTitle}
            projectID={projectID}
            proID = {proID}
            projectTitle={projectTitle}
          />
        }
        topButton="button"
      />

      <main>
        <div className={styles.myitemOuterWrapper}>
          <Grid container>
            <Grid item sm={12} md={12} style={{ margin: "15px 0px" }}>
              <ProjectButton
                text="+ Action Items"
                onClick = {() => setOpenActionItems(!openActionItems)}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={12}>
              <div
                style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}
              ></div>
              <BasicTable
                actionItems="Action Items "
                sno="S.No."
                owner="Owner"
                action="Action 1"
                dueDate="Due Date"
                taskRoom={props.taskRoom}
                projectID={projectID}
                getProjectActionItems={props.getProjectActionItems} 
              />
            </Grid>
          </Grid>
        </div>
      </main>
      <Modal 
      openPop={openActionItems} 
      close={() => setOpenActionItems(false)}
      onClick={() => setOpenActionItems(!openActionItems)}
      backdropClicked = {() => setOpenActionItems(!openActionItems)}
      title="Add Action Items"
      button="Add Action Items"
      actions = "ActionItems"
      projectID={projectID}
      proID={proID}
      taskRoom={props.taskRoom}
      taskID={props.taskID}
      getTaskActionItems={props.getTaskActionItems}
      getProjectActionItems={props.getProjectActionItems} 
      > 
      </Modal>
    </React.Fragment>
  );
};

export default ActionItems;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';
import { setAlert } from '../../actions/alert';
import './reset.css';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});



const ResetPasswordForm = (props) => {
  const [ email, setEmail ] = useState({ email : ''})
  const [ sent, setSent ] = useState(false);

  const classes = useStyles();
  const handlePasswordResetChange = (event) => {
      setEmail({[event.target.name ]:event.target.value})
  }
  const handlePasswordResetSubmit = (event) => {
      event.preventDefault()
    axiosInstance.post(API.ResetPassword, email)
                 .then(res => {if(res.data){
                   setSent(true)
                 }})
                 .catch(err => {
                   if(err.response.status === 400){
                   
                   props.setAlert("No Active account found", "error")
                  }
                  else{
                    props.setAlert("Something went wrong", "error")
                  }
                })
  }
  return (
    <>
      <AppBar style={{ backgroundColor: "#091936" }} position="static">
        <Toolbar
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Nascent</h3>
          
        </Toolbar>
      </AppBar>

      <div className="forgotPasswordWrapper">
          
          {sent ? 
          <div className="resetRequest">
            <p>Thanks For submitting Your Request. An email is sent to {email.email}, Please Check and request for new-password on clicking to the link</p>
          </div> : 
          <form>
              <div className="forgotPasswordForm">
              <input type='email' name='email' placeholder="Enter Your Email" onChange={handlePasswordResetChange} /><br />
              <button onClick={handlePasswordResetSubmit}> Submit</button>
              </div>
          </form>}
         
      </div>
    </>
  );
}

export default connect(null, { setAlert} )(ResetPasswordForm);
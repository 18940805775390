import React, { useState,  useEffect } from "react";
import {  Button } from "@material-ui/core";
import { formatDate } from '../../utils/dateTimeUtility';
import { Modal } from '../../components';
import "./slider.css";
import axiosInstance from "../../utils/axiosConfig";


import API from "../../urls/index";
import { Visibility } from "@material-ui/icons";

const Slider = (props) => {

  const [document, setDocument] = useState(null);
  const [ openResubmissionForm, setOpenResubmissionForm ] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(API.SingleTaskDocument + `${props.ID}/`)
      .then((res) => setDocument(res.data))
      .catch((err) => console.log(err));
  }, []);



  return (
    <div className="sliderWrapper">
     
        <div className="slider">
          {document ? (
            <div className="documentLog" key={document.id}>
              <p className="documentName">Documents Log</p>
              <hr />
              <div className="documentWrapper">
                <div style={{ height: "850px", overflow:"auto" }}>
                  {document.document_file.map((file) =>
                    file.document_status.map((status) => (
                      <>
                        <p>Status: {status.status} {status.status === "Resubmission" ? <a href={`${document.document_file[document.document_file.length - 1].file}`} target="_blank"><Visibility /></a>: null}</p>
                        <p>Modified by : {status.first_name} {status.last_name}</p>
                        <p>Created Date : {formatDate(status.action_date)}</p>
                        <hr />
                      </>
                    ))
                  )}
                </div>

                <Button color="secondary" style={{ width: "100%" }} onClick={() => setOpenResubmissionForm(!openResubmissionForm)}>
                  Re-Submit this Document
                </Button>
                <br />
              </div>
              
            </div>
          ) : (
            <div className="sliderIcon"><i className="fa fa-refresh fa-spin"></i>Loading...</div>
          )}
        </div>
        <Modal 
        openPop={openResubmissionForm}
        onClick={() => setOpenResubmissionForm(!openResubmissionForm)}
        backdropClicked={() => setOpenResubmissionForm(!openResubmissionForm)}
        title = "Resubmit The Document"
        button="Resubmit Document"
        resubmit={true}
        ID={props.ID}
        />
    </div>
  );
};

export default Slider;

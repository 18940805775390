import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setAlert } from '../../actions/alert';
import { AppBar, Toolbar } from '@material-ui/core';
import axiosInstance from 'axios';
import API from '../../urls/index';
import './accept.css';


const AcceptCollabReq = (props) => {

  const id = props.match.params.reqId;

  const [collabRequest, setCollabRequest] = useState({ first_name: '', last_name: '', contact: '', password: '', confirm_password: '' })
  const [requestSuccess, setRequestSuccess] = useState(false)

  const handleCollabRequestChange = (event) => {
    setCollabRequest({ ...collabRequest, [event.target.name]: event.target.value })
  }

  const handleCollabRequestSubmit = (event) => {
    event.preventDefault()


    if (collabRequest.first_name === '' || collabRequest.last_name === '' || collabRequest.password === '' || collabRequest.confirm_password === '') {
      props.setAlert("Please Enter all Input fields", "error")
    }
    else if (collabRequest.first_name !== '' && collabRequest.last_name !== '') {

      if (collabRequest.password !== collabRequest.confirm_password) {
        props.setAlert("Passwords did not match", "error")
      }
      else {
        axiosInstance.post(API.AcceptCollabReq + `${id}/req/accept/new/`, collabRequest)
          .then(res => {
            if (res.data.status === "success") {

              setRequestSuccess(true)
              props.setAlert("Registered Successfully", "succes");
            }



          })
          .catch(err => {
            if (err.response) {
              if (err.response.data.results) {
                props.setAlert(err.response.data.results, "error")
              }
              else if (err.response.data.detail) {
                props.setAlert("User with this username already exist please choose a unique username", "error")
              }
            }
            else {
              props.setAlert("Something Went wrong", "error")
            }
          })
      }
    }




  }


  return (
    <>
      <AppBar style={{ backgroundColor: "#091936" }} position="static">
        <Toolbar
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Nascent</h3>

        </Toolbar>
      </AppBar>



      {requestSuccess ? <div>User Registration Success <Link to='/login'>Login To Continue</Link></div> :
        <div className="acceptInvite">

          <form style={{ display: 'flex', flexDirection: 'column' }} >
            <h3 style={{ textAlign: "center", color: "black" }}>You have been invited to join a project! Please Register to Join Team.</h3>

            <div className="forgotPasswordForm">
              <label htmlFor="first_name"  >First Name</label>
              <input type='text' name="first_name" value={collabRequest.username} onChange={handleCollabRequestChange} /><br />
              <label htmlFor="last_name"  >Last Name</label>
              <input type='text' name="last_name" value={collabRequest.username} onChange={handleCollabRequestChange} /><br />
              <label htmlFor="username" >Contact</label>
              <input type='number' name="contact" value={collabRequest.contact} onChange={handleCollabRequestChange} /><br />
              <label htmlFor="password"  >Password</label>
              <input type='password' name="password" value={collabRequest.password} onChange={handleCollabRequestChange} /> <br />
              <label htmlFor="confirm-password" >Confirm password</label>
              <input type='password' name="confirm_password" value={collabRequest.confirm_password} onChange={handleCollabRequestChange} /> <br />
              <button onClick={handleCollabRequestSubmit}> Save</button>
            </div>

          </form>

        </div>}
    </>
  );
}

export default connect(null, { setAlert })(AcceptCollabReq);
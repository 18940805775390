import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar,Button, Grid } from '@material-ui/core';
import './forgotpassword.css';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const ForgotPassword = (props) => {
  const token = props.match.params.token;
  const [ forgotPassword, setForgotPassword ] = useState({token: token, password:'', confirmPassword : ''})
  const [ success, setSuccess ] = useState(false)
 
  const classes = useStyles();

  const handlePasswordChange = (event) => {
    setForgotPassword({...forgotPassword,[event.target.name]: event.target.value})
  }
  const data = { token: forgotPassword.token, password: forgotPassword.password}
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    
      if(forgotPassword.password === forgotPassword.confirmPassword){
        axiosInstance.post(API.ResetPasswordConfirm, data)
                   .then(res => {if(res.data){
                     props.setAlert("Password Changed successfully", 'success')
                     setSuccess(true)
                   }})
                   .catch(err => {if (err.response){
                   
                     props.setAlert(err.response.data.password[0], "error")
                   }
                   else{
                     props.setAlert("Something went wrong", "error")
                   }
                  })
    
      }
      else{
        props.setAlert("Fields do not match", "error")
      }
  }
  
  return (
    <>
      <AppBar style={{ backgroundColor: "#091936" }} position="static">
        <Toolbar
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Nascent</h3>
          
        </Toolbar>
      </AppBar>
      
      <div className="forgotPasswordWrapper">
          {/* { failed ? <div style={{textAlign:'center'}}>Fields Not Matching</div> : null} */}
          {success ? <div>Password Changed Successfully, <Link to='/login'>Login</Link> to Continue</div> :
          <form>
          
          <div className="forgotPasswordForm">
          <label htmlFor="new-password" >New Password</label>
          <input type='password' name="password"  onChange={handlePasswordChange} /><br />
          <label htmlFor="confirm-password" >Confirm Password</label>
          <input type='password' name="confirmPassword"  onChange={handlePasswordChange} /> <br />
          <button onClick={handlePasswordSubmit}> Save</button>
          </div>
      </form>}
      </div>
    </>
  );
}

export default connect(null, { setAlert })(ForgotPassword);
import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Avatar, List, ListItem, Button } from "@material-ui/core";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles } from "@material-ui/core/styles";
import db from "../../firebase";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { setAlert } from "../../actions/alert";

import "./conversation.css";
import { SimpleMenu } from "../../components";
import axiosInstance from "../../utils/axiosConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "5px auto",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ConversationMessageList = ({
  auth,
  setAlert,
  message,
  user_data,
  rooms,
  value,
  getMessages,
}) => {
  // const dummy = useRef();
  // const scrollToBottom = () => {
  //   dummy.current.scrollIntoView({ behavior: "smooth" });
  // };

  const handleChatDelete = (id) => {
    db.collection("rooms")
      .doc(rooms)
      .collection("messages")
      .onSnapshot((snap) =>
        snap.docs.map((doc) => {
          if (doc.data().id === id) {
            db.collection("rooms")
              .doc(rooms)
              .collection("messages")
              .doc(doc.id)
              .delete()
              .then((res) => {
                getMessages();
              })
              .catch((err) => console.log(err));
          }
        })
      );
  };
  // useEffect(scrollToBottom, [message])

  const [img, setImg] = useState("");

  useEffect(() => {
    axiosInstance.get("https://nascent.link/api/user/company/").then((i) => {
      i.data.results.map((i) => {
        if (i.owner.id === message.user_id) {
          setImg(i.owner.photo);
        }
      });
    });

    // axiosInstance
    // .get(API.UserProfileUpdate)
    // .then((res) =>
    //   setProfileData({
    //     first_name: res.data.first_name,
    //     last_name: res.data.last_name,
    //     contact: res.data.contact,
    //     username: res.data.username,
    //     photo: res.data.photo,
    //   })
    // )
  }, []);

  const classes = useStyles();
  return (
    <React.Fragment key={message.id}>
      {/* <div className={styles.conversationOuterWrapper}>
              <div className={styles.conversationInnerWrapper}>
                <div className={styles.userImage}>
                  <Avatar />
                </div>
                <div className={styles.conversationMessage}>
                  <p className={styles.message}>
                    {message.message}
                  </p>
                </div>
                <p className={styles.time}>4:21 PM</p>
              </div>
            </div> */}

      <List className="conversationList">
        <ListItem>
          <ListItemAvatar>
            <Avatar
              variant="square"
              style={{ height: "50px", width: "50px" }}
              src={`${img}`}
            />
          </ListItemAvatar>
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ListItemText>
                <span className="chatUsername">{message.user}</span>
                <span className="timeStamp">
                  {new Date(message.timestamp?.toDate()).toDateString()}
                </span>
                <span>
                  {user_data.data.username === message.email ? (
                    <SimpleMenu
                      style={{ height: "10px !important" }}
                      delete="Delete"
                      deleteProject={() => handleChatDelete(message.id)}
                    />
                  ) : null}
                </span>
              </ListItemText>
            </div>
            <ListItemText
              style={{ marginTop: "-5px" }}
              primary={
                message.attachmentName ? (
                  <a href={`${message.message}`} target="_blank">
                    {message.attachmentName}
                  </a>
                ) : (
                  message.message
                )
              }
            />
          </div>
        </ListItem>
      </List>

      {/* <span ref={dummy}></span> */}

      {/* <div className={styles.conversationOuterWrapper}>
              <div
                className={styles.conversationInnerWrapper}
                style={{ marginLeft: "50%" }}
              >
                <p className={styles.timeTwo}>4:21 PM</p>
                <div className={styles.conversationMessage}>
                  <p className={styles.message}>
                    {message.message}
                  </p>
                </div>
                <div className={styles.userImage}>
                  <Avatar />
                </div>
              </div>
            </div> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
    error: state.auth.error,
    user_data: state.userProfile,
  };
};

export default connect(mapStateToProps, { setAlert })(ConversationMessageList);

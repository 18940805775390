function getApiEndPoints() {
    let endPoint = null;

    switch (process.env.NODE_ENV) {
        case 'development':
            endPoint = {
                baseUrl: 'https://nascent.link/api/',
                siteUrl: 'https://nascent.link/api/',
                meetingUrl: 'https://meet.nascent.link/',
                base: "/"
            }
            break;
        case 'production':
            endPoint = {
                baseUrl: 'https://nascent.link/api/',
                siteUrl: 'https://nascent.link/api/',
                meetingUrl: 'https://meet.nascent.link/',
                base: "/"
            }
            break;

        default:
            endPoint = {
                baseUrl: 'https://nascent.link/api/',
                siteUrl: 'https://nascent.link/api/',
                meetingUrl: 'https://meet.nascent.link/',
                base: "/"
            }

    }
    return endPoint;
}

export const apiEndPoints = getApiEndPoints();

import React, { useContext, useState } from "react";

import { connect } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TableCell, TableRow } from "@material-ui/core";

import { setAlert } from "../../actions/alert";

import Paper from "@material-ui/core/Paper";
import axiosInstance from "../../utils/axiosConfig";
import VisibilityIcon from '@material-ui/icons/Visibility';
import API from "../../urls/index";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { formatDate } from '../../utils/dateTimeUtility';
import './taskdocument.css';

import { SimpleMenu, Modal, Slider, SelectBoxModal } from "../../components";
import TaskDocumentListContext from "../TaskDocuments/TaskDocumentListContext";

const TaskDocumentList = (props) => {


  const [ getTaskDocument, proID, getTaskDocumentsList, loader ] = useContext(TaskDocumentListContext)
  const [openDelete, setOpenDelete] = useState(false);

  const [ID, setID] = useState();
  
  const [response, setResponse] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [statusVal, setStatusValue] = useState(false);
  const [documentID, setDocumentID] = useState(60);
  const [ loading, setLoading]= useState(false);

  const [val, setVal] = useState();

  const deleteTaskDocument = (id) => {
    setID(id);
    setOpenDelete(!openDelete);
  };

  const getDocumentUpdate = (id) => {
    const document_id = id;
 
    setDocumentID(document_id);
    setStatusValue(!statusVal);
  };

  const deleteTaskDocumentConfirm = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.ProjectDocumentDelete + `${ID}/`)
      .then((res) => {
        if (res) {
          setResponse(true);
          setOpenDelete(!openDelete);
          props.setAlert("Task Document Deleted Successfully", "success");
          setLoading(false);
        }
      })
      .catch((err) => {
        props.setAlert(
          "You Do Not Have Permission to perform this action",
          "error"
        );
        setLoading(false)
        setOpenDelete(!openDelete)
      });
  };

  const getFilteredTaskDocument = (getTaskDocument) => {
    if (response) {
      return getTaskDocument.filter((task) => task.id !== ID);
    } else {
      return getTaskDocument;
    }
  };

  const getDocumentID = (id) => {
    setVal(id)
    props.showDrawer()
    props.getIDForDrawer(id)
  }



  return (
    !getTaskDocument ? <div>{loader}</div> : 
      getFilteredTaskDocument(getTaskDocument).map((document, index) => (
  
          <React.Fragment key={document.id}>
            
              <TableRow
                style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}
                className="largeScreen"
              >
                <TableCell>{index+1}</TableCell>
                
                <TableCell component="th" scope="row" align="center">
                  {document.name} <a href={`http://nascent.link${document.document_file[0].file}`} target="_blank"><VisibilityIcon /></a>
               
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{cursor : "pointer"}}
                  onClick={() =>
                    getDocumentUpdate(
                      document.document_file[0].id
                    )
                  }
                >
                  {document.document_file[0].document_status[document.document_file[0].document_status.length - 1].status}


                  
                </TableCell>

                <TableCell align="center">
                  {document.document_file[0].document_status[document.document_file[0].document_status.length - 1].first_name} {' '}
                  {document.document_file[0].document_status[document.document_file[0].document_status.length - 1].last_name}
                    
                  
                </TableCell>
                <TableCell align="center">
                {formatDate(document.document_file[0].document_status[document.document_file[0].document_status.length - 1].action_date)}
                </TableCell>
               
                <TableCell>
                <InfoOutlinedIcon
                 
                    onClick={() => getDocumentID(document.id)}
                  />
                </TableCell>

                <TableCell align="center">
                
                  <SimpleMenu
                  
                    delete="Delete"
                    deleteProject={() => deleteTaskDocument(document.id)}
                  />
                </TableCell>
                
              </TableRow>
            
              <TableRow component={Paper} className="smallScreen">
                <TableCell
                  align="left"
                  style={{
                    width: "40%",
                    lineHeight: "0.5rem",
                    padding: "20px",
                  }}
                  
                >
                  <p>S.No.</p>
                  <p>Document</p>
                  <p>Status</p>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    width: "40%",
                    lineHeight: "0.5rem",
                    padding: "10px",
                  }}
                >
                  <p>{document.id}</p>
                  <p>{document.name}  <a href={`http://nascent.link${document.document_file[0].file}`} target="_blank" ><VisibilityIcon style={{fontSize:'15px'}}/></a></p>
                  <p>{document.document_file[0].document_status[document.document_file[0].document_status.length - 1].status} </p>
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  <SimpleMenu
                    
                    delete="Delete"
                    deleteProject={() => deleteTaskDocument(document.id)}
                  />
                </TableCell>
              </TableRow>
            
            <Modal
              openPop={openDelete}
              onClick={() => setOpenDelete(!openDelete)}
              backdropClicked={() => setOpenDelete(!openDelete)}
              deleteBar="Delete Bar"
              title="Confirm Delete"
              loading={loading}
              ConfirmDeleteData={() => deleteTaskDocumentConfirm(ID)}
              closeDialog={() => setOpenDelete(!openDelete)}
            />

            <Modal
              openPop={statusVal}
              onClick={() => setStatusValue(!statusVal)}
              backdropClicked={() => setStatusValue(!statusVal)}
              button="Update Status of Document"
              title="Update Status Log"
              proID={proID}
              statusChange={true}
              documentID={documentID}
              closeDialog={() => setStatusValue(!statusVal)}
            />
          
          </React.Fragment>
      
      ))

  );
};

export default connect(null, { setAlert })(TaskDocumentList);

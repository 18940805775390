import React, { useState } from 'react';

import { Button, ProjectUsers } from '../../components';
import axiosInstance from '../../utils/axiosConfig';

import API from '../../urls/index';


const AddTaskMember = (props) => {



    const { taskID } = props;

    const [taskMember, setTaskMember] = useState({ member_id: [] })
    const [loading, setLoading] = useState(false);
    const handleMembersFieldChange = (value) => {
        setTaskMember({ member_id: value })
    }




    const handleTaskMemberSubmit = (e) => {
        e.preventDefault();

        if (taskMember.member_id.length === 0) {
            props.setAlert("Please Enter atleast One Member", "error")
        }
        else {
            setLoading(true)
            let mem_id = []
            mem_id = taskMember.member_id.map(id => id.user.id)

            axiosInstance.put(API.AddTaskMember + `${taskID}/member/add/`, { member_id: mem_id })
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        setTaskMember({ member_id: [] })
                        props.setAlert("Member Added Successfully", "success")
                        props.getTaskMembersList();
                        props.onClick()
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        props.setAlert("you do not have permission to perform this action", "error")
                    }
                    else {
                        props.setAlert("Something went wrong", "error")
                    }
                })
        }


    }
    return (<>
        <div style={{ marginBottom: '50px' }}>
            <ProjectUsers proID={props.proID} handleMembersFieldChange={handleMembersFieldChange} />
        </div>
        <Button button={props.button} loading={loading} onClick={handleTaskMemberSubmit} />
    </>)
}

export default AddTaskMember;
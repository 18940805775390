import React, { useState } from 'react';
import { DocumentModal, Button, ProjectUsers } from '../../components';
import axiosInstance from '../../utils/axiosConfig';

import API from '../../urls/index';

const AddTaskActionItem = (props) => {

   const { taskID } = props;
   const [loading, setLoading] = useState(false)
   const [taskActions, setTaskActions] = useState({ title: '', due_date: '' })
   const [memberId, setMemberId] = useState({ member_id: '' })
   const handleTaskActionItemsChange = (e) => {
      setTaskActions({ ...taskActions, [e.target.name]: e.target.value })
   }

   const handleMembersFieldChange = (value) => {
      setMemberId({ member_id: value })
   }

   const handleTaskActionItemsSubmit = (e) => {
      e.preventDefault();



      if (taskActions.title === '' || taskActions.due_date === '') {
         props.setAlert("Please Add All Fields before submission", "error")
      }
      else if (memberId.member_id === '') {
         props.setAlert("Please Enter Atleast one member, you want to assign this task to", "error")
      }

      else {
         setLoading(true)
         const [ass] = memberId.member_id;

         const data = {
            title: taskActions.title,
            due_date: taskActions.due_date,
            assigned_to: ass.user.id,
            status: "incomplete",
         }

         axiosInstance.post(API.AddTaskActionItem + `${taskID}/actionitem/add`, data)
            .then(res => {
               if (res.status === 200) {
                  props.setAlert("Successfully Added to Task Action Item", "success");
                  props.getTaskActionItems()
                  props.onClick()
                  setLoading(false)
                  setTaskActions({ title: '', due_date: '' })
               }
            })
            .catch(err => {

               props.setAlert("Something went wrong", "error");
               setLoading(false)
            }
            )
      }
   }
   return (<>
      <ProjectUsers label="Assigned To" proID={props.proID} handleMembersFieldChange={handleMembersFieldChange} />
      <DocumentModal label="Action" type="text" name="title" onChange={handleTaskActionItemsChange} />

      <DocumentModal name="due_date" label="Due Date" type="date" onChange={handleTaskActionItemsChange} />
      <Button loading={loading} button={props.button} onClick={handleTaskActionItemsSubmit} />
   </>)
}

export default AddTaskActionItem;
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import useStayScrolled from "react-stay-scrolled";
import { Grid, Paper } from "@material-ui/core";

import { NotificationBar, CustomizedTab, ChatForm } from "../../components";
import styles from "./conversation.module.css";
import db from "../../../src/firebase";

import ConversationMessageList from "./ConversationMessageList";
import pageview from "material-ui/svg-icons/action/pageview";
import message from "material-ui/svg-icons/communication/message";
import axios from "axios";
import axiosInstance from "../../utils/axiosConfig";

const Conversation = (props) => {
  const listRef = useRef();
  const { stayScrolled } = useStayScrolled(listRef);
  const projectTitle = props.computedMatch.params.title;
  const { taskTitle } = props;
  const projectID = props.computedMatch.params.id;
  const { proID } = props;
  const [messages, setMessages] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [n, setN] = useState(10);
  const [length, setLength] = useState(0);
  const [noMessages, setNoMessages] = useState(false);

  useEffect(() => {
    if (projectID) {
      db.collection("rooms").onSnapshot((snap) =>
        snap.docs.map((id) => {
          if (id.data().projectId === parseInt(projectID)) {
            setRooms(id.id);
            setValue(id.data().projectId);
          }

          getMessages();
        })
      );
    }
  }, [rooms, projectID, value]);

  const getMessages = () => {
    if (value === parseInt(projectID)) {
      const ref = db
        .collection("rooms")
        .doc(rooms)
        .collection("messages")
        .orderBy("timestamp", "asc")
        .limitToLast(n);
      ref.onSnapshot((snapshot) => {
        setMessages(snapshot.docs.map((id) => id.data()));
      });
    }
  };

  useLayoutEffect(() => {
    if (messages) {
      stayScrolled();
    }
  }, [messages]);

  const getMoreMessages = () => {
    setLoading(true);
    db.collection("rooms")
      .doc(rooms)
      .collection("messages")
      .orderBy("timestamp", "asc")
      .limitToLast(n + 10)
      .onSnapshot((snapshot) => {
        setLoading(false);
        setMessages(snapshot.docs.map((snap) => snap.data()));

        setN(n + 10);
        setLength(messages.length);
      });
  };

  useEffect(() => {
    if (length) {
      if (length === messages.length) {
        setNoMessages(true);
      }
    }
  }, [messages]);

  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
            done="70"
            taskRoom={props.taskRoom}
            proID={proID}
            projectID={projectID}
            taskTitle={taskTitle}
            taskID={props.taskID}
            projectTitle={projectTitle}
          />
        }
        topButton="button"
      />

      <main className={styles.mainWrapper}>
        <div className={styles.myitemOuterWrapper} ref={listRef}>
          <div style={{ width: "100%", margin: "0px auto" }}>
            <Grid container>
              <Grid item lg={12}>
                <div className={styles.conversationText}>
                  <h4>New Conversation</h4>
                  <p style={{ lineHeight: "1rem" }}>
                    start a team conversation to make announcements, share
                    information, or discuss projects
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div
                // style={{
                //   width: "100%",
                //   border: "0.5px solid black",
                //   marginTop: "10px",
                //   background: "red",
                // }}
                >
                  <hr style={{ background: "black" }} />
                </div>

                {/* {messages.filter(time => (new Date(time?.timestamp.toDate()).toUTCString() === new Date().toDate.toUTCString()))}
                 
                  {/* // (<h10 className={styles.today}></h10>}  */}
              </Grid>
            </Grid>
            {/* <div className={styles.conversationOuterWrapper}>
                <div className={styles.conversationInnerWrapper}>
                  <div className={styles.userImage}>
                    <Avatar />
                  </div>
                  <div className={styles.conversationMessage}>
                    <p className={styles.message}>hello world</p>
                  </div>
                  <p className={styles.time}>10:21 PM</p>
                </div>
              </div>
              <Hidden mdDown>
                <div className={styles.conversationOuterWrapper}>
                  <div
                    className={styles.conversationInnerWrapper}
                    style={{ marginLeft: "50%" }}
                  >
                    <p className={styles.timeTwo}>10:21 PM</p>
                    <div className={styles.conversationMessage}>
                      <p className={styles.message}>
                        hello world welcome to the world of react and redux, we
                        will discuss some important aspects of react and redux
                      </p>
                    </div>
                    <div className={styles.userImage}>
                      <Avatar />
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <div className={styles.conversationOuterWrapper}>
                  <div className={styles.conversationInnerWrapper}>
                    <p className={styles.timeTwo}>10:21 PM</p>
                    <div className={styles.conversationMessage}>
                      <p className={styles.message}>
                        hello world welcome to the world of react and redux, we
                        will discuss some important aspects of react and redux
                      </p>
                    </div>
                    <div className={styles.userImage}>
                      <Avatar />
                    </div>
                  </div>
                </div>
              </Hidden> */}

            {!messages ? null : messages.length >= 10 ? (
              noMessages ? (
                <span style={{ display: "block", textAlign: "center" }}>
                  {" "}
                  No More Messages{" "}
                </span>
              ) : (
                <span onClick={getMoreMessages} className="loadMore">
                  {loading ? <i className="fa fa-refresh fa-spin"></i> : null}
                  Load More
                </span>
              )
            ) : null}
            {!messages ? (
              <div className="loadingMessages">
                <i className="fa fa-refresh fa-spin"></i>Loading...
              </div>
            ) : messages.length === 0 ? (
              <div style={{ paddingLeft: "35px" }}>
                No Messages in this conversation
              </div>
            ) : (
              messages.map((message) => (
                <ConversationMessageList
                  getMessages={getMessages}
                  rooms={rooms}
                  value={value}
                  key={message.id}
                  message={message}
                />
              ))
            )}
          </div>
        </div>

        <div className={styles.chatForm}>
          <ChatForm rooms={rooms} value={value} />
        </div>
      </main>
    </React.Fragment>
  );
};

export default withRouter(Conversation);

import React, { useEffect, useState, createContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { NotificationBar, SimpleTable } from "../../components";
import { cleanup } from "@testing-library/react";
import { logout } from "../../actions/auth";
import styles from "./home2.module.css";
import axiosInstance from "../../utils/axiosConfig";
import useFullPageLoader from "../../hooks/UseFullPageLoader";
import { getprojectlist } from "../../actions/projectList";

import API from "../../urls/index";

export const CompanyUserContext = createContext();

const Home2 = (props) => {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [companyVal, setCompanyVal] = useState(null);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    if (props.company.companies.length > 1) {
      if (localStorage.getItem("companyId")) {
        setCompanyVal(localStorage.getItem("companyId"));
      } else {
        setCompanyVal(props.company.companies[0].id);
      }
    } else {
      const { company } = props;
      const companyData = company.companies;

      const [companyId] = companyData;

      setCompanyVal(companyId.id);
    }
  }, []);

  const handleLogout = () => {
    props.logout();
    history.push("/login");
  };

  const getCompanyUser = () => {
    showLoader();
    axiosInstance
      .get(API.CompanyMembers + `${companyVal}/members/`)
      .then((res) => {
        setUserData(res.data.results);
        hideLoader();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (companyVal) {
      props.getprojectlist(companyVal);
      getCompanyUser();
    }
  }, [companyVal]);

  // if(companyVal){
  //   localStorage.setItem("companyVal", companyVal)
  // }

  return props.company.companies.length === 0 ? (
    <div>
      You Are Currently not involved in any company{" "}
      <Link onClick={handleLogout}>Logout</Link>
    </div>
  ) : (
    <CompanyUserContext.Provider value={[userData, loader]}>
      <NotificationBar title="Home" topButton="Top Button" />

      <main>
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "95%", margin: "auto" }}>
            <Grid container>
              <Grid container>
                <Grid item lg={12} style={{ marginTop: "10px" }}>
                  <h5>Projects</h5>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <SimpleTable
                  project="Project"
                  file="File"
                  name="Name"
                  description="Description"
                  create="+ Create Project"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "95%", margin: "auto" }}>
            <Grid container>
              <Grid container>
                <Grid item lg={12} style={{ marginTop: "30px" }}>
                  <h5>Users</h5>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <SimpleTable
                  users="users"
                  file="File"
                  name="Name"
                  phnum="Phone Number"
                  email="Email"
                  date="Date"
                  companyVal={companyVal}
                  addUser="+ Add New Users"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </CompanyUserContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return { projectslist: state.projectList, company: state.auth.data };
};

export default connect(mapStateToProps, { getprojectlist, logout })(Home2);

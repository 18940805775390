import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, NotificationBar } from "../../components";
import { setAlert } from '../../actions/alert';
import styles from "./updatepassword.module.css";
import API from '../../urls/index';
import axiosInstance from '../../utils/axiosConfig';

const UpdatePassword = (props) => {
    const history = useHistory()
    const [changedPassword, setChangedPassword] = useState({ old_password : '', new_password : '' , confirm_new_password : ''});
    const [ loading, setLoading ] = useState(false);
    const handleChangedPassword = (event) => {
        setChangedPassword({...changedPassword, [event.target.name] : event.target.value})
    }

    const handleChangedPasswordSubmit = (event) => {
      event.preventDefault();
      if(changedPassword.new_password !== changedPassword.confirm_new_password){
        props.setAlert("Passwords do not match", "error")
        history.push('/update-password')
      }
      if(changedPassword.new_password === changedPassword.confirm_new_password){
        setLoading(true)
        axiosInstance.post(API.RequestPasswordChange, {old_password: changedPassword.old_password, new_password: changedPassword.new_password})
                     .then(res => { if(res.status === 200 ){
                        props.setAlert("Password Changed successfully", "success")
                        history.push('/')
                     }
                     else{
                       props.setAlert(res.data.results, "error");

                     }
                    })
                     .catch(err => {
                       props.setAlert(err.response.data.results, "error");
                       setLoading(false)
                      })
      }
    }

   
  return (
    <React.Fragment>
      <NotificationBar title="Update Password" />

      <form className={styles.updatePassword}>
        <h4>Change Password</h4>
        <div className={styles.inputs}>
          <label htmlFor="theme">Old Password:</label>
          <input
            type="password"
            name ="old_password"
            placeholder="Old Password"
            className={styles.inputOne}
            onChange = {handleChangedPassword}
         
          />
        </div>
        <div className={styles.inputs}>
          <label htmlFor="theme">New Password:</label>
          <input
            type="password"
            name="new_password"
            className={styles.inputOne}
            placeholder="New Password"
            onChange = {handleChangedPassword}
           
          />
        </div>
        <div className={styles.inputs}>
          <label htmlFor="theme">Re-type Password:</label>
          <input
            placeholder="Re-type New Password"
            type="password"
            name='confirm_new_password'
            className={styles.inputOne}
            onChange = {handleChangedPassword}
      
          />
        </div>

        <div className={styles.button}>
          <Button loading={loading} button="Update Password" onClick={handleChangedPasswordSubmit} />
        </div>
      </form>
    </React.Fragment>
  );
};

export default connect(null, { setAlert })(UpdatePassword);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import Skeleton from '@material-ui/lab/Skeleton';
import FileIcon from "../../fileIcon.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "./Navbar.css";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";



const CustomizedTab = (props) => {
  const history = useHistory()
  const { taskTitle } = props;
  const { projectID } = props;
  const { projectslist } = props;
  const { taskID } = props;
  const { proID } = props;
  const { projectTitle } = props;
  const [projects, setProjects] = useState(null);
  const [project, setProject] = useState(null);

  const handleActive = (event) => {
    const links = document.querySelectorAll("#links");
    Array.from(links).forEach((link) => link.classList.remove("active"));
    event.target.classList.add("active");
  };


  useEffect(() => {

    if (proID) {
      axiosInstance.get(API.GetSingleProject + `${parseInt(proID)}/`)
        .then(res => setProjects(res.data))
        .catch(err => console.log(err))
    }



    // const [ project ] = projects;
    // setProject(project)


  }, [])




  // useEffect(() => {
  //   if(projectID){
  //     
  //   else{
  //     console.log("Nothing")
  //   }
  // }, [])


  return (
    <>
      <div className="imgTag">
        {props.taskRoom ? <span className="backArrow" onClick={() => history.push(`/task/${projects.title.replace(/\\|\//g, '')}/${projects.id}`)}><ArrowBackIcon /></span> : null}
        <div className="imageWrapper">
          <img src={FileIcon} alt="File Icon" />
        </div>

        {props.taskRoom ? (

          <div className="navBarOuterWrapper ">

            <div className="projectName">
              <h5
                style={{ fontSize: "18px", width: "auto", fontWeight: "550" }}
              >
                Project : {projects ? projects.title : <span style={{ fontWeight: '100' }}><i className="fa fa-refresh fa-spin"></i></span>} / Task Details: {taskTitle}
                {/* <button
                  onClick={() => history.push(`/task/${project.title}/${project.id}`)}
                  className="backArrow"
                >
                  <ArrowBackIosIcon
                    style={{
                      marginLeft: "10px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  />
                  Back
                </button> */}
              </h5>
            </div>
            <div className="navbarInnerWrapper">
              <ul>
                <li>
                  <NavLink
                    id="links"
                    to={`/task/${proID}/taskJournal/${taskTitle}/${taskID}`}
                    onClick={handleActive}
                  >
                    Task Journal
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/task/${proID}/members/${taskTitle}/${taskID}`}
                    onClick={handleActive}
                  >
                    Task Members
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/task/${proID}/document/${taskTitle}/${taskID}`}
                    onClick={handleActive}
                  >
                    Task Documents
                  </NavLink>
                </li>
                {/* <li>
                    <NavLink id='links' to={`/task/${proID}/snapshot/${taskTitle}/${taskID}`} onClick={handleActive} >Snapshot</NavLink>
                </li> */}
                <li>
                  <NavLink
                    id="links"
                    to={`/task/${proID}/actionItems/${taskTitle}/${taskID}`}
                    onClick={handleActive}
                  >
                    Task Action Items
                  </NavLink>
                </li>

              </ul>

            </div>

          </div>
        ) : (
          <div className="navBarOuterWrapper ">
            <div className="projectName">
              <h5
                style={{ fontSize: "18px", width: "auto", fontWeight: "550" }}
              >
                Project: {projectTitle}
              </h5>
              {/* <span style={{ marginLeft: "5px", width: "50%" }}>
                <LinearProgressWithLabel
                  projectID={projectID}
                  done={props.done}
                />
              </span> */}
            </div>
            <div className="navbarInnerWrapper">
              <ul>
                <li>
                  <NavLink
                    id="links"
                    to={`/conversation/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    Conversation
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/task/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    Task
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/members/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    Members
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/document/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    Project Info
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/Alldocument/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    All Documents
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/snapshot/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    Snapshot
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="links"
                    to={`/actionItems/${projectTitle}/${projectID}`}
                    onClick={handleActive}
                  >
                    All Action Items
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { projectslist: state.projectList };
};

export default connect(mapStateToProps)(CustomizedTab);

import API from "../urls/index";
import axiosInstance from "../utils/axiosConfig";
import {
  USER_INFO_FETCH_SUCCESS,
  USER_INFO_FETCH_FAIL,
} from "../action_types";




export const getUserProfileInfo = (payload) => (dispatch) => {
  axiosInstance
    .get(API.GetUserProfileInfo)
    .then((res) => {
      dispatch({
        type: USER_INFO_FETCH_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: USER_INFO_FETCH_FAIL, payload: err });
    });
};

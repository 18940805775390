import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import {
  Login,
  NascentCompany,
  Details,
  ListOfConferences,
} from "./containers";

import {
  Home,
  Home2,
  MyItems,
  Conferences,
  ProjectMember,
  ProjectDocument,
  ActionItems,
  UserProfile,
  CompanyProfile,
  Conversation,
  ProjectActionItems,
  ProjectSnapShot,
  ProjectTask,
  AllDocumentPage,
} from "./containers";

import {
  TaskConversation,
  NotificationPopOver,
  Slider,
  UpdatePassword,
  MultipleCompany,
  Admins,
  ForgotPassword,
  AcceptCollabReq,
  ResetPasswordForm,
  TaskMembers,
  TaskSnapshot,
  TaskAction,
  TaskDocuments,
  ContactMessages,
  ConferenceEditModal,
  ViewRequest,
} from "./components";

import DefaultLayout from "./hoc/DefaultLayout";
import MaterialLayout from "./hoc/MaterialLayout";
import RouteWithLayout from "./hoc/RouteWithLayout";
// import ChatRoom from "./containers/Chat/ChatRoom";
// import ChatContainer from "./containers/Chat";

// import ChatContextProvider from "./containers/Chat/ChatContextProvider";

// const Test = () => {
//   return <h1>Test page</h1>;
// };

const App = () => (
  <Provider store={store}>
    {/* <ChatContextProvider> */}
    <Router>
      <Switch>
        <RouteWithLayout
          exact
          path="/"
          component={Home2}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/update-password"
          component={UpdatePassword}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          path="/slider"
          component={Slider}
          layout={DefaultLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/myitems/:companyId"
          component={MyItems}
          layout={MaterialLayout}
          private={true}
        />
        <RouteWithLayout
          layout={DefaultLayout}
          exact
          path="/login"
          component={Login}
          private={false}
        />
        <RouteWithLayout
          exact
          path="/conference-edit/:confId"
          component={ConferenceEditModal}
          layout={DefaultLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/company-profile-update"
          component={CompanyProfile}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/admins"
          component={Admins}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/multiple-company"
          component={MultipleCompany}
          layout={DefaultLayout}
          private={true}
        />
        <RouteWithLayout
          exact
          path="/Alldocument/:title/:id"
          component={AllDocumentPage}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/user-profile-update"
          component={UserProfile}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/conferences"
          component={Conferences}
          layout={MaterialLayout}
          private={true}
        />

        <Route exact path="/notification" component={NotificationPopOver} />

        <RouteWithLayout
          exact
          path="/reset"
          component={ResetPasswordForm}
          layout={DefaultLayout}
        />
        <RouteWithLayout
          exact
          path="/accept-req/:reqId"
          layout={DefaultLayout}
          component={AcceptCollabReq}
        />

        <RouteWithLayout
          exact
          path="/reset/:token"
          component={ForgotPassword}
          layout={DefaultLayout}
        />
        <RouteWithLayout
          exact
          path="/task/:title/:id"
          component={ProjectTask}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/details"
          layout={DefaultLayout}
          component={Details}
          private={true}
        />
        <RouteWithLayout
          exact
          path="/company/:companyId"
          layout={DefaultLayout}
          component={NascentCompany}
          private={true}
        />
        <RouteWithLayout
          exact
          path="/contact-messages"
          layout={DefaultLayout}
          component={ContactMessages}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/members/:title/:id"
          component={ProjectMember}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/document/:title/:id"
          component={ProjectDocument}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/actionitems/:title/:id"
          component={ProjectActionItems}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/list-of-conferences"
          component={ListOfConferences}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/conversation/:title/:id"
          component={Conversation}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/snapshot/:title/:id"
          component={ProjectSnapShot}
          layout={MaterialLayout}
          private={true}
        />
        <RouteWithLayout
          exact
          path="/task/:id/members/:tasktitle/:taskId"
          component={TaskMembers}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/task/:id/actionitems/:tasktitle/:taskId/"
          component={TaskAction}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/view-req/:viewId"
          component={ViewRequest}
          layout={DefaultLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/task/:id/document/:tasktitle/:taskId"
          component={TaskDocuments}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/task/:id/taskJournal/:tasktitle/:taskId"
          component={TaskConversation}
          layout={MaterialLayout}
          private={true}
        />

        <RouteWithLayout
          exact
          path="/task/:id/snapshot/:tasktitle/:taskId"
          component={TaskSnapshot}
          layout={MaterialLayout}
          private={true}
        />
      </Switch>
    </Router>
    {/* </ChatContextProvider> */}
  </Provider>
);

export default App;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  NotificationBar,
  BasicTable,
  CustomizedTab,
  Modal,
  ProjectButton,
  Slider
} from "../../components";
import { setAlert } from "../../actions/alert";
import styles from "./document.module.css";

const Document = (props) => {
  
  const { taskTitle } = props;
  const { projectID } = props;
  const { proID } = props;
  const [ showDrawer, setShowDrawer ] = useState(false)
  const { projectTitle } = props;
  const [ ID, setID ] = useState();
  const [openDocument, setOpenDocument] = useState(false);
  const [ openResubmissionForm, setOpenResubmissionForm ] = useState(false)

  return (
    <React.Fragment>
      <NotificationBar
        tabs={
          <CustomizedTab
          done = "70"
            projectTitle={projectTitle}
            projectID={projectID}
            proID={proID}
            taskRoom={props.taskRoom}
            taskTitle={taskTitle}
            taskID={props.taskID}
          />
        }
        topButton="button"
      />

      <main>
        { showDrawer ? <Slider onClick={() => setOpenResubmissionForm(!openResubmissionForm)} ID={ID} showDrawer ={showDrawer} /> : null}
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "100%" }}>
            <Grid container>
              <Grid container>
                <Grid item lg={12} style={{ margin: "15px 0px" }}>
                  <ProjectButton
                    text="+ Add Document"
                    onClick={() => setOpenDocument(!openDocument)}
                  />
                </Grid>
              </Grid>

              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <div
                  style={{ borderBottom: "1px solid rgba(224,224,224,1)" }}
                ></div>
                <BasicTable
                  document="document"
                  taskDocument={props.taskDocument}
                  sno="S.No"
                  name="Name of Document"
                  status="Status"
                  action="Action"
                  getProjectDocumentList={props.getProjectDocumentList}
                  showDrawer= {() => {
                    setShowDrawer(!showDrawer);
                   
                  }}
                  getIDForDrawer={(id) => setID(id)}
                />
              </Grid>
            </Grid>
            {showDrawer ? <div onClick={ () => setShowDrawer(!showDrawer)} className={styles.showDrawer}></div> : null}
          </div>
        </div>
      </main>
      <Modal
        openPop={openDocument}
        onClick={() => setOpenDocument(!openDocument)}
        backdropClicked={() => setOpenDocument(!openDocument)}
        title="Add Document"
        button="Add Document"
        status="status"
        setAlert={props.setAlert}
        projectID={projectID}
        proID={proID}
        upload="upload"
        taskRoom={props.taskRoom}
        taskID={props.taskID}
        getTaskDocumentsList={props.getTaskDocumentsList}
        getProjectDocumentList={props.getProjectDocumentList}
      ></Modal>
    </React.Fragment>
  );
};

export default connect(null, { setAlert })(Document);

import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectButton } from '../../components';
import { Toolbar, AppBar } from '@material-ui/core';
import { Home2 } from '../../containers';
import styles from './multiplecompany.module.css';
import { Close } from '@material-ui/icons';


const MultipleCompany = (props) => {
  const history = useHistory()
  const [ companyData , setCompanyData ] = useState(null)

    const handleMultipleCompany = (id, title, url) =>{
      // setCompanyData({id : id, title: title, url: url}) 


      localStorage.setItem("companyId", id)
      localStorage.setItem("companyTitle", title)
      localStorage.setItem("companyPhoto", url)
      
     history.push('/')
    }
    // useEffect(() => {
    //   if(companyData){
    //    history.push('/')
    //   }
    // }, [companyData])

    // localStorage.setItem("CompanyVal", companyData);

    return (
        <>
        <AppBar style={{ backgroundColor: "#091936" }} position="static">
        <Toolbar
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Nascent</h3>
         <Close onClick={() => history.push('/')} />
        </Toolbar>
      </AppBar>
          <div className={styles.multipleCompany}>
          <h4>Companies You are Involved In! Select Company You Want to Redirect to</h4>
          {props.company.companies.map(company => (
            <div className={styles.multipleButton} key={company.id}>
          <ProjectButton text={company.title} onClick={() => handleMultipleCompany(company.id, company.title, company.url)} />
          </div>
          ))}
         </div>
        </>
    )
}

const mapStateToProps = (state) => {
  return { company : state.auth.data}
}


export default connect(mapStateToProps)(MultipleCompany);

import React, { useEffect, useState } from 'react';
import TaskSnapshotContext from './TaskSnapshotContext';
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import { SnapShot } from '../../containers';
import axiosInstance from '../../utils/axiosConfig';
import API from '../../urls/index';

const TaskSnapshot = (props) => {
 
    const taskTitle = props.computedMatch.params.tasktitle;

    const taskID = props.computedMatch.params.taskId;
    const proID = props.computedMatch.params.id;
    
    const [ loader, showLoader, hideLoader ] = useFullPageLoader();
    
    const [ snapshotView, setSnapshotView ] = useState(null);

    useEffect(() => {
        showLoader()
       axiosInstance.get(API.TaskSnapshot + `${taskID}/snapshot/`)
                    .then(res => {
                        setSnapshotView(res.data.results);
                        hideLoader()
                    })
    }, [])
  
    return (
       <TaskSnapshotContext.Provider value={[snapshotView, loader]}>
            <SnapShot taskRoom="Task Room" taskID={taskID} taskTitle={taskTitle} proID={proID} />
       </TaskSnapshotContext.Provider>
    )
}

export default TaskSnapshot;
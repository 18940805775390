import React from "react";

import styles from "./conferencedetail.module.css";

const ConferenceDetail = (props) => {
  return (
    <div className={styles.confDetail}>
      <label>{props.label}</label>
      <textarea
        type={props.type}
        rows="3"
        minLength={props.maxlength}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        required
        title="This field can not be empty"
      />
    </div>
  );
};

export default ConferenceDetail;

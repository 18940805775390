import React, { useContext } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Hidden, Paper } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { SimpleMenu } from "../../components";
import Visibility from "@material-ui/icons/Visibility";

import AllDocumentContext from "../../containers/AllDocumentPage/AllDocumentContext";
import { Info } from "@material-ui/icons";
import { formatDate } from "../../utils/dateTimeUtility";

const TaskTable = (props) => {
  const [allDocuments, loader, showSlider] = useContext(AllDocumentContext);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow className="largeScreen">
            <TableCell>
              <h6>S. No</h6>
            </TableCell>
            <TableCell align="center">
              <h6>Task Name</h6>
            </TableCell>
            <TableCell align="center">
              <h6>Name of Document</h6>
            </TableCell>
            <TableCell align="center">
              <h6>Submitted By</h6>
            </TableCell>
            <TableCell align="center">
              <h6>Status</h6>
            </TableCell>
            <TableCell align="center">
              <h6>Action Date</h6>
            </TableCell>
            <TableCell>
              <h6>Logs</h6>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!allDocuments ? (
            <TableRow>{loader}</TableRow>
          ) : (
            allDocuments.map((document, index) => (
              <React.Fragment key={document.id}>
                <TableRow className="largeScreen">
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="center">
                    {document.task ? document.task_name : "No Document in Task"}
                  </TableCell>
                  <TableCell align="center">
                    {document.name}{" "}
                    <a
                      href={`http://nascent.link${
                        document.document_file[
                          document.document_file.length - 1
                        ].file
                      }`}
                      target="_blank"
                    >
                      <Visibility fontSize="small" style={{ color: "gray" }} />
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    {
                      document.document_file[document.document_file.length - 1]
                        .document_status[
                        document.document_file[
                          document.document_file.length - 1
                        ].document_status.length - 1
                      ].first_name
                    }{" "}
                    {
                      document.document_file[document.document_file.length - 1]
                        .document_status[
                        document.document_file[
                          document.document_file.length - 1
                        ].document_status.length - 1
                      ].last_name
                    }
                  </TableCell>

                  <TableCell align="center">
                    {
                      document.document_file[document.document_file.length - 1]
                        .document_status[
                        document.document_file[
                          document.document_file.length - 1
                        ].document_status.length - 1
                      ].status
                    }
                  </TableCell>

                  <TableCell align="center">
                    {formatDate(
                      document.document_file[document.document_file.length - 1]
                        .document_status[
                        document.document_file[
                          document.document_file.length - 1
                        ].document_status.length - 1
                      ].action_date
                    )}
                  </TableCell>
                  <TableCell>
                    {document.document_file.map(
                      (file) =>
                        file.document_status.length > 1 ||
                        document.document_file.length > 1
                    ) ? (
                      <Info onClick={() => showSlider(document)} />
                    ) : (
                      "No Logs"
                    )}
                  </TableCell>
                </TableRow>

                <TableRow component={Paper} className="smallScreen">
                  <TableCell
                    align="left"
                    style={{
                      width: "40%",
                      lineHeight: "0.5rem",
                      padding: "20px",
                    }}
                  >
                    <p>S.No.</p>
                    <p>Document</p>
                    <p>Uploaded Date</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "40%",
                      lineHeight: "0.5rem",
                      padding: "10px",
                    }}
                  >
                    <p>{document.id}</p>
                    <p>{document.name}</p>
                    <p>
                      {" "}
                      {formatDate(
                        document.document_file[
                          document.document_file.length - 1
                        ].document_status[
                          document.document_file[
                            document.document_file.length - 1
                          ].document_status.length - 1
                        ].action_date
                      )}{" "}
                    </p>
                  </TableCell>
                  {/* <TableCell align="center" style={{ width: "20%" }}>
                  <SimpleMenu
                    edit="Edit"
                    delete="Delete"
                    deleteProject={() => deleteProjectDocument(document.id)}
                  />
                </TableCell> */}
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TaskTable;

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";

import useFullPageLoader from "../../hooks/UseFullPageLoader";
import { NascentDetails, NascentImage, AdminHeader } from "../../components";
import company from "../../img/company.svg";
import API from "../../urls/index";
import styles from "./nascentcompany.module.css";
import axiosInstance from "../../utils/axiosConfig";
import SingleCompanyDetailContext from "./SingleCompanyDetailContext";

const NascentCompany = (props) => {
  const companyId = props.computedMatch.params.companyId;
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [singleCompanyInfo, setSingleCompanyInfo] = useState(null);

  const history = useHistory();

  useEffect(() => {
    showLoader();
    axiosInstance
      .get(API.CompanyDetails + `${companyId}/`)
      .then((res) => {
        setSingleCompanyInfo(res.data);
        hideLoader();
      })
      .catch((err) => console.log(err.response));
  }, []);

  return (
    <>
      <AdminHeader />
      <SingleCompanyDetailContext.Provider value={singleCompanyInfo}>
        {singleCompanyInfo ? (
          <div className={styles.nascentOuterWrapper}>
            <div className={styles.nascentAvatar}>
              <Avatar
                src={
                  !singleCompanyInfo.owner
                    ? null
                    : singleCompanyInfo.owner.photo
                }
                variant="square"
                style={{ height: "80px", width: "100px" }}
              />
              <div className={styles.nascentHeaderText}>
                <h3>{singleCompanyInfo.title}</h3>
                <p className={styles.text}>
                  {singleCompanyInfo.owner &&
                  singleCompanyInfo.owner.payment_status
                    ? "Active"
                    : "Expired"}
                </p>
              </div>
            </div>
            <div className={styles.nascentInnerWrapper}>
              <NascentDetails />
              <NascentImage application={company} />
            </div>
          </div>
        ) : (
          <div>{loader}</div>
        )}
      </SingleCompanyDetailContext.Provider>
    </>
  );
};

export default NascentCompany;

import React, { useState, useEffect, useContext } from "react";

import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";

import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import Hidden from "@material-ui/core/Hidden";
import { CompanyUserContext } from "../../containers/Home2";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { setAlert } from "../../actions/alert";
import { getprojectlist } from "../../actions/projectList";
import PersonIcon from "@material-ui/icons/Person";

import { Modal, SimpleMenu, ProjectListTable } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "2px",
  },
})(MuiTableCell);

const TableBody = withStyles({
  root: {
    backgroundColor: "#fff",
  },
})(MuiTableBody);

const SimpleTable = (props) => {
  const [openNewProject, setOpenNewProject] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [ID, setID] = React.useState();
  const [companyId, setCompanyId] = useState(null);
  const [response, setResponse] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);

  const [companyUserData, loader] = useContext(CompanyUserContext);

  useEffect(() => {
    if (props.companyVal) {
      const company_id = props.companyVal;
      setCompanyId(company_id);
    } else {
      const [company_id] = props.companyId.companies;
      setCompanyId(company_id);
    }
  }, [companyId]);

  const handleCompanyUserDelete = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const DeleteUserCompanyConfirm = (ID) => {
    if (companyId) {
      axiosInstance
        .delete(
          API.RemoveCompanyMember + `${props.companyVal}/member/${ID}/remove/`
        )
        .then((res) => {
          if (res) {
            props.setAlert("User Deleted Successfully", "success");
            setOpenDelete(!openDelete);
            setResponse(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            props.setAlert(err.response.data.detail, "error");
            setOpenDelete(!openDelete);
          } else {
            props.setAlert("Something went wrong", "error");
            setOpenDelete(!openDelete);
          }
        });
    }
  };

  const splitDate = (date) => {
    const dat = date.split("T")[0];
    return dat;
  };

  // useEffect(() => {
  //   if(props.projectslist){

  //     props.projectslist.data.map(project => {
  //       db.collection("rooms").onSnapshot(snap => snap.docs.map(doc => {
  //         if(doc.data().projectId === project.id){
  //           setRoom(doc.id)
  //           setProjectId({ id : project.id, name: project.title})
  //         }

  //     }))

  //   })
  // }

  // if(room){
  //   db.collection("rooms").doc(room).get()
  //                    .then((docSnapshot) => {
  //                      if(docSnapshot.exists){
  //                        console.log("Do Nothing")
  //                      }
  //                      else{
  //                        db.collection("rooms").add({
  //                          projectId : projectId.id,
  //                          name : projectId.name
  //                        })
  //                      }
  //                    })
  // }
  // console.log(room)
  // }, [room])

  // console.log(projectId)

  // db.collection("rooms").onSnapshot(snap => snap.docs.map(doc => console.log(doc.data().projectId)))

  const getFilteredUsers = (companyUserData) => {
    if (response) {
      return companyUserData.filter((user) => user.id !== ID);
    } else {
      return companyUserData;
    }
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Table>
        <TableHead>
          {props.project ? (
            <>
              <TableRow>
                <Hidden smDown xsDown>
                  <TableCell style={{ width: "7%" }}>
                    <h6>{props.file}</h6>
                  </TableCell>
                  <TableCell style={{ width: "19%" }}>
                    <h6>{props.project}</h6>
                  </TableCell>
                  <TableCell style={{ width: "55%" }}>
                    <h6>{props.description}</h6>
                  </TableCell>
                  <TableCell align="center" style={{ width: "20%" }}>
                    <Button onClick={() => setOpenNewProject(!openNewProject)}>
                      {props.create}
                    </Button>
                  </TableCell>
                </Hidden>
              </TableRow>

              <TableRow>
                <Hidden mdUp>
                  <TableCell align="center" style={{ width: "15%" }}>
                    <h6>{props.file}</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "55%" }}>
                    <h6 style={{ marginLeft: "20px" }}>{props.project}</h6>
                  </TableCell>
                  <TableCell align="right" style={{ width: "30%" }}>
                    <Button onClick={() => setOpenNewProject(!openNewProject)}>
                      + Project
                    </Button>
                  </TableCell>
                </Hidden>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <Hidden smDown>
                  <TableCell style={{ width: "5%" }}>
                    <h6>S.No</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "20%" }}>
                    <h6>{props.name}</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "15%" }}>
                    <h6>{props.phnum}</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "25%" }}>
                    <h6>{props.email}</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "15%" }}>
                    <h6>{props.date}</h6>
                  </TableCell>

                  <TableCell align="center" style={{ width: "13%" }}>
                    <Button onClick={() => setOpenUser(!openUser)}>
                      {props.addUser}
                    </Button>
                  </TableCell>
                </Hidden>
              </TableRow>

              <TableRow>
                <Hidden mdUp>
                  <TableCell align="center" style={{ width: "15%" }}>
                    <h6>S.No.</h6>
                  </TableCell>
                  <TableCell align="left" style={{ width: "55%" }}>
                    <h6 style={{ marginLeft: "20px" }}>{props.name}</h6>
                  </TableCell>
                  <TableCell align="center" style={{ width: "30%" }}>
                    <Button onClick={() => setOpenUser(!openUser)}>
                      + User
                    </Button>
                  </TableCell>
                </Hidden>
              </TableRow>
            </>
          )}
        </TableHead>
      </Table>
      <div style={{ overflow: "auto", height: "29vh", marginTop: "-5px" }}>
        <Table
          style={{
            tableLayout: "fixed",
            border: "1px solid rgba(224,224,224,1)",
          }}
        >
          <TableBody>
            {props.project ? (
              <ProjectListTable
                company_id={companyId}
                setAlert={props.setAlert}
              />
            ) : (
              <>
                {!companyUserData ? (
                  <TableRow>{loader}</TableRow>
                ) : (
                  getFilteredUsers(companyUserData).map((user, index) => (
                    <TableRow key={user.id}>
                      <Hidden smDown>
                        <TableCell style={{ width: "5%" }} align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "20%" }}
                        >
                          <PersonIcon fontSize="small" />
                          {user.first_name} {user.last_name}
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          {user.contact}
                        </TableCell>
                        <TableCell align="left" style={{ width: "25%" }}>
                          {user.email}
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          {splitDate(user.join_date)}
                        </TableCell>

                        <TableCell align="center" style={{ width: "13%" }}>
                          <SimpleMenu
                            delete="Delete"
                            deleteProject={() =>
                              handleCompanyUserDelete(user.id)
                            }
                          />
                        </TableCell>
                      </Hidden>

                      <Hidden mdUp>
                        <TableCell
                          onClick={() =>
                            (window.location.href = "/conversation")
                          }
                          align="center"
                          style={{ width: "15%" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" style={{ width: "60%" }}>
                          <div style={{ marginLeft: "20px" }}>
                            <h6>{user.username}</h6>
                            <div style={{ color: "gray" }}>9808916325</div>
                            <div style={{ color: "gray" }}>{user.email}</div>
                            <p style={{ fontSize: "10px", color: "graytext" }}>
                              {splitDate(user.join_date)}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{ width: "25%" }}>
                          <SimpleMenu
                            edit="Edit"
                            delete="Delete"
                            newProjectEditButton={() =>
                              setOpenNewProject(!openNewProject)
                            }
                          />
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  ))
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <Modal
        openPop={openAdmin}
        onClick={() => setOpenAdmin(!openAdmin)}
        backdropClicked={() => setOpenAdmin(!openAdmin)}
        title="Appoint As Company Admin"
        button="Appoint Admin"
      />
      <Modal
        openPop={openUser}
        onClick={() => setOpenUser(!openUser)}
        backdropClicked={() => setOpenUser(!openUser)}
        title="Add User to your team"
        button="Add User"
        name="Name"
        phone="Phone"
        email="Email"
      ></Modal>
      <Modal
        noTitle={true}
        newProject="New Project"
        openPop={openNewProject}
        onClick={() => setOpenNewProject(!openNewProject)}
        fullWidth={true}
        id={ID}
        backToHome={() => setOpenNewProject(!openNewProject)}
      />
      <Modal
        openPop={openDelete}
        onClick={() => setOpenDelete(!openDelete)}
        backdropClicked={() => setOpenDelete(!openDelete)}
        deleteBar="Delete Bar"
        title="Confirm Delete"
        ConfirmDeleteData={() => DeleteUserCompanyConfirm(ID)}
        closeDialog={() => setOpenDelete(!openDelete)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    companyId: state.auth.data,
  };
};

export default connect(mapStateToProps, { getprojectlist, setAlert })(
  withRouter(SimpleTable)
);

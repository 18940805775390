import React  from "react";
import { withRouter } from "react-router-dom";

import { Grid } from "@material-ui/core";
import {
 
  TaskList,
  DocumentList,
  MembersList,
  NotificationBar,
  CustomizedTab,
} from "../../components";

import styles from "./snapshot.module.css";

// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/storage";

// import { useAuthState } from "react-firebase-hooks/auth";
// import { useCollectionData } from "react-firebase-hooks/firestore";

// import ChatContext from "../Chat/chatContext";

// const firebaseConfig = {
//   apiKey: "AIzaSyAJrs-oP-xp-V6ny_cV3Oy_DXTSBBuYtdM",
//   authDomain: "nascentchatapp.firebaseapp.com",
//   projectId: "nascentchatapp",
//   storageBucket: "nascentchatapp.appspot.com",
//   messagingSenderId: "614837209209",
//   appId: "1:614837209209:web:4e330263a00150bc8aeca8",
//   measurementId: "G-4MQFEL36B9",
// };

// firebase.initializeApp(firebaseConfig);
// const auth = firebase.auth();
// const firestore = firebase.firestore();
// const storage = firebase.storage();

const SnapShot = (props) => {
  const { taskTitle } = props;
  const { projectID } = props;
  const { proID } = props;
  const { projectTitle } = props;


  return (
    <React.Fragment>
      <NotificationBar
        // title="Snapshot"
        tabs={<CustomizedTab  done = "70" projectTitle={projectTitle} taskRoom={props.taskRoom} proID={proID} projectID={projectID} taskTitle={taskTitle} taskID={props.taskID} />}
        topButton="button"
      />

      <main className={styles.mainWrapper}>
     
          <div className={styles.chatWrapper}>
            <Grid container spacing={2}>
              {props.taskRoom ? null : 
              <Grid item md={6} xs={12}>
                
                <TaskList  /> 
              </Grid>}
              {props.taskRoom ? 
              <Grid item md={12} xs={12}>
                
                <DocumentList taskRoom = {props.taskRoom} />
                <MembersList taskRoom = {props.taskRoom}  />
              </Grid> : 
              <Grid item md={6} xs={12}>
                
                <DocumentList />
                <MembersList  />
              </Grid>}
            </Grid>
          </div>
       
      </main>
    </React.Fragment>
  );
};

export default withRouter(SnapShot);

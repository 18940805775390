import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { login } from "../../actions/auth";
import { getUserCompany } from "../../actions/company";
import { getUserProfileInfo } from "../../actions/userProfile";
import { updateCompany } from "../../actions/company";

import Avatar from "@material-ui/core/Avatar";

import { NotificationBar, Button } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";
import styles from "./profile.module.css";
import "./profile.css";
import { Modal } from "../../components";
import store from "../../store";

const Profile = (props) => {
  const history = useHistory();
  const PictureChangeRef = useRef();
  const [loading, setLoading] = useState(false);
  const [fileInput, setFileInput] = useState({ photo: "" });
  const [profileData, setProfileData] = useState({
    title: "",
    description: "",
    first_name: "",
    last_name: "",
    contact: "",
    username: "",
    photo: "",
  });
  const [openCancleSubscription, setSubscription] = useState(false);
  const [subsId, setSubsId] = useState();

  const companyProfileUpdate = () => {
    axiosInstance
      .get(API.CompanyUpdate + `${props.companyId.company_id}/`)
      .then((res) => {
        setProfileData({
          title: res.data.title,
          description: res.data.description,
          first_name: res.data.owner.first_name,
          last_name: res.data.owner.last_name,
          contact: res.data.owner.contact,
          username: res.data.owner.username,
          photo: res.data.owner.photo,
          premium_user: res.data.owner.premium_user,
        });
      });
  };

  useEffect(() => {
    axiosInstance
      .get(API.UserProfileUpdate)
      .then((res) => {
        setSubsId(res.data.subs_id);
      })
      .catch((err) => {});
  }, [store.getState().cancelSubscription.cancelled]);

  useEffect(() => {
    if (props.companyId.company_head) {
      companyProfileUpdate();
    } else {
      axiosInstance
        .get(API.UserProfileUpdate)
        .then((res) => {
          setProfileData({
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            contact: res.data.contact,
            username: res.data.username,
            photo: res.data.photo,
          });
        })
        .catch((err) => props.setAlert("Something went wrong", "error"));
    }
  }, []);

  const handleUserprofileUpdate = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const handleUserProfileSubmit = (event) => {
    event.preventDefault();
    if (props.companyId.company_head) {
      if (fileInput.photo === ``) {
        const data = {
          title: profileData.title,
          description: profileData.description,
          owner: {
            first_name: profileData.first_name,
            last_name: profileData.last_name,

            contact: profileData.contact,
          },
        };
        setLoading(true);
        // form_data.append("email", profileData.email);

        axiosInstance
          .patch(API.CompanyUpdate + "update/", data)
          .then((res) => {
            if (res) {
              props.setAlert("SuccessFully Updated Profile", "success");
              props.getusercompany();

              props.getuserprofileinfo();
              history.push("/");
            }
          })
          .catch((err) => props.setAlert(err.response.data.detail, "error"));
      } else if (fileInput.photo !== "") {
        let form_data = new FormData();
        form_data.append("photo", fileInput.photo);
        // console.log(postImage.document[0], document.name, document.status);
        // form_data.append("username", profileData.username);

        form_data.append("first_name", profileData.first_name);
        form_data.append("last_name", profileData.last_name);
        form_data.append("contact", profileData.contact);
        setLoading(true);
        axiosInstance
          .patch(API.UserProfileUpdate, form_data)
          .then((res) => {
            if (res) {
              props.setAlert("SuccessFully Updated Profile", "success");
              props.getuserprofileinfo();
              const data = {
                title: profileData.title,
                description: profileData.description,
                owner: {
                  first_name: profileData.first_name,
                  last_name: profileData.last_name,

                  contact: profileData.contact,
                },
              };

              axiosInstance
                .patch(API.CompanyUpdate + "update/", data)
                .then((res) => {
                  if (res) {
                    history.push("/");
                    props.getusercompany();

                    props.getuserprofileinfo();
                  }
                })
                .catch((err) =>
                  props.setAlert(err.response.data.detail, "error")
                );
              history.push("/");
            }
          })
          .catch((err) => props.setAlert(err.response.data.detail, "error"));
      }
    } else {
      if (fileInput.photo === ``) {
        const data = {
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          contact: profileData.contact,
          username: profileData.username,
        };
        setLoading(true);
        axiosInstance
          .put(API.UserProfileUpdate, data)
          .then((res) => {
            if (res) {
              props.setAlert("Data Updated Successfully", "success");
              props.getuserprofileinfo();
              history.push("/");
            } else {
              props.setAlert("Something went wrong", "error");
            }
          })
          .catch((err) => {
            if (err) {
              props.setAlert("something went wrong", "error");
            }
          });
      } else {
        let form_data = new FormData();
        form_data.append("photo", fileInput.photo);

        form_data.append("username", profileData.username);
        form_data.append("contact", profileData.contact);
        form_data.append("first_name", profileData.first_name);
        form_data.append("last_name", profileData.last_name);
        // form_data.append("username", profileData.last_name);
        setLoading(true);
        axiosInstance
          .put(API.UserProfileUpdate, form_data)
          .then((res) => {
            if (res) {
              props.setAlert("SuccessFully Posted Document", "success");
              props.getuserprofileinfo();
              history.push("/");
            }
          })
          .catch((err) => props.setAlert(err.response.data.detail, "error"));
      }
    }
  };

  const handleUserProfileChange = (e) => {
    const file = e.target.files[0];
    let validExts = new Array(".png", ".jpg", ".jpeg", ".gif");
    let fileExt = file.name.substring(file.name.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      alert(
        "Invalid file selected, valid files are of " +
          validExts.toString() +
          " types."
      );
      return false;
    }
    setFileInput({ [e.target.name]: file });
  };

  return (
    <React.Fragment>
      <NotificationBar
        title="Home"
        topButton="button"
        photo={profileData.photo}
      />
      <main>
        <div className={styles.userProfileWrapper}>
          <div style={{ position: "relative" }}>
            <Avatar src={profileData.photo} className="ProfileAvatar" />
          </div>
          <h5>
            {profileData.first_name} {profileData.last_name}
          </h5>
          <p>{profileData.username}</p>
          {props.companyId.company_head ? (
            <p>{profileData.premium_user ? "Premium" : "Standard"}</p>
          ) : null}
          <form encType="multipart/form-data">
            {/* <div className={styles.inputs}>
                   <label htmlFor="theme">Select Theme:</label>
                   <input type="text" className={styles.inputOne} />  
                </div> */}
            <div className={styles.inputs}>
              <label htmlFor="theme">Update picture:</label>
              <input
                type="file"
                onChange={handleUserProfileChange}
                // onChange={handleUserprofileUpdate}
                name="photo"
                className={styles.inputOne}
              />
            </div>

            <div className={styles.inputs}>
              <label htmlFor="theme">Change Phone:</label>
              <input
                type="text"
                onChange={handleUserprofileUpdate}
                value={profileData.contact}
                name="contact"
                className={styles.inputOne}
              />
            </div>
            <div className={styles.inputs}>
              <label htmlFor="theme">Change Firstname:</label>
              <input
                onChange={handleUserprofileUpdate}
                name="first_name"
                value={profileData.first_name}
                type="text"
                className={styles.inputOne}
              />
            </div>
            <div className={styles.inputs}>
              <label htmlFor="theme">Change Lastname:</label>
              <input
                type="text"
                onChange={handleUserprofileUpdate}
                value={profileData.last_name}
                name="last_name"
                className={styles.inputOne}
              />
            </div>
            {props.companyId.company_head ? (
              <>
                <div className={styles.inputs}>
                  <label htmlFor="theme">Change Title:</label>
                  <input
                    type="text"
                    onChange={handleUserprofileUpdate}
                    value={profileData.title}
                    name="title"
                    className={styles.inputOne}
                  />
                </div>
                <div className={styles.inputs}>
                  <label htmlFor="theme">Description:</label>
                  <input
                    type="text"
                    onChange={handleUserprofileUpdate}
                    value={profileData.description}
                    name="description"
                    className={styles.inputOne}
                  />
                </div>
              </>
            ) : null}

            <div className={styles.button}>
              <Button
                button="Update Profile"
                onClick={handleUserProfileSubmit}
                loading={loading}
              />
            </div>
          </form>
        </div>
        {props.companyId.company_head ? (
          <Link
            onClick={() => setSubscription(!openCancleSubscription)}
            className={styles.premium}
          >
            {subsId ? "Cancel Subscription" : null}
          </Link>
        ) : null}
      </main>
      <Modal
        openPop={openCancleSubscription}
        onClick={() => setSubscription(!openCancleSubscription)}
        backdropClicked={() => setSubscription(!openCancleSubscription)}
        title="Cancel Subscription"
        subs="subs"
        details="details"
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { companyId: state.auth.data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: () => dispatch(setAlert()),
    login: (username, password) => dispatch(login(username, password)),
    getusercompany: () => dispatch(getUserCompany()),
    getuserprofileinfo: () => dispatch(getUserProfileInfo()),
    updatecompany: () => dispatch(updateCompany()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

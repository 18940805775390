import React from "react";

import { formatDate } from '../../utils/dateTimeUtility';
import './documentslider.css';


const AllDocumentSlider = (props) => {
  const { documents } = props;


  return (
    <div className="sliderWrapper">

      <div className="slider">

        {documents ? (
          <div className="documentLog" key={documents.id}>
            <p className="sName">Document Logs</p>
            <hr />
            <div className="documentWrapper">
              <div style={{ height: "850px", overflow: "auto" }}>
                {documents.document_file.map((file) =>
                  file.document_status.map((status) => (
                    <React.Fragment key={status.id}>
                      <p>status: {status.status}</p>
                      <p>created by : {status.first_name} {status.last_name}</p>
                      <p>Created Date : {formatDate(status.action_date)}</p>
                      <hr />
                    </React.Fragment>
                  ))
                )}
              </div>

              <br />
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>


    </div>
  );
};

export default AllDocumentSlider;

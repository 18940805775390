import React, { useState, useContext } from "react";
import TaskActionItemsContext from "../../components/TaskActionItems/TaskActionItemsContext";

import {
  Button,
  DocumentModal,
  ProjectUsers
  
} from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import API from '../../urls/index';


const TaskModalUpdate = (props) => {
  const { editState } = props;

  const [ loading, setLoading ] = useState(false);
  const [updateData, setUpdateData] = useState({
    title: editState.title,

    due_date: editState.due_date,
    status: editState.status,
  });
  const [ assigned, setAssigned ] = useState({ assigned : [{ user : editState.assigned_to }]})

  const [taskActionItems, getTaskActionItems, proID] = useContext(
    TaskActionItemsContext
  );

  const handleTaskActionItemUpdate = (e) => {
      setUpdateData({...updateData, [e.target.name] : e.target.value})
  }

  const handleMembersFieldChange = (value) => {
    setAssigned({assigned : value})

    
 }

  const handleTaskActionItemSubmit = (e) => {
      e.preventDefault()
      const [ assignedTo ] = assigned.assigned;
      const data = {
          title : updateData.title,
          assigned_to : assignedTo.user.id,
          due_date : updateData.due_date,
          status : updateData.status,
          // status : updateData.status === "on" ? true : false
      }
      setLoading(true)
      axiosInstance.put(API.DeleteActionItem + `${editState.id}/update/`, data)
                   .then(res => {if(res){
                     props.onClick();
                     getTaskActionItems();
                     props.setAlert("Task Action Item updated Successfully", "success");
                     setLoading(false)

                   }})
                   .catch(err => {
                     props.onClick()
                     props.setAlert(err.response.data.detail, "error");
                     setLoading(false)
                    })
  }
  return (
    <>
    <ProjectUsers label="Assigned To" proID={proID} value={assigned.assigned} handleMembersFieldChange={handleMembersFieldChange}/>
      <DocumentModal type="text" name="title" label="Description" value={updateData.title} onChange={handleTaskActionItemUpdate} />
      
      <DocumentModal type="date" name="due_date" label="Due Date" value={updateData.due_date}  onChange={handleTaskActionItemUpdate}  />
      {/* <div style={{padding:'10px', margin:'0px 15px'}}>
        <label htmlFor="status" style={{margin:'10px'}} >Status</label>
        <input type="checkbox" name="status" checked={updateData.status ? true : false} value={updateData.status==="on" ? true : false } onChange={handleTaskActionItemUpdate} />
      </div> */}
      <Button loading={loading} button={props.button} onClick={handleTaskActionItemSubmit} />
    </>
  );
};

export default TaskModalUpdate;

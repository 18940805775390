import React, { useContext, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {  Modal, SimpleMenu } from "../../components";
import axiosInstance from "../../utils/axiosConfig";
import { setAlert } from "../../actions/alert";
import API from "../../urls/index";
import ProjectActionItemContext from "../../containers/ProjectActionItems/ProjectActionItemContext";

import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./action.css";


const ProjectActionItemsList = (props) => {
  const [actionItemsList, loader, getProjectActionItems] = useContext(
    ProjectActionItemContext
  );
  const [loading, setLoading] = useState(false);
  const [openActionItemEdit, setOpenActionItemEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [ID, setID] = useState(false);
  const [status, setStatus] = useState({ status: "" });
  const [ actionID, setActionID ] = useState(null);
  const [ loadingStatus, setLoadingStatus ] = useState(false);
  const [editActionItems, setEditActionItem] = useState({
    title: "",
    assigned_to: "",
    due_date: "",
    status : '',
  });
  const [response, setResponse] = useState(false);
  const [prevStatus, setPrevStatus] = useState(null);
  const deleteActionItem = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const deleteProjectActionItem = (ID) => {
    setLoading(true)
    axiosInstance
      .delete(API.DeleteActionItem + `${ID}/update/`)
      .then((res) => {
        if (res) {
          setOpenDelete(!openDelete);
          setResponse(true);
          props.setAlert("Action Item deleted successfully", "success");
          setLoading(false)
        }
      })
      .catch((err) => {
        props.setAlert(
          "You do not have permission to perform this action",
          "error"
        );
        setOpenDelete(!openDelete);
        setLoading(false)
      });
  };

  const getFilteredActionItems = (actionItemsList) => {
    if (response) {
      return actionItemsList.filter((items) => items.id !== ID);
    } else {
      return actionItemsList;
    }
  };

  const handleProjectActionItemUpdate = (id, title, assigned_to, due_date, status) => {
    setOpenActionItemEdit(!openActionItemEdit);
    setEditActionItem({
      id: id,
      title: title,
      assigned_to: assigned_to,
      due_date: due_date,
      status:status
    });
  };

  const handleStatusChange = (e) => {
    setStatus({[e.target.name]: e.target.value });
  };

  const handleStatusSubmit = () => {
    
    if (prevStatus.status === status.status) {
      setActionID(null)
    } else {
      setLoadingStatus(true)
      axiosInstance
        .patch(API.DeleteMyActionItem + `${actionID}/update/`, status)
        .then((res) => {
          if (res) {
            props.setAlert("Action Items Editted Successfully", "success");
            getProjectActionItems();
            setLoading(false);
            setLoadingStatus(false)
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
            setLoading(false);
            setLoadingStatus(false)
          }
        });
    }
  };
  const setPreviousStatus = (status, id) => {
    setStatus({status : status});
    setPrevStatus({ status: status });
    setActionID(id);
  
  };




 
  const handleCompleted = (id, status) => {

    setLoading(true);
    setID(id);
    if (!status) {
      axiosInstance
        .patch(API.DeleteMyActionItem + `${id}/update/`, { status: true })
        .then((res) => {
          if (res.status === 200) {
            props.setAlert("You completed This Action Item", "success");
            getProjectActionItems();
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
            setLoading(false);
          }
        });
    } else if (status) {
      axiosInstance
        .patch(API.DeleteMyActionItem + `${id}/update/`, { status: false })
        .then((res) => {
          if (res.status === 200) {
            getProjectActionItems();
            props.setAlert("Marked This Task Incomplete", "error");

            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            props.setAlert(
              "You Do not have permission to perform this action",
              "error"
            );
            setLoading(false);
          }
        });
    }
  };

  const CapitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const useOutsideClickRef = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleStatusSubmit();
         
          setActionID(null);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, status]);
  };





  const wrapperRef = useRef(null);
  useOutsideClickRef(wrapperRef);

  return !actionItemsList ? (
    <div>{loader}</div>
  ) : (
    getFilteredActionItems(actionItemsList).map((items, index) => (
      <React.Fragment key={items.id}>
    
          <TableRow style={{ borderBottom: "1px solid rgba(224,224,224,1)" }} className="largeScreen">
            <TableCell>{index+1}</TableCell>
            <TableCell component="th" scope="row" align="left">
              {items.title}
            </TableCell>
            <TableCell align="left">
              {items.owner.first_name} {items.owner.last_name}
              
            </TableCell>
            <TableCell align="left">
              {items.assigned_to.first_name} {items.assigned_to.last_name}
              
            </TableCell>
            <TableCell align="left">
              {items.due_date}
              
            </TableCell>
            <TableCell align="left">
              {!items.task ? "No Task" : items.task.title}
            </TableCell>
            <TableCell
              align="left"
            
              
            >
              {actionID === items.id ? (
                <select
                  ref={wrapperRef}
                  name="status"
                  onChange={handleStatusChange}
                >
                  <option value="incomplete" selected = {prevStatus.status === "incomplete" ? true : false}>Incomplete</option>
                  <option value="complete" selected = {prevStatus.status === "complete" ? true : false}>Complete</option>
                  <option value="parked" selected = {prevStatus.status === "parked" ? true : false}>Parked</option>
                </select>
              ) : (
                <div onClick={() => setPreviousStatus(items.status, items.id)}>
                  {actionID === items.id && loadingStatus ? <i className="fa fa-refresh fa-spin" aria-disabled></i> : CapitalizeFirstLetter(items.status)}
                  </div>
              )}
            </TableCell>
            <TableCell align="left">
              <SimpleMenu
                edit="Edit"
                delete="Delete"
                updateProject={() =>
                  handleProjectActionItemUpdate(
                    items.id,
                    items.title,
                    items.assigned_to,
                    items.due_date,
                    items.status
                  )
                }
                deleteProject={() => deleteActionItem(items.id)}
              />
            </TableCell>
            {/* <TableCell align="center">
        { loading && ID === items.id ? <i className="fa fa-refresh fa-spin" aria-disabled></i> : 
        <CheckCircleOutlineOutlinedIcon
            className={items.status ? "green" : "gray"}
            onClick={() => handleCompleted(items.id, items.status)}
          />}
        </TableCell> */}
            
          </TableRow>
    
          <TableRow component={Paper} className="smallScreen">
            <TableCell
              align="left"
              style={{
                width: "40%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>S.No.</p>
              <p>Action</p>
              <p>Name</p>
              <p>Due Date</p>
            </TableCell>
            <TableCell
              align="left"
              style={{
                width: "40%",
                lineHeight: "0.5rem",
                padding: "20px",
              }}
            >
              <p>{index+1}</p>
              <p>{items.title}</p>
              <p>{items.assigned_to.first_name} {items.assigned_to.last_name}</p>
              <p>{items.due_date}</p>
            </TableCell>
            <TableCell>
              <SimpleMenu edit="Edit" delete="Delete"  updateProject={() =>
                  handleProjectActionItemUpdate(
                    items.id,
                    items.title,
                    items.assigned_to,
                    items.due_date,
                    items.status
                  )
                }
                deleteProject={() => deleteActionItem(items.id)} />
               
            </TableCell>
          </TableRow>
       
          <Modal
          openPop={openActionItemEdit}
          onClick={() => setOpenActionItemEdit(!openActionItemEdit)}
          backdropClicked={() => setOpenActionItemEdit(!openActionItemEdit)}
          title="Update Action Item"
          button="Update Action Item"
          edit={true}
          editActionItems={editActionItems}
          projectID={props.projectID}
          getProjectActionItems={props.getProjectActionItems}
        />
        <Modal
          openPop={openDelete}
          onClick={() => setOpenDelete(!openDelete)}
          backdropClicked={() => setOpenDelete(!openDelete)}
          deleteBar="Delete Bar"
          title="Confirm Delete"
          loading={loading}
          ConfirmDeleteData={() => deleteProjectActionItem(ID)}
          closeDialog={() => setOpenDelete(!openDelete)}
        />
      </React.Fragment>
    ))
  );
};

export default connect(null, { setAlert })(ProjectActionItemsList);

import React from 'react';

import styles from './filter.module.css';


const Filter = (props) => {
    return(
        <>
        <form className={styles.filterForm} >
            <select name={props.name} placeholder="filter" onChange={props.onChange}>
                <option>Filter</option>
                <option>All</option>
                <option value={props.value1}>{props.opt1}</option>
                <option value={props.value2}>{props.opt2}</option>
            </select>
            <br />
        </form>
        </>
    )
}

export default Filter;
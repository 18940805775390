import React, { useState, useEffect, useContext } from "react";

import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls";
import { ConferenceModal, UserList, Button } from "../../components";
import ConferenceDetail from "../ConferenceDetail";
import styles from "./conferenceeditmodal.module.css";
import { setAlert } from '../../actions/alert';
import { formatDate } from "../../utils/dateTimeUtility";
import ProjectButton from "../ProjectButton";
import { Modal } from "@material-ui/core";



const ConferenceEditModal = (props) => {

  const history = useHistory();
  const confId = props.computedMatch.params.confId;
  const [openMembers, setOpenMembers] = useState(false);
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conferenceMembers, setConferenceMembers] = useState(null);
  const [members, setMembers] = useState(null);
  const [conference, setConference] = useState({
    id: '',
    title: '',
    start: '',
    end: '',
    project: '',
    description: '',
    project_name: '',
    meeting_name: '',
    members: [],
    date: ''
  });



  const [conferenceData, setConferenceData] = useState(null);



  const getConferenceData = () => {
    axiosInstance.get(API.GetConferenceList + `${confId}/`)
      .then(res => {
        if (res) {

          setConference({
            id: res.data.id,
            title: res.data.title,
            start: new Date(res.data.start).toLocaleTimeString(),
            end: new Date(res.data.end).toLocaleTimeString(),
            project: res.data.project,
            description: res.data.description,
            project_name: res.data.project_name,
            meeting_name: res.data.meeting_name,
            date: res.data.start.split("T")[0]
          })

          setConferenceData({
            id: res.data.id,
            title: res.data.title,
            start: new Date(res.data.start).toLocaleTimeString(),
            end: new Date(res.data.end).toLocaleTimeString(),
            project: res.data.project,
            description: res.data.description,
            project_name: res.data.project_name,
            meeting_name: res.data.meeting_name,

          })
          setConferenceMembers({
            members: res.data.members
          })
        }
      })
      .catch(err => {
        if (err) {
          props.setAlert("Something went wrong", "error")
        }
      })
  }

  useEffect(() => {
    getConferenceData()

  }, [])

  const handleMembersFieldChange = (value) => {
    setMembers({ members: value })
  }


  const handleConferenceChange = (e) => {
    setConference({ ...conference, [e.target.name]: e.target.value });

  };







  const handleRemoveMember = (id) => {
    axiosInstance.post(API.SaveNewConference + `${conference.id}/delete_members/`, { members: [id] })
      .then(res => {
        if (res) {
          setResponse({ val: true, id: id })

          getConferenceData()
          props.setAlert("Removed Successfully", "success")

        }
      })
      .catch(err => {
        if (err.response.status === 400) {
          props.setAlert("You Do not have permission to perform this acton", "error")
        }
        else {

          props.setAlert("Something went wrong", "error")
        }
      })
  }

  const getFilteredMembers = (members) => {
    if (response) {
      return members.filter(mem => mem.id !== response.id);
    }
    else {
      return members
    }
  }

  const submitNewConference = (e) => {
    e.preventDefault();




    setLoading(true)
    if (!members) {
      //  const date = new Date();
      //  var year = date.getFullYear();
      //  var mnth = date.getMonth();
      //  var day = date.getDate();
      //  console.log(`${year}-${mnth}-${day}`)

      if (conference.start === conferenceData.start) {
        var start_time = getFormattedTime(conference.start)
      }
      else {
        var start_time = conference.start
      }
      if (conference.end === conferenceData.end) {
        var end_time = getFormattedTime(conference.end)
      }
      else {
        var end_time = conference.end
      }
      var data = {
        title: conference.title,
        meeting_name: conference.meeting_name,
        company: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : props.info.companies[0].id,
        project: conference.project,
        project_name: conference.project_name,
        // date : conference.date,
        description: conference.description,
        start: new Date(conference.date + "T" + start_time),
        end: new Date(conference.date + "T" + end_time),

      };
      axiosInstance
        .put(API.SaveNewConference + `${conference.id}/`, data)
        .then((res) => {
          if (res) {

            props.setAlert("Conference added successfully", "success");

            // props.getUpcomingConference();
            history.push('/conferences')
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err) {

            props.setAlert("Failed to added conference", "error");

            setLoading(false);
          }
        });

    }

    else if (members.members.length !== 0) {
      const newArr = []
      members.members.map(mem => newArr.push(mem.id))
      var meeting_data = {
        members: newArr
      }

      if (conference.start === conferenceData.start) {
        var start_time = getFormattedTime(conference.start)
      }
      else {
        var start_time = conference.start
      }
      if (conference.end === conferenceData.end) {
        var end_time = getFormattedTime(conference.end)
      }
      else {
        var end_time = conference.end
      }
      var data = {
        title: conference.title,
        meeting_name: conference.meeting_name,
        company: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : props.info.companies[0].id,
        project: conference.project,
        project_name: conference.project_name,

        description: conference.description,
        start: new Date(conference.date + "T" + start_time),
        end: new Date(conference.date + "T" + end_time),

      };

      axiosInstance
        .put(API.SaveNewConference + `${conference.id}/`, data)
        .then((res) => {
          if (res) {

            props.setAlert("Conference Updated successfully", "success");



            setLoading(false)
          }
        })
        .catch((err) => {
          if (err) {

            props.setAlert("Failed to added conference", "error");

            setLoading(false);
          }
        });


      axiosInstance.post(API.SaveNewConference + `${conference.id}/add_members/`, meeting_data)
        .then(res => {
          if (res) {
            history.push('/conferences');

            setLoading(false)
          }
        })
        .catch(err => {
          if (err) {
            props.setAlert("You do not have permission to perform this action", "error")
            setLoading(false)
          } else {

            props.setAlert("Something went wrong", "error")
            setLoading(false)
          }
        })
    }




  };

  const getFormattedTime = (time) => {
    var t = time;
    if (t.split(' ')[1] === "PM") {
      if (t.split(':')[0] <= 12) {
        return ((parseInt(t.split(':')[0]) + 12) + ":" + t.split(":")[1] + ":" + "00")
      }
      else {
        return t
      }
    }
    else {
      if (t.split(':')[0].length === 1) {
        return ("0" + t.split(':')[0] + ":" + t.split(':')[1] + ":" + "00")
      }
      else {
        return t.split(':')[0] + ":" + t.split(':')[1] + ":" + "00"
      }
    }
  }



  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ width: '90%', margin: 'auto' }}>
        <Close onClick={() => history.push('/conferences')} />
      </div>
      <div style={{ width: '70%', margin: "auto" }}>

        <div className={styles.memberEditWrapper}>
          <div style={{ width: "50%" }}>
            <div className={styles.membersEdit}>
              <div className={styles.membersVal}>Members In This Conference</div>
              <div style={{ width: '87%' }}>
                <hr />
              </div>
              <div className={styles.memberEditTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conferenceMembers && getFilteredMembers(conferenceMembers.members).map(mem => (
                    <TableRow key={mem.id}>
                      <TableCell>{mem.first_name} {mem.last_name} </TableCell>
                      <TableCell>{mem.username}</TableCell>
                      <TableCell><Close fontSize="small" color="secondary" onClick={() => handleRemoveMember(mem.id)} /></TableCell>
                    </TableRow>))}
                </TableBody>

              </div>
            </div>

          </div>
          <form
            className={styles.confSection}
            onSubmit={(e) => submitNewConference(e)}
            key={conference.id}
          >

            <span onClick={() => setOpenMembers(!openMembers)} style={{ marginLeft: '20px', marginBottom: '20px', cursor: "pointer", border: '1px solid rgba(224,224,224,1)', padding: '8px', borderRadius: '8px' }}> + Add Members </span>
            <div style={{ width: '92%', margin: 'auto' }}>
              <hr />
            </div>
            {openMembers ? <UserList handleMembersFieldChange={handleMembersFieldChange} /> : null}
            <ConferenceModal
              htmlFor="title"
              type="text"
              label="Room Name"
              name="title"
              type="withButton"
              value={conference && conference.title}

              onChange={handleConferenceChange}
            />

            <ConferenceModal
              htmlFor="title"
              type="text"
              label="Title"
              name="meeting_name"
              value={conference && conference.meeting_name}

              onChange={handleConferenceChange}
            />

            <div className={styles.projectSelect}>
              <label htmlFor="Project" className={styles.label}>Project</label><select name="project_name" value={conference && conference.project_name} className={styles.selectBox} onChange={handleConferenceChange} name="project">

                {props.list.map(list => (
                  <option typeof="number" value={list.id}>{list.title}</option>
                ))}
              </select>
            </div>




            <ConferenceModal
              htmlFor="start"
              type="time"
              label="Start Time"
              name="start"
              value={getFormattedTime(conference && conference.start)}
              onChange={handleConferenceChange}
            />

            <ConferenceModal
              htmlFor="date"
              type="date"
              label="Date"
              name="date"
              value={conference && conference.date}
              onChange={handleConferenceChange}
            />




            <ConferenceModal
              htmlFor="end"
              type="time"
              label="End Time"
              name="end"
              value={getFormattedTime(conference && conference.end)}
              onChange={handleConferenceChange}
            />


            <ConferenceDetail
              htmlFor="description"
              type="text"
              label="Description"
              name="description"
              value={conference && conference.description}
              onChange={handleConferenceChange}
            />


            <Button button="Update Conference" loading={loading} type="submit" />

          </form>

        </div>
      </div>
    </div>
  )

}


const mapStateToProps = (state) => {
  return { list: state.projectList.data, info: state.auth.data }
}


export default connect(mapStateToProps, { setAlert })(ConferenceEditModal);
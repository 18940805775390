import React, { useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import ProjectSnapshotContext from '../../containers/ProjectSnapshot/ProjectSnapshotContext';
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { SimpleMenu } from '../../components';


const useStyles = makeStyles({
  table: {
    backgroundColor: "white",
    borderRadius:'3px',
  },
  p: {
    marginTop: "15px",
    marginBottom: "5px"
  }
});

const TaskList = (props) => {
  const classes = useStyles();
  const getDuration = (duration) => {
    const dur = duration.split(',')[0]
    return dur;
  }

  const [ snapshotView , loader ] = useContext(ProjectSnapshotContext)

  return (
    <React.Fragment>
      <p>Tasks</p>

      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className="table-row-data">
              <TableCell>Task ID</TableCell>
              <TableCell>Task Name</TableCell>
              <TableCell>Duration</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {!snapshotView ? <TableRow><TableCell><i className="fa fa-refresh fa-spin"></i>Loading...</TableCell></TableRow> :
            snapshotView.tasks.map((task, index) => (
              <TableRow key={task.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{task.title}</TableCell>
              <TableCell>{getDuration(task.get_duration)}</TableCell>
           
            </TableRow>
            ))}
            
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

TaskList.propTypes = {};

export default TaskList;

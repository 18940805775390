import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCEHggRjN-Yc8mpYtjSMFLLJiloglhtre4",
  authDomain: "nascent-e3db9.firebaseapp.com",
  projectId: "nascent-e3db9",
  storageBucket: "nascent-e3db9.appspot.com",
  messagingSenderId: "620138980066",
  appId: "1:620138980066:web:2a0fffee1c27dbc447b4bb",
  measurementId: "G-7JDMSR15Y1",
};

const fire = firebase.initializeApp(firebaseConfig);

const db = fire.firestore();

export default db;

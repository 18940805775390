import React, { useContext } from 'react';
import styles from './nascentdetail.module.css';
import { formatDate } from '../../utils/dateTimeUtility';
import SingleCompanyDetailContext from '../../containers/NascentCompany/SingleCompanyDetailContext';

const NascentDetails = () => {
    const singleCompanyInfo= useContext(SingleCompanyDetailContext);
    
    
    return (
        <div className={styles.nascentInnerText}>
            <div>
              <p>Email:</p>
              <p className={styles.text}>{singleCompanyInfo.owner && singleCompanyInfo.owner.username}</p>
            </div>
            <div>
              <p>Phone:</p>
              <p className={styles.text}>{singleCompanyInfo.owner ? singleCompanyInfo.owner.contact : "No Contact"}</p>
            </div>
            
            <div>
               <p>status:</p>
               <p className={styles.text}>{singleCompanyInfo.owner && singleCompanyInfo.owner.payment_status ? "Active": "Expired"}</p>
            </div>
            <div>
               <p>Subscription :</p>
               <p className={styles.text}>{singleCompanyInfo.owner && singleCompanyInfo.owner.premium_user ? "Premium" : "Standard"}</p>
            </div>
            <div>
               <p>Payment Date :</p>
               <p className={styles.text}>{singleCompanyInfo.com_customer ? formatDate(singleCompanyInfo.com_customer.subscription_start_date) : "No Date Info"}</p>
            </div>
            <div>
               <p>Account Expiration Date :</p>
               <p className={styles.text}>{singleCompanyInfo.com_customer ? formatDate(singleCompanyInfo.com_customer.subscription_end_date) : "No Date Info"}</p>
            </div>
            
        </div>
    )
}

export default NascentDetails;
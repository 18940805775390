import {
    GET_NOTIFICATION, ERROR_TO_GET_NOTIFICATIONS
 } from '../action_types';
 
 const initialState = { notification: null }
 
 
 export const notificationList = (state=initialState, action) => {
     switch(action.type){
         case GET_NOTIFICATION:
             return {...state, notification: action.payload}
         case ERROR_TO_GET_NOTIFICATIONS:
             return {...state , notification : "Error fetching Notifications"}     
         default:
             return state;    
     }
 }
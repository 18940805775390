
import { GET_PROJECT_LIST, PROJECTLIST_ERROR } from '../action_types';
import axiosInstance from '../utils/axiosConfig';
import API from '../urls/index';


export const getprojectlist = (companyId) => dispatch => {
            axiosInstance.get(API.ProjectList + `${companyId}/project/`)
                         .then(res => dispatch({type:GET_PROJECT_LIST, payload: res.data.results})) 
                         .catch(err => dispatch({type : PROJECTLIST_ERROR, payload: err}))               
    
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserList, Button } from '../../components';
import axiosInstance from '../../utils/axiosConfig';
import { setAlert } from '../../actions/alert';
import API from '../../urls/index';


const AppointAdmin = (props) => {

    const [addAdmin, setAddAdmin] = useState({ member_id: [] })
    const [loading, setLoading] = useState(false);
    const handleMembersFieldChange = (value) => {
        setAddAdmin({ member_id: value })
    }

    const handleAddAdminSubmit = (e) => {

        const data = {
            member_id: addAdmin.member_id.map(id => id.id),
            type: "add"
        }
        setLoading(true)
        axiosInstance.post(API.AppointAdmin + `${props.companyId}/admin/`, data)
            .then(res => {
                if (res.status === 200) {
                    props.getCompanyAdmins()
                    props.setAlert("Admin Added Successfully", "success");
                    props.onClick()
                    setLoading(true);
                }
            })
            .catch(err => {
                if (err) {
                    props.setAlert("You Don't have Permission to perform this action", "error");
                    props.onClick()
                    setLoading(true)
                }
            })
    }

    return (
        <>
            <div style={{ marginBottom: '50px' }}>
                <UserList handleMembersFieldChange={handleMembersFieldChange} />
            </div>
            <Button button={props.button} loading={loading} onClick={handleAddAdminSubmit} />
        </>
    )
}

const mapStateToProps = (state) => {
    return { companyId: state.auth.data.company_id }
}

export default connect(mapStateToProps, { setAlert })(AppointAdmin);
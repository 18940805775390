import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  
  SelectBoxModal,
  Button,
  DocumentModal,
  ProjectDocumentSelectBox,
 
} from "../../components";
import { setAlert } from '../../actions/alert';
import API from '../../urls/index';

import axiosInstance from '../../utils/axiosConfig';


const TaskDocumentModal = (props) => {
  const { proID } = props;
  const { taskID } = props;
  const [ loading, setLoading ] = useState(false)
  const [ userRole, setUserRole ] = useState({})


  useEffect(() => {
    getUserRole()
  }, [])


  const getUserRole = () => {
     axiosInstance.get(API.UserRoleInProject + `${proID}/user/position/`)
                  .then(res => {if(res.status === 200){
                      setUserRole(res.data.results)
                  }})
                  .catch(err => {if(err){
                      props.setAlert(" Error Loading Data", "error")
                  }})
  }
  

  const [document, setDocument] = useState({ name: "", status:""});
  const [postImage, setPostImage] = useState(null);

  const handleTaskDocumentChange = (event) => {
    setDocument({...document,[event.target.name]: event.target.value });
  };

  const handleTaskDocumentImageChange = (event) => {
    setPostImage({ document: event.target.files });
  };



  const getSelectBoxAccordingToRole = () => {
    if(userRole === {}){
      return <div>Loading...</div>
    }
    else{
    if(userRole.company_admin || userRole.role === "PM" || userRole.role === "PMem"){
      return <ProjectDocumentSelectBox
             htmlFor="Document Status"
             name="status"
             opt1="Not Deliverable"
             opt2="Initial Submission"
             opt3="Resubmission"
             opt4="Not Deliverable Internal"
             value1="Not Deliverable"
             value2="Initial Submission"
             value3="Resubmission"
             value4="Not Deliverable Internal"
             onChange={handleTaskDocumentChange}
       />
    }
    else if(userRole.role === "PC"){
      return <SelectBoxModal
             htmlFor="Status"
             name="status"
             opt1="Not Deliverable"
             
             value1="Not Deliverable"        
             onChange={handleTaskDocumentChange}
       />
    }
  }
  }

  const handleTaskDocumentSubmit = (id) => {
    if(!postImage){
      props.setAlert("Please Add The document file", "error")
    }
    else if(document.name === '' || document.status === ''){
      props.setAlert("Please Enter all input fields", "error")
    }
    else{
      setLoading(true)
      let form_data = new FormData();
    form_data.append("document", postImage.document[0]);

    form_data.append("name", document.name);
    form_data.append("status", document.status);
    
    axiosInstance
      .post(API.AddTaskdocument + `${taskID}/document/add/`,
        form_data
      )
      .then((res) => {
        if (res.status === 200) {
          // setDocument({name: '', status : ''});
          // setPostImage(null)
          props.getTaskDocumentsList()
          props.setAlert("SuccessFully Posted Document", "success");
          props.onClick()
          setLoading(false)
        }
      })
      .catch((err => props.setAlert("Something went wrong", "error")));
    }


  };
  return (
    <>
      <DocumentModal
        htmlFor="name"
        type="text"
        label="Document Name"
        name="name"
        value={document.name}
        required={true}
        onChange={handleTaskDocumentChange}
      />

       {getSelectBoxAccordingToRole()}
  
      
      <DocumentModal
        htmlFor="upload"
        type="file"
        name="document"
        label="Upload"
        onChange={handleTaskDocumentImageChange}
      />

      <Button
        loading={loading}
        button={props.button}
        onClick={handleTaskDocumentSubmit}
      />
    </>
  );
};

export default connect(null, { setAlert })(TaskDocumentModal);

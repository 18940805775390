import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import {
  NotificationBar,
  BasicTable,
  ProjectButton,
  Modal,
} from "../../components";
import MyItemsContext from "./MyItemsContext";
import styles from "./myitems.module.css";
import axiosInstance from "../../utils/axiosConfig";
import useFullPageLoader from '../../hooks/UseFullPageLoader';
import API from "../../urls/index";

const MyItems = (props) => {
  const companyId = props.computedMatch.params.companyId;
  const [myActionItems, setMyActionItems] = useState(null);

  const [showMyItems, setShowMyItems] = useState(false);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  useEffect(() => {
    let isMounted = true;
    getMyActionItems();

    return () => {
      isMounted = false;
    }

  }, []);

  const getMyActionItems = () => {
    showLoader()
    axiosInstance
      .get(API.MyActionItems + `${companyId}/user/actionitem/`)
      .then((res) => {
        setMyActionItems(res.data.results);
        hideLoader()
      }
      )
      .catch((err) => console.log(err));
  }




  return (
    <>
      <NotificationBar
        title="My Action Items"
        topButton="button"
      />
      <MyItemsContext.Provider value={[myActionItems, getMyActionItems, loader]}>
        <div className={styles.myitemOuterWrapper}>
          <Grid container>
            <Grid container>
              <Grid item lg={12} style={{ margin: "15px 0px" }}>
                <ProjectButton
                  onClick={() => setShowMyItems(!showMyItems)}
                  text="+ Add Your Action Item"
                />
              </Grid>
            </Grid>
            <div
              style={{
                borderBottom: "1px solid rgba(224,224,224,1)",
                width: "100%",
              }}
            ></div>

            <BasicTable
              myActionItems="Action Items"
              sno="S.No"
              date="Due Date"
              Description="Description"
              owner="Owner"
              actionProject="Project"
              tas="Task"
              getMyActionItems={getMyActionItems}
            />


          </Grid>
        </div>
        <Modal
          openPop={showMyItems}
          getMyActionItems={getMyActionItems}
          onClick={() => setShowMyItems(!showMyItems)}
          backdropClick={() => setShowMyItems(!showMyItems)}
          title="List Out Your Todos"
          button="Add Your Todo"
          todo="show todo"
          companyId={companyId}
        />

      </MyItemsContext.Provider>
    </>
  );
};



export default MyItems;

import React, { useState } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import { Modal } from "../../components";
import TableCell from "@material-ui/core/TableCell";
import { setAlert } from "../../actions/alert";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls/index";

import { SimpleMenu } from "../../components";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DetailComponent = ({ companyDetail, getCompanyDetails, setAlert }) => {
  const history = useHistory();
  const classes = useStyles();

  const [openDelete, setOpenDelete] = useState(false);
  const [ID, setID] = useState(false);

  const DeleteCompanyData = (id) => {
    setOpenDelete(!openDelete);
    setID(id);
  };

  const DeleteCompanyDataConfirm = (ID) => {
    axiosInstance
      .delete(API.DeleteCompany + `${ID}/`)
      .then((res) => {
        if (res) {
          setAlert("Company Deleted Successfully", "success");
          getCompanyDetails();
          setOpenDelete(!openDelete);
        }
      })
      .catch((err) => setAlert("Cannot Perform This Action", " error"));
  };

  return (
    <>
      <div style={{ width: "94%", margin: "auto" }}>
        <div style={{ width: "100%", overflow: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: "5%" }}>
                  S.No.
                </TableCell>
                <TableCell align="center" style={{ width: "10%" }}>
                  Account ID
                </TableCell>
                <TableCell align="left" style={{ width: "30%" }}>
                  Company Name
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  Contact Information
                </TableCell>
                <TableCell align="center" style={{ width: "15%" }}>
                  Product Type
                </TableCell>
                <TableCell align="center" style={{ width: "10%" }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ width: "10%" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
        <div style={{ height: "60vh", width: "100%", overflowY: "auto" }}>
          <Table>
            <TableBody style={{ border: "1px solid rgba(224,224,224,1)" }}>
              {companyDetail.length === 0 ? (
                <TableRow>No Company Registered yet</TableRow>
              ) : (
                companyDetail.map((company, index) => (
                  <TableRow key={company && company.id}>
                    <TableCell style={{ width: "5%" }} align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ width: "10%" }} align="center">
                      {company && company.id}
                    </TableCell>
                    <TableCell align="left" style={{ width: "30%" }}>
                      <div
                        onClick={() => history.push(`/company/${company.id}`)}
                        style={{ cursor: "pointer" }}
                      >
                        {company && company.title}
                      </div>
                    </TableCell>
                    <TableCell align="center" style={{ width: "20%" }}>
                      {company.owner && company.owner.contact
                        ? company.owner.contact
                        : "No Contact Info"}
                    </TableCell>
                    <TableCell align="center" style={{ width: "15%" }}>
                      {company.owner && company.owner.premium_user
                        ? "Premium"
                        : "Standard"}
                    </TableCell>
                    <TableCell align="center" style={{ width: "11%" }}>
                      {company.owner && company.owner.payment_status
                        ? "Active"
                        : "Inactive"}
                    </TableCell>
                    <TableCell align="center" style={{ width: "10%" }}>
                      <SimpleMenu
                        delete="Delete"
                        deleteProject={() => DeleteCompanyData(company.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}

              {/* {companyFiltered.length === 0 ? 
              companyDetails.filter(company => company.companyName.toLowerCase().includes(query.toLowerCase())).map(({id, acntId, companyName, phNum, productType, status}) => (<TableRow key={id}>
                <TableCell >{id}</TableCell>
                <TableCell align="center">{acntId}</TableCell>
                <TableCell align="center">
                  <Button href="/nascent-company">{companyName}</Button>
                </TableCell>
                <TableCell align="center">{phNum}</TableCell>
                <TableCell align="center">{productType}</TableCell>
                <TableCell align="center">{status}</TableCell>
                <TableCell align="center">
                  <SimpleMenu edit="Edit" delete="Delete" />
                </TableCell>
              </TableRow> )): 
              companyFiltered.map(({id, acntId, companyName, phNum, productType, status}) => 
              (<TableRow key={id}>
                <TableCell >{id}</TableCell>
                <TableCell align="center">{acntId}</TableCell>
                <TableCell align="center">
                  <Button href="/nascent-company">{companyName}</Button>
                </TableCell>
                <TableCell align="center">{phNum}</TableCell>
                <TableCell align="center">{productType}</TableCell>
                <TableCell align="center">{status}</TableCell>
                <TableCell align="center">
                  <SimpleMenu edit="Edit" delete="Delete" />
                </TableCell>
              </TableRow>))} */}
            </TableBody>
          </Table>
        </div>

        <Modal
          openPop={openDelete}
          onClick={() => setOpenDelete(!openDelete)}
          backdropClicked={() => setOpenDelete(!openDelete)}
          deleteBar="Delete Bar"
          title="Confirm Delete"
          ConfirmDeleteData={() => DeleteCompanyDataConfirm(ID)}
          closeDialog={() => setOpenDelete(!openDelete)}
        />
      </div>
    </>
  );
};

export default connect(null, { setAlert })(DetailComponent);

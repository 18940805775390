import React, { useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Button} from '@material-ui/core';
import axiosInstance from '../../utils/axiosConfig';
import { setAlert } from '../../actions/alert';

import API from '../../urls/index';
import './viewReq.css';

const ViewRequest = (props) => {
    const history = useHistory();
    const viewId = props.computedMatch.params.viewId;
    const [ message, setMessage ] = useState(false);
    const [ reject, setReject ] = useState(false);

    const handleAcceptRequest = () => {
        axiosInstance.post(API.AcceptCollabReq + `${viewId}/req/accept/`, {status : "accepted" })
                     .then(res =>  {
                         if(res){
                            setMessage(true);
                            
                         }
                        })
                     .catch(err => {
                         
                         props.setAlert(err.response.data.detail, "error")
                     })
    }

    const handleRejectRequest = () => {
        axiosInstance.post(API.AcceptCollabReq + `${viewId}/req/accept/`, {status : "rejected" })
                     .then(res =>  {
                         if(res){
                            setReject(true)
                         }
                        })
                     .catch(err => {
               
                         props.setAlert(err.response.data.detail, "error")
                     })
    }
    return (
        <>
        <AppBar style={{ backgroundColor: "#091936" }} position="static">
        <Toolbar
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Nascent</h3>
          <span style={{color:"white", cursor : "pointer"}} onClick={() => history.push('/')}>Go Home</span>
        </Toolbar>
      </AppBar>
        <div className="ViewOuterWrapper">
            {message ? <div className="accept">Successfully joined company <span><Link to='/'>Go Home</Link></span></div> : (reject? <div className="reject">You have rejected the request<span><Link to='/'>Go Home</Link></span></div> : <>
            <div>
               <h5>You are requested to join the New Company. Please Accept or Reject the request to continue!</h5>
           </div>
           <div className="ViewButton">
               <Button color="primary" onClick={handleAcceptRequest}>Accept</Button>
               <Button color="secondary" onClick={handleRejectRequest}>Reject</Button>
           </div>
           </>)}
       
           
        </div>
        </>
    )
}

export default connect(null, { setAlert } )(ViewRequest);
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Grid, Divider } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import axiosInstance from "../../utils/axiosConfig";
import API from "../../urls";
import { Modal, NotificationBar, Meeting } from "../../components";
import styles from "./conferences.module.css";
import { VideocamOutlined } from "@material-ui/icons";
import { cleanup } from "@testing-library/react";
import ExpiredConferenceList from "./ExpiredConferenceList";
import { ProjectSnapShot } from "..";
import store from "../../store";

const Conferences = (props) => {
  const [newMeeting, setNewMeeting] = useState(false);
  const [openConference, setConference] = useState(false);

  const [upComingConferenceList, setupComingConferenceList] = useState([]);
  const [expiredConferenceList, setExpiredConferenceList] = useState([]);
  const [activeConferenceList, setActiveConferenceList] = useState([]);
  const [meetingId, setMeetingId] = useState(0);

  const [roomName, setRoomName] = useState(null);

  useEffect(() => {
    getUpcomingConferenceList();
    return () => {
      cleanup();
    };
  }, [store.getState().newConference]);

  const getUpcomingConferenceList = async () => {
    try {
      const res = await axiosInstance.get(
        API.GetUpcomingConference +
          `${
            localStorage.getItem("companyId")
              ? localStorage.getItem("companyId")
              : props.companyId.companies[0].id
          }/videomeetings/`
      );
      if (res.data) {
        let currentDate = new Date();
        let expiredConferences = [];
        let activeConferences = [];

        res.data.results.map((item, index) => {
          let givenDate = new Date(item.end);

          if (givenDate < currentDate) {
            expiredConferences.unshift(item);
            expiredConferences.sort(function (a, b) {
              var c = new Date(a.start);
              var d = new Date(b.start);
              return d - c;
            });
          } else {
            activeConferences.unshift(item);
            activeConferences.sort(function (a, b) {
              var c = new Date(a.start);
              var d = new Date(b.start);
              return c - d;
            });
          }
        });
        setExpiredConferenceList(expiredConferences);
        setActiveConferenceList(activeConferences);
      }
      // setupComingConferenceList(res.data);
    } catch (error) {
      throw error;
    }
  };

  const generateRoomName = (e) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    setRoomName(result);
  };

  const editConference = (id) => {
    setMeetingId(id);
    setConference(!openConference);
  };

  const openConferenceModal = () => {
    generateRoomName();
    setConference(!openConference);
  };

  useEffect(() => {
    if (roomName) {
      console.log(roomName);
    }
  }, [roomName]);

  return (
    <React.Fragment>
      <NotificationBar
        title="Conferences"
        topButton="button"
        getUpcomingConferenceList={getUpcomingConferenceList}
      />
      <main>
        <div className={styles.myitemOuterWrapper}>
          <div style={{ width: "100%", backgroundColor: "#edf6fa" }}>
            <div>
              <Grid
                container
                spacing={2}
                style={{ width: "100%", color: "black", margin: "auto" }}
              >
                <Grid
                  item
                  lg={6}
                  sm={12}
                  md={6}
                  xs={12}
                  style={{ margin: "0px 10px" }}
                >
                  <Divider style={{ margin: " 30px" }} />
                  <div className={styles.btnWrapper}>
                    <div className={styles.hostMeetingWrapper}>
                      <button onClick={() => openConferenceModal()}>
                        <AddIcon />
                      </button>
                      <p>Host a meeting</p>
                    </div>
                    {/* <div className={styles.hostMeetingWrapper}>
                      <Link to="list-of-conferences" style={{ color: "black" }}>
                        <button>
                          <SearchOutlinedIcon />
                        </button>
                      </Link>
                      <p>View All Conferences</p>
                    </div> */}
                  </div>

                  <div className={styles.expiredConferenceDiv}>
                    <h4>Expired Conferences</h4>

                    {!expiredConferenceList ? (
                      <p>Loading...</p>
                    ) : (
                      <React.Fragment>
                        {expiredConferenceList.length > 0 ? (
                          <ExpiredConferenceList
                            items={expiredConferenceList}
                          />
                        ) : (
                          <p>No expired meetings</p>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Grid>

                <Modal
                  openPop={newMeeting}
                  onClick={() => setNewMeeting(!newMeeting)}
                  backdropClicked={() => setNewMeeting(!newMeeting)}
                  title="Add New Conference"
                  button="Host Meeting"
                  type="type"
                  task="task"
                  date="Date"
                  time="time"
                  details="details"
                  member="Member"
                  roomName={roomName}
                  getUpcomingConferenceList={getUpcomingConferenceList}
                ></Modal>
                <Grid
                  item
                  lg={5}
                  sm={11}
                  md={6}
                  xs={11}
                  style={{
                    backgroundColor: "white",
                    margin: "auto",
                    borderRadius: "10px",
                    marginTop: "25px",
                  }}
                >
                  <div
                    style={{ height: "calc(95vh - 4.75rem)", overflow: "auto" }}
                  >
                    <Grid container>
                      <Grid item lg={11} style={{ margin: "auto" }}>
                        <h5>Upcoming Conferences</h5>
                      </Grid>

                      <Grid item lg={1} style={{ margin: "auto" }}>
                        <VideocamOutlined />
                      </Grid>
                    </Grid>

                    {!activeConferenceList ? (
                      <p>Loading...</p>
                    ) : (
                      <React.Fragment>
                        {activeConferenceList.length > 0 ? (
                          activeConferenceList.map((item, idx) => {
                            return (
                              <Meeting
                                meetingInfo={item}
                                key={item.id}
                                getUpcomingConference={
                                  getUpcomingConferenceList
                                }
                                editConference={editConference}
                              />
                            );
                          })
                        ) : (
                          <p>No active conferences found </p>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </main>
      <Modal
        openPop={openConference}
        onClick={() => setConference(!openConference)}
        backdropClicked={() => setConference(!openConference)}
        getUpcomingConferenceList={getUpcomingConferenceList}
        title="Add New Conference"
        button="Host Meeting"
        task="task"
        date="Date"
        roomName={roomName}
        time="time"
        details="details"
        meetingId={meetingId}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.data,
  };
};
export default connect(mapStateToProps)(Conferences);

import React from "react";
import {
  getTimeInLocalTimeStamp,
  formatDate,
  getTimeFromDateTime,
  formatDateDayMonth,
} from "../../utils/dateTimeUtility";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function ExpiredConferenceList(props) {
  return (
    <TableContainer style={{ background: "white", borderRadius: "7px" }}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>S.N</TableCell>
            <TableCell>Conference Title</TableCell>
            <TableCell align="right">Meeting date</TableCell>
            {/* <TableCell align="right">Start Time</TableCell> */}
            {/* <TableCell align="right">End Time</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.slice(0, 10).map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell component="th" scope="row">
                {row.meeting_name}
              </TableCell>
              <TableCell align="right">{formatDate(row.start)}</TableCell>
              {/* <TableCell align="right">
                {getTimeFromDateTime(new Date(row.start))}
              </TableCell>
              <TableCell align="right">
                {getTimeFromDateTime(new Date(row.end))}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
